import ReactDOM from 'react-dom';
import { Modal, Section } from '@imposium-hub/components';
import { HEADER_HEIGHT } from '../constants/editor';

const missingVarsTable = (missingVars) => {
    return (
        <table className='missingVars'>
            <tbody>
                {missingVars.map((variable) => {
                    return (
                        <tr
                            className='missingVar'
                            key={variable}>
                            <td className='variable'>{variable}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

interface IMissingVarsModalProps {
    missingVars: any[];
    showMissingVars: boolean;
    onClose(): void;
    rect: any;
}

export const MissingVarsModal = (p: IMissingVarsModalProps) => {
    const { missingVars, showMissingVars, onClose, rect } = p;

    const top = rect?.top + rect?.height - 20;
    const left = rect?.left + rect?.width - 20;

    const modal = (
        <Modal
            onRequestClose={() => onClose()}
            wrapperStyle={{
                top: HEADER_HEIGHT,
                left: '0px',
                width: '100%',
                height: `calc(100% - ${HEADER_HEIGHT}px)`
            }}
            style={{
                width: '200px',
                height: '200px',
                top,
                left,
                overflow: 'auto'
            }}
            isOpen={showMissingVars}>
            <Section title='Missing Variables'>{missingVarsTable(missingVars)}</Section>
        </Modal>
    );

    const PortalContextComponent = () =>
        ReactDOM.createPortal(modal, document.querySelector('#react-root'));

    return <PortalContextComponent />;
};
