import * as PIXI from 'pixi.js-legacy';
import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import MediaLayerSource from './MediaLayerSource';

export default class ImageLayerSource extends MediaLayerSource {
    private imgSprites: any = {};

    public clear(): void {
        super.clear();

        for (const key in this.imgSprites) {
            if (this.imgSprites.hasOwnProperty(key)) {
                const img = this.imgSprites[key];
                if (img && !img.destroyed) {
                    img.destroy(true);
                }
                delete this.imgSprites[key];
            }
        }
        this.imgSprites = {};
    }

    public seek() {
        super.seek();

        if (!this.mediaSprite) {
            return;
        }

        if (this.mediaSprite) {
            this.contentSprite.removeChild(this.mediaSprite);
            this.mediaSprite = null;
        }

        const frameNumber = this.getFrameNumber();

        if (frameNumber === null) {
            return;
        }

        const imgSprite = this.imgSprites[`image-${frameNumber + 1}`];
        if (imgSprite) {
            this.mediaSprite = imgSprite;
            this.contentSprite.addChild(this.mediaSprite);
            this.makeInteractive();
            this.resize();
        }
    }

    private getFrameNumber() {
        const { relativeFrame, source } = this.props;
        if (!source) {
            return null;
        }
        let frame = Math.max(relativeFrame, 0);
        frame = Math.min(frame, source.duration - 1);
        return frame;
    }

    public async loadLayerSource(): Promise<void | any> {
        this.logInfo(`Load Layer Source`);

        this.setState({
            status: LAYER_SOURCE_STATUSES.LOADING
        });

        const timer = Date.now();
        const { source } = this.props;
        let sourceWidth;
        let sourceHeight;

        try {
            const imgPromises = [];
            for (let i = 1; i <= source.duration; i++) {
                const imgUrl = source.preview_url.replace('%d', i);

                imgPromises.push(
                    new Promise((resolve, reject) => {
                        const img: any = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = () => {
                            const { naturalWidth, naturalHeight } = img;
                            sourceWidth = naturalWidth;
                            sourceHeight = naturalHeight;
                            const imgSprite = PIXI.Sprite.from(img);
                            this.imgSprites[`image-${i}`] = imgSprite;
                            resolve(imgSprite);
                        };
                        img.onerror = (e) => {
                            this.logError(`Error loading image sequence image ${imgUrl}`, e);
                            reject();
                        };
                        img.src = imgUrl;
                    })
                );
            }

            await Promise.all(imgPromises);
        } catch (e) {
            return Promise.reject(e);
        }

        this.mediaSprite.removeChildren();
        const sourceTimer = Date.now() - timer;
        this.logInfo(`Layer source loaded in ${sourceTimer}ms`);

        return new Promise<void>((resolve) => {
            void this.seek();
            void this.draw();
            this.setState({
                sourceWidth,
                sourceHeight,
                status: LAYER_SOURCE_STATUSES.READY
            });
            resolve();
        });
    }
}
