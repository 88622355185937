import * as React from 'react';
import { Card, NumberField, ColorField, CheckboxField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { IVariables, colorPresets } from '../constants/snippets';
import MediaSource from './MediaSource';
import { TEMPLATE_LAYER_SOURCE_TYPE_OPTIONS } from '../constants/story';
import SuperUserOnly from './SuperUserOnly';
import _ from 'lodash';
import { getSourceKey } from './viewer/composition-preview/LayeredCompositionPreview';

interface ITemplateLayerOptionsProps {
    variables: IVariables;
    storyId: string;
    options: any;
    type: any;
    onChange(options: any): void;
    layerSources: any;
    compositionId: string;
    layerId: string;
}

interface ITemplateLayerOptionsState {
    missingVars: any[];
}

class TemplateLayerOptions extends React.PureComponent<
    ITemplateLayerOptionsProps,
    ITemplateLayerOptionsState
> {
    private evtHandlers = {
        source: (s) => this.updateOptionsFields({ source: s }),
        width: (w) => this.updateOptionsFields({ width: w }),
        height: (h) => this.updateOptionsFields({ height: h }),
        animated: (a) => this.updateOptionsFields({ animated: a }),
        gpu: (g) => this.updateOptionsFields({ requires_gpu: g }),
        bgColor: (v) => this.bgChanged(v)
    };

    constructor(props) {
        super(props);

        this.state = {
            missingVars: null
        };
    }

    public componentDidMount(): void {
        this.getMissingVars();
    }

    public componentDidUpdate(
        prevProps: Readonly<ITemplateLayerOptionsProps>,
        prevState: Readonly<ITemplateLayerOptionsState>
    ): void {
        const {
            variables,
            layerSources,
            options: {
                source: { from, asset_id }
            }
        } = this.props;

        if (
            (from !== prevProps.options.source.from || asset_id === null) &&
            this.state.missingVars?.length > 0
        ) {
            this.setState({ missingVars: null });
        }

        if (
            (prevState.missingVars === null && this.state.missingVars === null) ||
            !_.isEqual(layerSources, prevProps.layerSources) ||
            !_.isEqual(variables, prevProps.variables)
        ) {
            this.setState({ missingVars: null });
            this.getMissingVars();
        }
    }

    private getMissingVars() {
        const { layerSources, compositionId, layerId } = this.props;

        const key = getSourceKey(compositionId, layerId);
        const layerSource = layerSources[key];

        if (layerSource) {
            const { variables_used } = layerSource;
            this.checkMissingVars(variables_used);
        }
    }

    private checkMissingVars(variables_used) {
        const { variables } = this.props;

        const missingVars = [];

        for (const idx of variables_used) {
            if (idx && !variables[idx]) {
                missingVars.push(idx);
            }
        }

        if (missingVars.length > 0) {
            this.setState({ missingVars });
        }
    }

    private updateOptionsFields(options) {
        const merged = { ...this.props.options, ...options };
        this.props.onChange(merged);
    }

    private bgChanged(color) {
        if (color.hex === 'transparent') {
            this.updateOptionsFields({ background_color: null });
        } else {
            const values = color.rgb;
            this.updateOptionsFields({
                background_color: `rgba(${values.r},${values.g},${values.b},${values.a})`
            });
        }
    }

    public render() {
        const {
            type,
            options: { source, width, height, background_color, animated, requires_gpu },
            variables,
            storyId
        } = this.props;
        const bgColor = background_color ? background_color : 'transparent';

        return (
            <Card
                title={copy.layerConfig.options}
                open={true}
                collapsable={true}
                style={'options'}>
                <MediaSource
                    storyId={storyId}
                    variables={variables}
                    source={source}
                    layerType={type}
                    typeOptions={TEMPLATE_LAYER_SOURCE_TYPE_OPTIONS}
                    onChange={this.evtHandlers.source}
                    missingVars={this.state.missingVars}
                />
                <NumberField
                    label={copy.layerConfig.templateWidth}
                    tooltip={copy.layerConfig.tooltipTempWidth}
                    width='50%'
                    value={width}
                    onChange={this.evtHandlers.width}
                />
                <NumberField
                    label={copy.layerConfig.templateHeight}
                    tooltip={copy.layerConfig.tooltipTempHeight}
                    width='50%'
                    value={height}
                    onChange={this.evtHandlers.height}
                />
                <ColorField
                    label={copy.overlayConfig.templateBgColor}
                    tooltip={copy.overlayConfig.tooltipBgColor}
                    width='50%'
                    value={bgColor}
                    presetColors={colorPresets}
                    enableAlpha={true}
                    onChange={this.evtHandlers.bgColor}
                />
                <CheckboxField
                    label={copy.layerConfig.templateAnimated}
                    tooltip={copy.layerConfig.tooltipTemplateAnimated}
                    width='50%'
                    value={animated}
                    onChange={this.evtHandlers.animated}
                />
                <SuperUserOnly>
                    <CheckboxField
                        label={copy.layerConfig.templateGPU}
                        tooltip={copy.layerConfig.tooltipTemplateGPU}
                        width='33%'
                        value={requires_gpu}
                        onChange={this.evtHandlers.gpu}
                    />
                </SuperUserOnly>
            </Card>
        );
    }
}

export default TemplateLayerOptions;
