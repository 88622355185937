import * as React from 'react';
import CutConfig from './CutConfig';
import { Section } from '@imposium-hub/components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ICut, IVariables, IAudioOverlay } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import AudioOverlayConfig from './AudioOverlayConfig';

interface ILayerConfigProps {
    width: number;
    height: number;
    rate: number;
    cuts: ICut[];
    editor: IEditor;
    activeTimelineCut: string;
    activeTimelineOverlay: string;
    project: IProject;
    variables: IVariables;
    audioOverlays: IAudioOverlay[];
    updateCut(config): void;
    deleteCut(config): void;
    deleteAudioOverlay(config): void;
    updateAudioOverlay(config): void;
    duplicateAudioOverlay(config): void;
}

class LayerConfig extends React.Component<ILayerConfigProps> {
    constructor(props) {
        super(props);
    }

    public shouldComponentUpdate(nextProps, nextState) {
        const {
            project: { actId, sceneId },
            activeTimelineCut,
            activeTimelineOverlay,
            cuts,
            variables,
            audioOverlays
        } = this.props;

        if (nextProps.editor.actId !== actId) {
            return true;
        }

        if (nextProps.editor.sceneId !== sceneId) {
            return true;
        }

        if (nextProps.activeTimelineCut !== activeTimelineCut) {
            return true;
        }

        if (nextProps.activeTimelineOverlay !== activeTimelineOverlay) {
            return true;
        }

        if (nextProps.cuts !== cuts) {
            return true;
        }

        if (nextProps.variables !== variables) {
            return true;
        }

        if (nextProps.audioOverlays !== audioOverlays) {
            return true;
        }

        return false;
    }

    public render() {
        return <div className='cut-list'>{this.renderCuts()}</div>;
    }

    public renderCuts() {
        const {
            activeTimelineCut,
            activeTimelineOverlay,
            cuts,
            audioOverlays,
            variables,
            width,
            height,
            rate
        } = this.props;
        if (cuts) {
            const activeCut: any = cuts.find((c: any) => c.id === activeTimelineCut);
            const activeAudioOverlay: any = audioOverlays.find(
                (a: any) => a.id === activeTimelineOverlay
            );

            let cutIdx: number;
            let audIdx: number;

            if (typeof activeCut === 'object') {
                cutIdx = cuts.indexOf(activeCut);

                return (
                    <CutConfig
                        key={`cut-${activeCut.id}`}
                        width={width}
                        height={height}
                        rate={rate}
                        activeOverlay={activeTimelineOverlay}
                        onUpdate={(c) => this.updateCut(cutIdx, c)}
                        onDelete={() => this.deleteCut(cutIdx)}
                        variables={variables}
                        config={activeCut}
                    />
                );
            }

            if (typeof activeAudioOverlay === 'object') {
                audIdx = audioOverlays.indexOf(activeAudioOverlay);

                return (
                    <AudioOverlayConfig
                        deleteAudioOverlay={this.props.deleteAudioOverlay}
                        updateAudioOverlay={this.props.updateAudioOverlay}
                        duplicateAudioOverlay={this.props.duplicateAudioOverlay}
                        key={`audio-${activeAudioOverlay.id}`}
                        variables={variables}
                        index={audIdx}
                        config={activeAudioOverlay}
                    />
                );
            }

            return <Section title={''}></Section>;
        } else {
            return null;
        }
    }

    private deleteCut(cutIndex) {
        const {
            project: { actId, sceneId }
        } = this.props;

        const updateConfig = {
            actId,
            sceneId,
            cutIndex
        };

        this.props.deleteCut(updateConfig);
    }

    public updateCut(cutIndex, cut) {
        const {
            project: { actId, sceneId }
        } = this.props;

        const updateConfig = {
            actId,
            sceneId,
            cutIndex,
            cut
        };

        this.props.updateCut(updateConfig);
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        editor: state.editor,
        project: state.project,
        activeTimelineCut: state.timeline[state.project.compositionId].activeTimelineCut,
        activeTimelineOverlay: state.timeline[state.project.compositionId].activeTimelineOverlay
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerConfig);
