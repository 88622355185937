import {
    Button,
    ButtonMenu,
    ButtonMenuItem,
    Card,
    NumberField,
    Section,
    SelectField,
    SliderField,
    TextField
} from '@imposium-hub/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { api } from '../constants/app';
import { fields as copy } from '../constants/copy';
import { STORY_ERRORS } from '../constants/errors';
import { ICON_COPY, ICON_ELLIPSIS_V, ICON_HEADPHONES, ICON_TRASH } from '../constants/icons';
import {
    IAudioOverlay,
    NEW_ASSET_SOURCE,
    NEW_ASSET_TAGS_SOURCE,
    NEW_INVENTORY_SOURCE
} from '../constants/snippets';
import { ASSET_TYPES, SOURCE_TYPES, SOURCE_TYPE_OPTIONS } from '../constants/story';
import type { IProject } from '../redux/reducers/project';
import { logError } from '../util/notifications';
import ErrorIndicator from './errors/ErrorIndicator';
import TagFields from './TagFields';
import AssetField from './assets/AssetField';
import { openConfirmModal } from '../util/ui';

interface IAudioOverlayConfigProps {
    config: IAudioOverlay;
    index: number;
    project: IProject;
    deleteAudioOverlay(config: any): void;
    duplicateAudioOverlay(config: any): void;
    updateAudioOverlay(config: any): void;
    variables: any;
}

class AudioOverlayConfig extends React.PureComponent<IAudioOverlayConfigProps> {
    private evtHandlers: any;

    private menu: JSX.Element;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                assetId: (a) => this.sourceAssetChanged(a),
                assetTags: (v) => this.sourceFieldChanged('asset_tags', v),
                type: (v) => this.sourceTypeChanged(v),
                name: (v) => this.inputChanged('name', v),
                volume: (v) => this.inputChanged('volume', v),
                offset: (v) => this.inputChanged('offset', v),
                assetError: (m) => {
                    logError(m);
                }
            },
            buttons: {
                duplicate: () => this.duplicateOverlay(),
                delete: () => this.confirmDelete()
            }
        };

        const menuItems = [
            <ButtonMenuItem
                key='duplicate'
                label={
                    <span>
                        {ICON_COPY}&nbsp;{copy.overlayConfig.btnDuplicateOverlay}
                    </span>
                }
                onClick={this.evtHandlers.buttons.duplicate}
            />,
            <ButtonMenuItem
                key='delete'
                label={
                    <span>
                        {ICON_TRASH}&nbsp;{copy.overlayConfig.btnDeleteOverlay}
                    </span>
                }
                onClick={this.evtHandlers.buttons.delete}
            />
        ];

        this.menu = (
            <ButtonMenu
                position='left'
                items={menuItems}
                button={<Button style='subtle'>{ICON_ELLIPSIS_V}</Button>}
            />
        );
    }

    private sourceAssetChanged(asset) {
        const config = { ...this.props.config };
        const src = { ...config['source'] };

        if (asset) {
            src['asset_id'] = asset.id;
        } else {
            src['asset_id'] = null;
        }

        config['source'] = src;
        this.update(config);
    }

    private sourceFieldChanged(key, value) {
        const config = { ...this.props.config };
        const src = { ...config['source'] };
        src[key] = value;
        config['source'] = src;

        this.update(config);
    }

    private sourceTypeChanged(type, seed?) {
        const config = seed ? seed : { ...this.props.config };

        switch (type) {
            // script
            case SOURCE_TYPES.SCRIPT:
                config['source'] = null;
                config['generating_script'] = '';
                break;

            // variable
            case SOURCE_TYPES.INVENTORY:
                config['source'] = { ...NEW_INVENTORY_SOURCE };
                break;

            // asset
            case SOURCE_TYPES.ASSET:
                config['source'] = { ...NEW_ASSET_SOURCE };
                config['source']['asset_type'] = ASSET_TYPES.AUDIO;
                break;

            // asset from tags
            case SOURCE_TYPES.ASSET_TAGS:
                config['source'] = { ...NEW_ASSET_TAGS_SOURCE };
                config['source']['asset_type'] = ASSET_TYPES.AUDIO;
                break;
        }

        this.update(config);
    }

    public inputChanged(key, value) {
        const config = { ...this.props.config };
        config[key] = value;

        this.update(config);
    }

    public update(overlay) {
        const {
            index,
            project: { actId, sceneId }
        } = this.props;
        const update = {
            actId,
            sceneId,
            index,
            overlay
        };
        this.props.updateAudioOverlay(update);
    }

    private confirmDelete() {
        const { config, index } = this.props;

        openConfirmModal({
            onYes: () => this.props.deleteAudioOverlay({ index }),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private duplicateOverlay() {
        const { index } = this.props;
        this.props.duplicateAudioOverlay({ index });
    }

    private renderSourceInputs() {
        const {
            config: { source },
            variables,
            project: { storyId }
        } = this.props;

        if (source) {
            switch (source['from']) {
                case SOURCE_TYPES.ASSET:
                    return (
                        <AssetField
                            storyId={storyId}
                            api={api}
                            label={copy.global.asset}
                            tooltip={copy.overlayConfig.tooltipAsset}
                            key='asset-field'
                            onChange={this.evtHandlers.inputs.assetId}
                            accepts={source['asset_type']}
                            onError={this.evtHandlers.inputs.assetError}
                            assetId={source['asset_id']}
                        />
                    );
                    break;

                case SOURCE_TYPES.ASSET_TAGS:
                    return (
                        <TagFields
                            key='tag-fields'
                            tags={source['asset_tags']}
                            variables={variables}
                            onChange={this.evtHandlers.inputs.assetTags}
                        />
                    );
                    break;
            }
        }
    }

    private renderSourceSelect() {
        const {
            config: { type },
            config
        } = this.props;
        const value = config.source ? config.source.from : '';

        return (
            <SelectField
                label={copy.overlayConfig.sourceType}
                tooltip={copy.overlayConfig.tooltipSourceType}
                value={value}
                width='50%'
                options={SOURCE_TYPE_OPTIONS[type]}
                onChange={this.evtHandlers.inputs.type}
            />
        );
    }

    public render = (): JSX.Element => {
        const { config } = this.props;

        return (
            <Section
                title={
                    <span>
                        {ICON_HEADPHONES}&nbsp;{config.name}
                    </span>
                }
                buttons={this.menu}>
                <Card
                    title={copy.global.general}
                    open={true}
                    collapsable={false}>
                    <TextField
                        label={copy.global.name}
                        tooltip={copy.overlayConfig.tooltipName}
                        value={config['name']}
                        onChange={this.evtHandlers.inputs.name}
                    />
                </Card>
                <Card
                    title={copy.overlayConfig.source}
                    open={true}
                    collapsable={true}>
                    <ErrorIndicator
                        overlayId={config.id}
                        show={[
                            STORY_ERRORS.OVERLAY_ASSET_TAG_ERROR,
                            STORY_ERRORS.OVERLAY_ASSET_NOT_FOUND,
                            STORY_ERRORS.OVERLAY_ASSET_ERROR
                        ]}
                    />
                    {this.renderSourceSelect()}
                    {this.renderSourceInputs()}
                </Card>
                <Card
                    title={copy.overlayConfig.effects}
                    open={true}
                    collapsable={true}>
                    <SliderField
                        label={copy.overlayConfig.volumeMultiplier}
                        tooltip={copy.overlayConfig.tooltipVolumeMultiplier}
                        value={config['volume']}
                        width='50%'
                        min={0}
                        max={5}
                        defaultValue={1}
                        step={0.1}
                        onChange={this.evtHandlers.inputs.volume}
                    />
                    <NumberField
                        label={copy.overlayConfig.offset}
                        tooltip={copy.overlayConfig.tooltipOffset}
                        value={config['offset']}
                        width='50%'
                        onChange={this.evtHandlers.inputs.offset}
                    />
                </Card>
            </Section>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioOverlayConfig);
