import * as React from 'react';
import {
    AssetsTableDateCell,
    AssetsTableDropzone,
    AssetsTableSelectFilter,
    AssetsTableSelectCell,
    AssetsTableTypeFilter,
    AssetsTableNameFilter,
    AssetsTableNameCell,
    AssetsTableTagsFilter,
    AssetsTableTagsPivot,
    AssetsTableStatusCell,
    validateAssetMimetype,
    resetSelection,
    AssetsTablePreviewCell,
    DataTable,
    updateFilters,
    AssetsTableDurationCell,
    AssetsTableTagsCell,
    replaceAsset,
    AssetsTableRateCell,
    AssetsTableGlobalCell,
    AssetsTableAssetIdFilter,
    AssetsTableAssetIdCell
} from '@imposium-hub/components';
import { ASSET_TYPES } from '../../constants/story';
import { assets as copy } from '../../constants/copy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addViewer, replaceViewer, updateViewer } from '../../redux/actions/story';
import { api } from '../../constants/app';
import { IProject } from '../../redux/reducers/project';
import { IComposition, NEW_EMPTY_DEFAULT_VIEWER } from '../../constants/snippets';
import { setComposition } from '../../redux/actions/compositions';
import { ASSET_DND_TYPES } from '../../constants/timeline';
import { MAX_FILE_SIZE } from '../../constants/app';
import { logNotification, logError } from '../../util/notifications';
import AssetContextMenu from '../AssetContextMenu';
import { LOWER_PANELS, RESERVED_COMP_RENDER_NAME } from '../../constants/editor';
import {
    createNewHTMLAsset,
    replaceHTMLAsset,
    updateEditorConfig,
    uploadHTMLAsset
} from '../../redux/actions/editor';
import { arrayMove, getInputFilter } from '../../util/general';
import { uploadAssetsHandler } from '../../util/story';
import _ from 'lodash';

interface IAssetsTableProps {
    viewer: any;
    assetList: any;
    assetFilters: any;
    assetUploads: any;
    loading: boolean;
    doHydrate: () => any;
    project: IProject;
    compositions: any;
    setComposition: (id: string, comp: IComposition) => void;
    updateFilters: (filters: any) => any;
    replaceAsset: (apiInstance: any, assetId: any, files: File[], storyId: string) => any;
    addViewer: (viewerConfig: any) => any;
    updateViewer: (id: string, asset: any) => any;
    replaceViewer: (viewerConfig: any) => any;
    resetSelection: () => void;
    columnsFilter: any;
    editor: any;
    updateEditorConfig(c): void;
    createNewHTMLAsset(): void;
    uploadHTMLAsset(f: any, n: string): void;
    replaceHTMLAsset(f: any, n: string): void;
    uploadMenuHover: boolean;
    onTableHover: (o) => void;
}

interface IAssetsTableState {
    activePreview: string;
    assetOver: boolean;
    columnConfig: any;
    assets: any;
    assetId: any;
    assetType: string;
}

class AssetsTable extends React.PureComponent<IAssetsTableProps, IAssetsTableState> {
    private evtHandlers: any;

    private readonly replaceFileRef: any;

    constructor(p: IAssetsTableProps) {
        super(p);
        this.state = {
            activePreview: '',
            columnConfig: this.getColumnConfig(p),
            assetOver: false,
            assets: null,
            assetId: '',
            assetType: ''
        };

        this.evtHandlers = {
            onClose: () => this.handlePreviewClose(),
            handleClick: (cell) => this.handleClick(cell)
        };

        this.replaceFileRef = React.createRef();
    }

    public componentDidMount(): void {
        this.props.doHydrate();
    }

    private showTags(cell) {
        if (cell.row.isExpanded) {
            cell.row.toggleRowExpanded(false);
        } else {
            cell.row.toggleRowExpanded(true);
        }
    }

    private handleClick(cell: any) {
        const { id } = cell.row.original;
        this.setState({
            activePreview: id
        });
    }

    private handlePreviewClose() {
        this.setState({ activePreview: '' });
    }

    public componentDidUpdate = (prevProps: Readonly<IAssetsTableProps>): void => {
        const {
            viewer,
            assetList: { loading },
            assetFilters: currFilters,
            columnsFilter,
            assetUploads: { uploads: currUploads },
            project: { storyId: currStoryId }
        } = this.props;

        const {
            assetFilters: prevFilters,
            columnsFilter: prevColFilter,
            assetUploads: { uploads: prevUploads },
            project: { storyId: prevStoryId }
        } = prevProps;

        if (columnsFilter !== prevColFilter) {
            this.setState({ columnConfig: this.getColumnConfig(this.props) });
        } else if (
            (!loading && currFilters !== prevFilters) ||
            currUploads.length < prevUploads.length ||
            prevStoryId !== currStoryId
        ) {
            this.props.doHydrate();
            this.props.resetSelection();
        }

        const viewerTabsId = viewer.tabs.map(({ id }) => id);

        if (viewerTabsId.length > 0) {
            for (const tabId of viewerTabsId) {
                const currAsset = this.props.assetList?.assets?.find((a) => a.id === tabId);
                const prevAsset = prevProps.assetList?.assets?.find((a) => a.id === tabId);

                if (!_.isEqual(currAsset, prevAsset) && currAsset?.id) {
                    this.props.updateViewer(currAsset.id, currAsset);
                }
            }
        }
    };

    private getColumnConfig = (props) => {
        const {
            editor: { fromCrM },
            columnsFilter: {
                tags,
                duration,
                rate,
                height,
                width,
                date_updated,
                date_created,
                last_updated_by,
                id
            }
        } = props;

        const columns: any[] = [
            {
                accessor: 'status',
                width: 15,
                minWidth: 15,
                maxWidth: 15,
                disableSortBy: true,
                disableResizing: true,
                Cell: (cell: any) => <AssetsTableStatusCell cell={cell} />
            },
            {
                accessor: 'select',
                width: 25,
                minWidth: 25,
                maxWidth: 25,
                disableSortBy: true,
                disableResizing: true,
                Header: () => <AssetsTableSelectFilter />,
                Cell: (cell: any) => <AssetsTableSelectCell cell={cell} />
            },
            {
                accessor: 'type',
                disableSortBy: true,
                disableResizing: true,
                width: 25,
                minWidth: 25,
                maxWidth: 25,
                Header: () => <AssetsTableTypeFilter />,
                Cell: (cell: any) => (
                    <AssetsTablePreviewCell
                        onClick={() => this.evtHandlers.handleClick(cell)}
                        onRequestClose={this.evtHandlers.onClose}
                        showMedia={this.state.activePreview === cell.row.original.id}
                        cell={cell}
                    />
                )
            },
            {
                accessor: 'name',
                minWidth: 100,
                Header: copy.tabs.name,
                disableSortBy: false,
                Search: () => <AssetsTableNameFilter />,
                Cell: (cell: any) => {
                    const { name, type } = cell.row.original;
                    const isMainRenderCompFromCrM =
                        type === ASSET_TYPES.VIDEO_COMPOSITION &&
                        name === RESERVED_COMP_RENDER_NAME &&
                        this.props.editor.fromCrM;
                    return (
                        <AssetsTableNameCell
                            disabled={isMainRenderCompFromCrM}
                            api={api}
                            cell={cell}
                        />
                    );
                }
            }
        ];

        if (id) {
            columns.push({
                accessor: 'id',
                minWidth: 100,
                disableSortBy: true,
                Header: copy.tabs.id,
                Search: () => <AssetsTableAssetIdFilter />,
                Cell: (cell: any) => (
                    <AssetsTableAssetIdCell
                        cell={cell}
                        onNotification={(n) => logNotification(n)}
                        onError={(e) => logError(e)}
                    />
                )
            });
        }

        if (tags) {
            columns.push({
                accessor: 'tags',
                minWidth: 75,
                disableSortBy: true,
                Header: copy.tabs.tags,
                Search: () => <AssetsTableTagsFilter />,
                Cell: (cell: any) => (
                    <AssetsTableTagsCell
                        cell={cell}
                        onClick={() => this.showTags(cell)}
                    />
                )
            });
        }

        if (duration) {
            columns.push({
                accessor: 'duration',
                minWidth: 55,
                width: 55,
                Header: copy.tabs.duration,
                disableSortBy: true,
                Cell: (cell: any) => <AssetsTableDurationCell cell={cell} />
            });
        }

        if (rate) {
            columns.push({
                accessor: 'rate',
                minWidth: 50,
                width: 50,
                Header: copy.tabs.rate,
                disableSortBy: true,
                Cell: (cell: any) => <AssetsTableRateCell cell={cell} />
            });
        }
        if (width) {
            columns.push({
                accessor: 'width',
                minWidth: 50,
                width: 50,
                Header: copy.tabs.width,
                disableSortBy: true
            });
        }

        if (height) {
            columns.push({
                accessor: 'height',
                minWidth: 50,
                width: 50,
                Header: copy.tabs.height,
                disableSortBy: true
            });
        }

        if (last_updated_by) {
            columns.push({
                accessor: 'last_updated_by',
                minWidth: 120,
                disableSortBy: true,
                Header: copy.tabs.lastModifiedBy
            });
        }

        if (date_updated) {
            columns.push({
                accessor: 'date_updated',
                width: 110,
                minWidth: 110,
                disableSortBy: false,
                disableResizing: true,
                sortDescFirst: true,
                Header: copy.tabs.lastModified,
                Cell: (cell: any) => (
                    <AssetsTableDateCell
                        cell={cell}
                        date_created={false}
                    />
                )
            });
        }

        if (date_created) {
            columns.push({
                accessor: 'date_created',
                width: 110,
                minWidth: 110,
                disableSortBy: false,
                disableResizing: true,
                Header: copy.tabs.date,
                Cell: (cell: any) => (
                    <AssetsTableDateCell
                        cell={cell}
                        date_created={true}
                    />
                )
            });
        }

        columns.push({
            accessor: 'context_Menu',
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            disableResizing: true,
            overflowVisible: true,
            pin: true,
            Cell: (cell: any) => (
                <AssetContextMenu
                    asset={cell.row.original}
                    showDelete={true}
                    showClose={false}
                    showDetails={false}
                    showSettings={false}
                    position={'left'}
                    setAssetId={(i, t) => this.setAssetId(i, t)}
                    isViewer={false}
                />
            )
        });

        if (!fromCrM) {
            columns.push({
                accessor: 'story_id',
                width: 20,
                minWidth: 20,
                maxWidth: 20,
                disableSortBy: true,
                disableResizing: true,
                Cell: (cell: any) => <AssetsTableGlobalCell cell={cell} />
            });
            arrayMove(columns, columns.length - 1, 1);
        }

        return columns;
    };

    private handleTableSort = (sortState: any): void => {
        if (typeof sortState[0] === 'object') {
            this.props.updateFilters({
                order: sortState[0].id,
                order_direction: sortState[0].desc ? 'desc' : 'asc'
            });
        }
    };

    private openPreviewTab = (row: any): void => {
        const {
            original: asset,
            original: { id, name: label, type }
        } = row;
        const tabLabel =
            type === ASSET_TYPES.VIDEO_COMPOSITION
                ? `Composition: ${label || id}`
                : `Asset: ${label || id}`;

        if (type === ASSET_TYPES.VIDEO_COMPOSITION) {
            // check to see if the comp is in the compositions state, meaning it has been edited locally, if it has, set that
            const compositions = this.props.compositions;
            const compData = compositions[id] ? compositions[id] : JSON.parse(asset.data);
            this.props.updateEditorConfig({
                activeLowerPanel: LOWER_PANELS.TIMELINE
            });
            this.props.setComposition(id, compData);
        }

        this.props.addViewer({ id, label: tabLabel, asset, type });
    };

    private checkHover(monitor) {
        const isOver = monitor.isOver();
        if (isOver !== this.state.assetOver) {
            this.setState({ assetOver: isOver }, () => this.props.onTableHover(isOver));
        }
    }

    private setAssetId(assetId, assetType) {
        this.setState({ assetId, assetType }, () => this.replaceFileRef.current.click());
    }

    private replaceAsset(e: any) {
        const {
            project: { storyId },
            viewer
        } = this.props;
        const { assetId } = this.state;
        const validFiles: any[] = Array.from(e.target.files).filter((f: File) =>
            validateAssetMimetype(f)
        );

        if (validFiles.some((file) => file.size > MAX_FILE_SIZE)) {
            logError(copy.errorMaxSize);
            e.target.value = null;
            return;
        }
        for (const file of validFiles) {
            if (file.type === 'application/json') {
                const reader = new FileReader();
                reader.onload = (r) => this.props.replaceHTMLAsset(r, assetId);
                reader.readAsText(file);
            }
        }

        const validUploadFiles = validFiles.filter((f) => f.type !== 'application/json');

        if (Array.isArray(validUploadFiles) && validUploadFiles.length > -1) {
            this.props.replaceAsset(api, assetId, validUploadFiles, storyId)?.then((res) => {
                const assetTabIndex = viewer.tabs.findIndex((t) => t.id === assetId);
                if (assetTabIndex !== -1) {
                    this.props.updateViewer(assetId, res);
                }
            });
        }

        this.replaceFileRef.current.value = '';
    }

    private itemsPerPageHandler(items) {
        this.props.updateFilters({ items_per_page: items });
        this.props.updateEditorConfig({ assetsTableItemsPerPage: items });
    }

    public render = (): JSX.Element => {
        const {
            loading,
            assetList: { assets, total_pages, asset_count },
            assetFilters: { page, order, order_direction: orderDirection, items_per_page },
            viewer: { activeViewer: activeAssetId },
            assetUploads: { uploads },
            editor: { expandTags, showVariables, assetsTableItemsPerPage }
        } = this.props;

        const { columnConfig, assetType } = this.state;

        const sortBy =
            order && orderDirection ? [{ id: order, desc: orderDirection === 'desc' }] : undefined;

        const highlightBy =
            typeof activeAssetId === 'string' ? { key: 'id', value: activeAssetId } : undefined;

        const modifier = this.state.assetOver ? 'asset-over' : '';
        let data = assets;

        const uploading = [];
        if (uploads.length > 0) {
            uploads.map((u) => {
                if (u.percent > 0) {
                    console.log(u);
                    const obj = {
                        name: u.filename,
                        percent: u.percent,
                        assetId: u?.assetId,
                        story_id: u?.storyId,
                        type: 'upload'
                    };
                    if (u.error) {
                        Object.assign(obj, { error: u.error, type: 'error' });
                    }
                    uploading.push(obj);
                }
            });

            data = uploading.concat(data);
        }

        const totalPages = total_pages === 0 ? 1 : total_pages;
        const uploadMenuHover = this.props.uploadMenuHover ? 'upload-menu-hover' : '';
        const itemsPerPage = assetsTableItemsPerPage ? assetsTableItemsPerPage : items_per_page;

        return (
            <>
                <AssetsTableDropzone
                    className={`data-table-wrapper ${modifier} ${uploadMenuHover}`}
                    onDrop={(i, m) => uploadAssetsHandler(i, m)}
                    onExternalCollect={(o) => this.checkHover(o)}>
                    <DataTable
                        dnd={!showVariables}
                        tightRows={true}
                        dndType={ASSET_DND_TYPES.ASSET_NAME}
                        dndName='Asset'
                        data={data}
                        columns={columnConfig}
                        showInterstitial={loading}
                        noDataText={copy.noData}
                        highlightBy={highlightBy}
                        onRowDoubleClick={this.openPreviewTab}
                        sortBy={sortBy}
                        onSort={this.handleTableSort}
                        currentPage={Number(page)}
                        totalPages={totalPages}
                        totalItems={asset_count}
                        onPage={(p) => this.props.updateFilters({ page: p })}
                        onItemsPerPage={(i) => this.itemsPerPageHandler(i)}
                        itemsPerPage={itemsPerPage}
                        expandTags={expandTags}
                        renderPivotComponent={(row) => {
                            const rowKey = row?.original?.id;
                            return (
                                <AssetsTableTagsPivot
                                    key={rowKey}
                                    api={api}
                                    row={row}
                                    onNotification={(n) => logNotification(n)}
                                    onError={(e) => logError(e)}
                                />
                            );
                        }}
                    />
                    <div className='overlay'>
                        <p>{copy.dropAsset}</p>
                    </div>
                    <input
                        id='replace-file'
                        className='file-hidden'
                        accept={getInputFilter(assetType)}
                        type='file'
                        ref={this.replaceFileRef}
                        onChange={(e) => this.replaceAsset(e)}
                    />
                </AssetsTableDropzone>
            </>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            updateFilters,
            resetSelection,
            addViewer,
            updateViewer,
            replaceViewer,
            setComposition,
            updateEditorConfig,
            replaceAsset,
            createNewHTMLAsset,
            uploadHTMLAsset,
            replaceHTMLAsset
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        viewer: state.story.viewer || { ...NEW_EMPTY_DEFAULT_VIEWER },
        project: state.project,
        compositions: state.compositions.present,
        assetList: state.assetList,
        assetFilters: state.assetFilters,
        assetUploads: state.assetUploads,
        editor: state.editor
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsTable);
