import * as React from 'react';
import { useState, CSSProperties } from 'react';
import { useDrop } from 'react-dnd';
import { ICompositionLayer } from '../../constants/snippets';
import {
    ASSET_DND_TYPES,
    LAYER_DND_TYPES,
    LAYER_EXPANDED_ROW_HEIGHT,
    LAYER_ROW_HEIGHT
} from '../../constants/timeline';
import { onDropLoc } from '../../constants/story';
import { LayerDropIndicator } from './LayerDropIndicator';
import { dropableAssetTypeCheck } from '../../util/general';

interface ILayerDropTargetProps {
    children: any;
    onLayerDrop(index: number, offset: number);
    onAssetDrop(asset, index, from);
    height: number;
    layers: ICompositionLayer[];
    expandedLayers: string[];
    totalLayers: number;
}

export const LayerDropTarget: React.FC<ILayerDropTargetProps> = (p: ILayerDropTargetProps) => {
    const { onLayerDrop, layers, onAssetDrop, expandedLayers, totalLayers, height, children } = p;

    const [isDrag, setIsDrag] = useState<boolean>();

    const onLayerIndicatorDrop = (item: any, index: number) => {
        if (item.type === ASSET_DND_TYPES.ASSET_NAME) {
            onAssetDrop(item.rowData, index, onDropLoc.INDICATOR);
        } else {
            onLayerDrop(item.id, index);
        }
    };

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [LAYER_DND_TYPES.LAYER_NAME, ASSET_DND_TYPES.ASSET_NAME],
        canDrop: (item: any) => {
            return item.type === LAYER_DND_TYPES.LAYER_NAME
                ? true
                : dropableAssetTypeCheck(item.rowData.type);
        },
        drop: (item: any) => {
            if (item.type === ASSET_DND_TYPES.ASSET_NAME) {
                setIsDrag(false);
                onAssetDrop(item.rowData, 0, onDropLoc.TARGET);
            }
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        })
    });

    const indicators = [];

    if (isOver) {
        let runningRowHeight: number = LAYER_ROW_HEIGHT;
        for (let i = 0; i <= layers.length; i++) {
            const mod = i === 0 ? 'first' : i === layers.length ? 'last' : '';

            const atLayer = layers[layers.length - i];
            if (atLayer) {
                const layerHeight =
                    expandedLayers.indexOf(atLayer.id) !== -1
                        ? LAYER_EXPANDED_ROW_HEIGHT
                        : LAYER_ROW_HEIGHT;
                runningRowHeight += layerHeight;
            }

            indicators.push(
                <LayerDropIndicator
                    modifier={mod}
                    index={layers.length - i}
                    onDrop={onLayerIndicatorDrop}
                    top={runningRowHeight}
                    key={i}
                />
            );
        }
    }

    const topOffset = Math.floor(LAYER_ROW_HEIGHT / 2);

    let getTop = (totalLayers + 1) * LAYER_ROW_HEIGHT + topOffset;
    if (expandedLayers.length > 0) {
        getTop += (LAYER_EXPANDED_ROW_HEIGHT - LAYER_ROW_HEIGHT) * expandedLayers.length;
    }
    const getHeight = height - getTop;
    const style: CSSProperties = {
        top: `${getTop}px`,
        height: `${getHeight}px`
    };

    const drag =
        canDrop && isOver ? (
            <div
                className='layer-drop-indicator drag'
                onDragEnter={() => setIsDrag(true)}
                onDragLeave={() => setIsDrag(false)}
                style={style}>
                {isDrag ? <div className='layer-position-indicator' /> : null}
            </div>
        ) : null;

    return (
        <div
            style={{ height: `${height}px` }}
            className='layer-drop-target'
            ref={drop}>
            {children}
            {drag}
            {indicators}
        </div>
    );
};
