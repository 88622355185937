import * as React from 'react';
import SplitPane from 'react-split-pane';
import LeftHeader from './LeftHeader';
import AssetsSection from './assets/AssetsSection';
import RightHeader from './RightHeader';
import ImageStoryConfig from './ImageStoryConfig';
import Viewer from './viewer/Viewer';
import { IStory } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import { sections as sectionCopy } from '../constants/copy';
import { Button } from '@imposium-hub/components';
import { updateEditorConfig } from '../redux/actions/editor';
import { connect } from 'react-redux';
import { updateScene } from '../redux/actions/story';
import PaneErrorBoundry from './errors/PaneErrorBoundry';

import {
    HEADER_HEIGHT,
    LEFT_PANEL_MIN,
    LEFT_PANEL_MAX_IMG,
    LEFT_PANEL_DEFAULT_IMG,
    VAR_PANEL_DEFAULT
} from '../constants/editor';
import VariablePanel from './VariablePanel';
import { ITimelineState } from '../redux/reducers/timeline';

interface IImageStoryInterfaceProps {
    story: IStory;
    editor: IEditor;
    timeline: ITimelineState;
    project: IProject;
    updateScene(c): void;
    updateEditorConfig(c): void;
    onStoryChange?: (story: any) => any;
}

class ImageStoryInterface extends React.PureComponent<IImageStoryInterfaceProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            toggleVars: () => this.toggleVars()
        };
    }

    private toggleVars() {
        this.props.updateEditorConfig({
            varsOpen: !this.props.editor.varsOpen
        });
    }

    private onConfigUpdate(e) {
        const {
            story,
            project: { actId, sceneId }
        } = this.props;
        const act = story.acts[actId];
        const scene = act.scenes[sceneId];

        const newScene = { ...scene };
        newScene.sceneData = e;

        const updateConfig = {
            actId,
            sceneId: scene.id,
            scene: newScene
        };

        this.props.updateScene(updateConfig);
    }

    public render() {
        const {
            story,
            editor: { varsOpen },
            timeline: { activeTimelineOverlay },
            project: { actId, sceneId }
        } = this.props;

        const act = story.acts[actId];
        const scene = act.scenes[sceneId];

        const btnOpenVars = (
            <Button
                style='tab'
                onClick={this.evtHandlers.toggleVars}
                customStyles={{
                    position: 'absolute',
                    top: '2px',
                    right: '2px'
                }}>
                {sectionCopy.variables}
            </Button>
        );

        return (
            <SplitPane
                split='vertical'
                minSize={LEFT_PANEL_MIN}
                maxSize={LEFT_PANEL_MAX_IMG}
                defaultSize={LEFT_PANEL_DEFAULT_IMG}
                primary='first'>
                <SplitPane
                    split='horizontal'
                    minSize={HEADER_HEIGHT}
                    maxSize={HEADER_HEIGHT}
                    defaultSize={HEADER_HEIGHT}
                    allowResize={false}
                    primary='first'>
                    <PaneErrorBoundry>
                        <LeftHeader
                            disableUnpublishedPreview={true}
                            onStoryChange={this.props.onStoryChange}
                        />
                    </PaneErrorBoundry>

                    <SplitPane
                        split='horizontal'
                        defaultSize='50%'
                        primary='first'>
                        <div className='panel'>
                            <PaneErrorBoundry>
                                <AssetsSection />
                            </PaneErrorBoundry>
                        </div>

                        <div className='panel'>
                            <PaneErrorBoundry>
                                <ImageStoryConfig
                                    variables={act.inventory}
                                    config={scene.sceneData}
                                    onUpdate={(e) => this.onConfigUpdate(e)}
                                    activeOverlay={activeTimelineOverlay}
                                />
                            </PaneErrorBoundry>
                        </div>
                    </SplitPane>
                </SplitPane>

                <SplitPane
                    split='horizontal'
                    minSize={HEADER_HEIGHT}
                    maxSize={HEADER_HEIGHT}
                    defaultSize={HEADER_HEIGHT}
                    allowResize={false}
                    primary='first'>
                    <PaneErrorBoundry>
                        <RightHeader />
                    </PaneErrorBoundry>

                    <SplitPane
                        split='vertical'
                        allowResize={false}
                        defaultSize={varsOpen ? VAR_PANEL_DEFAULT : 0}
                        primary='second'>
                        <div className='panel'>
                            <PaneErrorBoundry>
                                <Viewer scene={scene} />
                                {!varsOpen ? btnOpenVars : null}
                            </PaneErrorBoundry>
                        </div>

                        <div className='panel'>
                            <PaneErrorBoundry>
                                <VariablePanel />
                            </PaneErrorBoundry>
                        </div>
                    </SplitPane>
                </SplitPane>
            </SplitPane>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEditorConfig: (c) => dispatch(updateEditorConfig(c)),
        updateScene: (c) => dispatch(updateScene(c))
    };
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        editor: state.editor,
        timeline: state.timeline[state.project.compositionId],
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageStoryInterface);
