import * as React from 'react';
import { Card, ColorField, SelectField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import {
    colorPresets,
    IVariables,
    NEW_ASSET_SOLID_SOURCE,
    NEW_INVENTORY_SOURCE
} from '../constants/snippets';
import { SOURCE_TYPE_OPTIONS, SOURCE_TYPES } from '../constants/story';
import { getVariableOptions } from '../util/story';
import { formatColor } from '../util/ui';

interface ISolidLayerOptionsProps {
    variables: IVariables;
    type: any;
    options: any;
    onChange(options: any): void;
}

class SolidLayerOptions extends React.PureComponent<ISolidLayerOptionsProps> {
    private evtHandlers = {
        sourceType: (f) => this.sourceTypeChanged(f),
        color: (v) =>
            this.updateSouceField({
                from: this.props.options.source.from,
                color: formatColor(v, true)
            }),
        invId: (v) =>
            this.updateSouceField({
                ...this.props.options.source,
                from: this.props.options.source.from,
                inventory_id: v
            })
    };

    constructor(props) {
        super(props);
    }

    private updateSouceField(source) {
        this.updateOptionsFields({ source });
    }

    private updateOptionsFields(options) {
        const merged = { ...this.props.options, ...options };
        this.props.onChange(merged);
    }

    private sourceTypeChanged(type) {
        const { type: layerType } = this.props;
        let src;

        switch (type) {
            // variable
            case SOURCE_TYPES.INVENTORY:
                src = { ...NEW_INVENTORY_SOURCE };
                src['asset_type'] = layerType;
                break;

            // color
            case SOURCE_TYPES.COLOR:
                src = { ...NEW_ASSET_SOLID_SOURCE };
                src['asset_type'] = layerType;
                break;
        }

        this.updateOptionsFields({ source: src });
    }

    private renderSourceInputs() {
        const {
            options: {
                source,
                source: { from, inventory_id }
            },
            variables
        } = this.props;

        let inputs = [];

        switch (from) {
            case SOURCE_TYPES.COLOR:
                const color = source.color
                    ? source.color === '#00000000'
                        ? 'transparent'
                        : source.color
                    : '#ff0000ff';

                inputs = [
                    <ColorField
                        key='color-field'
                        width='50%'
                        enableAlpha={true}
                        presetColors={colorPresets}
                        label={copy.global.color}
                        onChange={this.evtHandlers.color}
                        value={color}
                    />
                ];
                break;
            case SOURCE_TYPES.INVENTORY:
                inputs = [
                    <SelectField
                        label={copy.global.variable}
                        tooltip={copy.overlayConfig.tooltipVariable}
                        key='inventory-id-field'
                        width='50%'
                        value={inventory_id}
                        options={getVariableOptions(variables, ['color'])}
                        onChange={this.evtHandlers.invId}
                    />
                ];
                break;
        }

        return inputs;
    }

    public render() {
        const {
            type,
            options: {
                source: { from }
            }
        } = this.props;

        const wrapperStyle: React.CSSProperties = {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        };

        return (
            <Card
                title={copy.layerConfig.options}
                open={true}
                collapsable={true}
                style={'options'}>
                <div style={wrapperStyle}>
                    <SelectField
                        label={copy.layerConfig.sourceType}
                        tooltip={copy.layerConfig.tooltipSourceType}
                        value={from}
                        width='50%'
                        options={SOURCE_TYPE_OPTIONS[type]}
                        onChange={this.evtHandlers.sourceType}
                    />
                    {this.renderSourceInputs()}
                </div>
            </Card>
        );
    }
}

export default SolidLayerOptions;
