import * as React from 'react';
import TagFields from './TagFields';
import CustomVarFields from './CustomVarFields';
import {
    Card,
    FieldWrapper,
    TextField,
    CheckboxField,
    NumberField,
    SelectField,
    HRule,
    Button,
    ButtonGroupField,
    Section,
    ButtonMenu,
    ButtonMenuItem,
    ColorField
} from '@imposium-hub/components';
import { getVariableOptions, getVariableVersions, matchType } from '../util/story';
import { logError } from '../util/notifications';
import EffectConfig from './EffectConfig';
import { fields as copy } from '../constants/copy';
import { ITemplateArchiveBody, downloadTemplateArchive } from '../util/template-generator';
import {
    ICON_PLAY,
    ICON_COPY,
    ICON_TRASH,
    ICON_ELLIPSIS_V,
    ICON_LAYER_GROUP
} from '../constants/icons';
import { api } from '../constants/app';
import { isSuperUser } from './SuperUserOnly';

import {
    SOURCE_TYPES,
    OVERLAY_TYPES,
    ASSET_TYPES,
    OVERLAY_TYPE_OPTIONS,
    ADVANCED_OVERLAY_TYPE_OPTIONS,
    ASSET_TYPE_OPTIONS,
    SOURCE_TYPE_OPTIONS,
    OVERLAY_POSITION_TYPES,
    OVERLAY_ENGINE_OPTIONS,
    SCENE_TYPES
} from '../constants/story';

import {
    NEW_INVENTORY_SOURCE,
    NEW_ASSET_SOURCE,
    NEW_ASSET_TAGS_SOURCE,
    NEW_QUAD_POSITION,
    NEW_RECT_POSITION,
    NEW_MT_QUAD_POSITION,
    NEW_MT_RECT_POSITION,
    IOverlay,
    colorPresets
} from '../constants/snippets';
import ErrorIndicator from './errors/ErrorIndicator';
import { STORY_ERRORS } from '../constants/errors';
import AssetField from './assets/AssetField';
import { openConfirmModal } from '../util/ui';

interface IOverlayConfigProps {
    config: IOverlay;
    width: number;
    height: number;
    fps: number;
    variables: any;
    storyId: string;
    type: string;
    onChange(config): void;
    onDuplicate(config): void;
    onDelete(): void;
    onPreview(evt): void;
}

class OverlayConfig extends React.PureComponent<IOverlayConfigProps> {
    private evtHandlers;

    private menuItems: JSX.Element[];

    private menu: JSX.Element;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                type: (v) => this.overlayTypeChanged(v),
                width: (v) => this.inputChanged('width', v),
                height: (v) => this.inputChanged('height', v),
                engine: (v) => this.inputChanged('engine', v),
                bgColor: (v) => this.bgChanged(v),
                enabled: (v) => this.inputChanged('enabled', v),
                name: (v) => this.inputChanged('name', v),
                assetType: (v) => this.sourceFieldChanged('asset_type', v),
                invId: (v) => this.sourceFieldChanged('inventory_id', v),
                invVersion: (v) => this.sourceFieldChanged('version', v),
                assetId: (v) => this.sourceFieldChanged('asset_id', v ? v.id : null),
                assetTags: (v) => this.sourceFieldChanged('asset_tags', v),
                assetVars: (v) => this.sourceFieldChanged('asset_vars', v),
                script: (v) => this.inputChanged('generating_script', v),
                sourceType: (v) => this.sourceTypeChanged(v),
                posType: (t) => this.positionTypeChanged(t),
                position: (v) => this.inputChanged('position_inputs', v),
                videoOffset: (v) => this.sourceFieldChanged('offset', v),
                assetError: (m) => {
                    logError(m);
                }
            },
            buttons: {
                preview: (e) => this.props.onPreview(e),
                duplicate: () => this.duplicateOverlay(),
                delete: () => this.confirmDelete(),
                downloadTemplate: () => void this.downloadTemplate()
            }
        };

        this.menuItems = [
            <ButtonMenuItem
                key='preview'
                label={
                    <span>
                        {ICON_PLAY}&nbsp;{copy.cutConfig.btnPreview}
                    </span>
                }
                onClick={this.evtHandlers.buttons.preview}
            />,
            <ButtonMenuItem
                key='duplicate'
                label={
                    <span>
                        {ICON_COPY}&nbsp;{copy.overlayConfig.btnDuplicateOverlay}
                    </span>
                }
                onClick={this.evtHandlers.buttons.duplicate}
            />,
            <ButtonMenuItem
                key='delete'
                label={
                    <span>
                        {ICON_TRASH}&nbsp;{copy.overlayConfig.btnDeleteOverlay}
                    </span>
                }
                onClick={this.evtHandlers.buttons.delete}
            />
        ];

        // Remove preview button from menu if Story type is not Video Scene
        if (this.props.type !== 'VideoScene01') {
            this.menuItems = this.menuItems.filter((items) => items.key !== 'preview');
        }

        this.menu = (
            <ButtonMenu
                position='left'
                items={this.menuItems}
                button={<Button style='subtle'>{ICON_ELLIPSIS_V}</Button>}
            />
        );
    }

    private sourceFieldChanged(key, value) {
        const config = { ...this.props.config };
        const src = { ...config['source'] };
        src[key] = value;
        config['source'] = src;

        this.props.onChange(config);
    }

    private confirmDelete() {
        const { config } = this.props;

        openConfirmModal({
            onYes: () => this.props.onDelete(),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private duplicateOverlay() {
        this.props.onDuplicate(this.props.config);
    }

    private overlayTypeChanged(type) {
        const config = { ...this.props.config };
        config['type'] = type;

        // set the source type to the first option in the array
        this.sourceTypeChanged(SOURCE_TYPE_OPTIONS[type][0].value, config);
    }

    private positionTypeChanged(type) {
        const config = { ...this.props.config };
        config['position'] = type;

        switch (type) {
            case OVERLAY_POSITION_TYPES.MT_QUAD:
                config['position_inputs'] = { ...NEW_MT_QUAD_POSITION };
                break;

            case OVERLAY_POSITION_TYPES.QUAD:
                config['position_inputs'] = { ...NEW_QUAD_POSITION };
                break;

            case OVERLAY_POSITION_TYPES.RECT:
                config['position_inputs'] = this.getFullscreenRect();
                break;

            case OVERLAY_POSITION_TYPES.MT_RECT:
                config['position_inputs'] = { ...NEW_MT_RECT_POSITION };
                break;

            default:
                config['position_inputs'] = {};
        }

        this.props.onChange(config);
    }

    private getFullscreenRect() {
        const { width, height } = this.props;
        const conf = { ...NEW_RECT_POSITION };
        conf.x = 0;
        conf.y = 0;
        conf.width = width;
        conf.height = height;

        return conf;
    }

    private sourceTypeChanged(type, seed?) {
        const config = seed ? seed : { ...this.props.config };
        let src;

        switch (type) {
            // script
            case SOURCE_TYPES.SCRIPT:
                config['source'] = null;
                config['generating_script'] = '';
                break;

            // variable
            case SOURCE_TYPES.INVENTORY:
                config['source'] = { ...NEW_INVENTORY_SOURCE };
                config['generating_script'] = '';
                break;

            // asset
            case SOURCE_TYPES.ASSET:
                src = { ...NEW_ASSET_SOURCE };
                src['asset_type'] = config.type;
                config['source'] = src;
                config['generating_script'] = '';
                break;

            // asset from tags
            case SOURCE_TYPES.ASSET_TAGS:
                src = { ...NEW_ASSET_TAGS_SOURCE };
                src['asset_type'] = config.type;
                config['source'] = src;
                config['generating_script'] = '';
                break;
        }

        this.props.onChange(config);
    }

    public inputChanged(key, value) {
        const config = { ...this.props.config };
        if (key === 'width' || key === 'height') {
            config[key] = parseInt(value, 10);
        } else {
            config[key] = value;
        }

        this.props.onChange(config);
    }

    private bgChanged(color) {
        if (color.hex === 'transparent') {
            this.inputChanged('background_color', null);
        } else {
            const values = color.rgb;
            this.inputChanged(
                'background_color',
                `rgba(${values.r},${values.g},${values.b},${values.a})`
            );
        }
    }

    private renderTemplateInputs() {
        const {
            config,
            config: { source, type, engine, background_color }
        } = this.props;
        let dimensionInputs = [];
        let customVarFields;

        // only render the template inputs on image and video overlays
        if (type === OVERLAY_TYPES.IMAGE || type === OVERLAY_TYPES.VIDEO) {
            // only render the template inputs if the source is a script (!source), or a template asset (from either tags or direct)
            if (
                !source ||
                (source &&
                    source['from'] === SOURCE_TYPES.ASSET &&
                    source['asset_type'] === ASSET_TYPES.TEMPLATE) ||
                (source &&
                    source['from'] === SOURCE_TYPES.ASSET_TAGS &&
                    source['asset_type'] === ASSET_TYPES.TEMPLATE)
            ) {
                const engineType = engine ? engine : '';
                const bgColor = background_color ? background_color : 'transparent';

                if (source) {
                    customVarFields = (
                        <CustomVarFields
                            key='var-fields'
                            vars={source['asset_vars']}
                            onChange={this.evtHandlers.inputs.assetVars}
                        />
                    );
                }

                dimensionInputs = [
                    <HRule
                        key='rule-1'
                        style='subtle'
                    />,
                    <NumberField
                        label={copy.overlayConfig.templateWidth}
                        tooltip={copy.overlayConfig.tooltipTempWidth}
                        width='50%'
                        key='overlay-width'
                        value={config['width']}
                        onChange={this.evtHandlers.inputs.width}
                    />,
                    <NumberField
                        label={copy.overlayConfig.templateHeight}
                        tooltip={copy.overlayConfig.tooltipTempHeight}
                        width='50%'
                        key='overlay-height'
                        value={config['height']}
                        onChange={this.evtHandlers.inputs.height}
                    />,
                    <ColorField
                        label={copy.overlayConfig.templateBgColor}
                        tooltip={copy.overlayConfig.tooltipBgColor}
                        width='50%'
                        key='temp-bg-color'
                        value={bgColor}
                        presetColors={colorPresets}
                        enableAlpha={true}
                        onChange={this.evtHandlers.inputs.bgColor}
                    />,
                    <ButtonGroupField
                        label={copy.overlayConfig.engine}
                        tooltip={copy.overlayConfig.tooltipEngine}
                        value={engineType}
                        width='50%'
                        key='engine'
                        options={OVERLAY_ENGINE_OPTIONS}
                        onChange={this.evtHandlers.inputs.engine}
                    />,
                    <FieldWrapper
                        width='50%'
                        tooltip={copy.overlayConfig.tooltipDownloadTemplate}
                        label={copy.overlayConfig.templateDownload}
                        key='download-template'>
                        <Button
                            disabled={!config.width || !config.height}
                            onClick={this.evtHandlers.buttons.downloadTemplate}>
                            {copy.overlayConfig.downloadButton}
                        </Button>
                    </FieldWrapper>,
                    <HRule
                        key='rule-2'
                        style='subtle'
                    />,
                    customVarFields
                ];
            }
        }

        return dimensionInputs;
    }

    private downloadTemplate() {
        const {
            config,
            fps,
            config: { type },
            variables
        } = this.props;

        const archiveName: string = `${config.name} - Template.zip`;
        const templateType =
            type === OVERLAY_TYPES.IMAGE
                ? ASSET_TYPES.IMAGE
                : type === OVERLAY_TYPES.VIDEO
                ? ASSET_TYPES.IMAGE_SEQUENCE
                : null;

        const templateOverrides: ITemplateArchiveBody = {
            fps,
            type: templateType,
            width: parseFloat(String(config.width)),
            height: parseFloat(String(config.height)),
            background_color: config.background_color,
            inventory: {}
        };

        const variablesKeys: string[] = Object.keys(variables);

        if (variablesKeys.length > 0) {
            variablesKeys.forEach((key) => {
                if (variables[key].hasOwnProperty('id')) {
                    templateOverrides.inventory[variables[key].id] = null;

                    if (
                        variables[key].hasOwnProperty('previewItem') &&
                        variables[key].previewItem.hasOwnProperty('src') &&
                        !variables[key].previewItem.hasOwnProperty('url')
                    ) {
                        templateOverrides.inventory[variables[key].id] =
                            variables[key].previewItem.src;
                    }

                    if (
                        variables[key].hasOwnProperty('previewItem') &&
                        variables[key].previewItem.hasOwnProperty('url')
                    ) {
                        templateOverrides.inventory[variables[key].id] =
                            variables[key].previewItem.url;
                    }
                }
            });
        }

        downloadTemplateArchive(archiveName, templateOverrides);
    }

    private renderSourceInputs() {
        const {
            config,
            config: { source, type },
            variables,
            storyId
        } = this.props;
        let inputs;

        if (source) {
            const assetTypeSelect = (
                <SelectField
                    label={copy.overlayConfig.assetType}
                    tooltip={copy.overlayConfig.tooltipAssetType}
                    key='asset-type-field'
                    width='50%'
                    value={source['asset_type']}
                    options={ASSET_TYPE_OPTIONS[type]}
                    onChange={this.evtHandlers.inputs.assetType}
                />
            );

            switch (source['from']) {
                case SOURCE_TYPES.INVENTORY:
                    const version =
                        type === OVERLAY_TYPES.VIDEO && source['inventory_id'] ? (
                            <SelectField
                                label={copy.global.version}
                                tooltip={copy.overlayConfig.tooltipVersion}
                                key='inventory-version-field'
                                width='50%'
                                value={source['version']}
                                options={getVariableVersions(variables, source['inventory_id'])}
                                onChange={this.evtHandlers.inputs.invVersion}
                            />
                        ) : null;

                    const offset =
                        type === OVERLAY_TYPES.VIDEO && source['inventory_id'] ? (
                            <NumberField
                                key='inventory-offset-field'
                                label={copy.overlayConfig.offset}
                                tooltip={copy.overlayConfig.tooltipOffset}
                                width='50%'
                                value={source['offset'] || 0}
                                onChange={this.evtHandlers.inputs.videoOffset}
                            />
                        ) : null;

                    inputs = [
                        <SelectField
                            label={copy.global.variable}
                            tooltip={copy.overlayConfig.tooltipVariable}
                            key='inventory-id-field'
                            width='50%'
                            value={source['inventory_id']}
                            options={getVariableOptions(variables, [config.type])}
                            onChange={this.evtHandlers.inputs.invId}
                        />,
                        version,
                        offset
                    ];
                    break;

                case SOURCE_TYPES.ASSET:
                    inputs = [
                        assetTypeSelect,
                        <AssetField
                            api={api}
                            storyId={storyId}
                            label={copy.global.asset}
                            tooltip={copy.overlayConfig.tooltipAsset}
                            key='asset-field'
                            onChange={this.evtHandlers.inputs.assetId}
                            onError={this.evtHandlers.inputs.assetError}
                            accepts={source['asset_type']}
                            assetId={source['asset_id']}
                        />
                    ];
                    break;

                case SOURCE_TYPES.ASSET_TAGS:
                    inputs = [
                        assetTypeSelect,
                        <TagFields
                            key='tag-fields'
                            tags={source['asset_tags']}
                            variables={variables}
                            onChange={this.evtHandlers.inputs.assetTags}
                        />
                    ];
                    break;
            }
        } else {
            inputs = [
                <TextField
                    label={copy.overlayConfig.script}
                    tooltip={copy.overlayConfig.tooltipMediaScript}
                    width='50%'
                    key='media-script-field'
                    value={config['generating_script']}
                    onChange={this.evtHandlers.inputs.script}
                />
            ];
        }

        return inputs;
    }

    private renderSourceSelect() {
        const {
            config: { type },
            config
        } = this.props;
        const value = config.source ? config.source.from : '';

        return (
            <SelectField
                label={copy.overlayConfig.sourceType}
                tooltip={copy.overlayConfig.tooltipSourceType}
                value={value}
                width='50%'
                options={SOURCE_TYPE_OPTIONS[type]}
                onChange={this.evtHandlers.inputs.sourceType}
            />
        );
    }

    public render = (): JSX.Element => {
        const { config, type, storyId } = this.props;
        const typeOpts = isSuperUser() ? ADVANCED_OVERLAY_TYPE_OPTIONS : OVERLAY_TYPE_OPTIONS;

        return (
            <Section
                title={
                    <>
                        {ICON_LAYER_GROUP}&nbsp;{config.name}
                    </>
                }
                buttons={this.menu}>
                <Card
                    title={copy.global.general}
                    open={true}
                    collapsable={false}>
                    <TextField
                        label={copy.global.name}
                        tooltip={copy.overlayConfig.tooltipName}
                        value={config['name']}
                        onChange={this.evtHandlers.inputs.name}
                    />

                    {matchType(
                        <SelectField
                            label={copy.global.type}
                            tooltip={copy.overlayConfig.tooltipType}
                            value={config['type']}
                            width='50%'
                            options={typeOpts}
                            onChange={this.evtHandlers.inputs.type}
                        />,
                        type,
                        SCENE_TYPES.VIDEO
                    )}

                    <CheckboxField
                        label={copy.global.enabled}
                        tooltip={copy.overlayConfig.tooltipEnabled}
                        value={config['enabled']}
                        width='50%'
                        onChange={this.evtHandlers.inputs.enabled}
                    />
                </Card>
                <Card
                    title={copy.overlayConfig.source}
                    open={true}
                    collapsable={true}>
                    <ErrorIndicator
                        overlayId={config.id}
                        show={[
                            STORY_ERRORS.OVERLAY_ASSET_ERROR,
                            STORY_ERRORS.OVERLAY_ASSET_NOT_FOUND,
                            STORY_ERRORS.OVERLAY_ASSET_TAG_ERROR,
                            STORY_ERRORS.OVERLAY_SCRIPT_ERROR,
                            STORY_ERRORS.OVERLAY_VARIABLE_ERROR
                        ]}
                    />
                    {this.renderSourceSelect()}
                    {this.renderSourceInputs()}
                    {this.renderTemplateInputs()}
                </Card>
                <Card
                    title={copy.overlayConfig.position}
                    open={true}
                    collapsable={true}>
                    <ErrorIndicator
                        overlayId={config.id}
                        show={[
                            STORY_ERRORS.OVERLAY_RECT_ERROR,
                            STORY_ERRORS.OVERLAY_CORNER_PIN_ERROR,
                            STORY_ERRORS.OVERLAY_CORNER_PIN_SEQUENCE_ERROR,
                            STORY_ERRORS.OVERLAY_RECT_SEQUENCE_ERROR
                        ]}
                    />
                </Card>
                <Card
                    title={copy.overlayConfig.effects}
                    open={true}
                    collapsable={true}>
                    <ErrorIndicator
                        overlayId={config.id}
                        show={[STORY_ERRORS.OVERLAY_EFFECT_MASK_MISSING]}
                    />
                    <EffectConfig
                        storyId={storyId}
                        config={config['effects'] || {}}
                        overlayType={type}
                        onChange={(v) => this.inputChanged('effects', v)}
                    />
                </Card>
            </Section>
        );
    };
}

export default OverlayConfig;
