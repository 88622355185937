import * as React from 'react';
import {
    ENCODING_SETTING_RESIZE_OPTIONS,
    IMAGE_ENCODING_SETTING_EXTENSIONS,
    ENCODING_SETTING_QUALITY_OPTIONS
} from '../constants/story';
import { TextField, SelectField, NumberField, Card, HRule, Button } from '@imposium-hub/components';
import { IImageStoryEncodingSetting } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { ICON_COPY, ICON_TRASH, ICON_CHECK, ICON_TIMES } from '../constants/icons';
import { openConfirmModal } from '../util/ui';

interface IImageOutputSettingProps {
    config: IImageStoryEncodingSetting;
    isNew?: boolean;
    onUpdate?(c): void;
    onDelete?(): void;
    onDuplicate?(): void;
    onConfirm?(c): void;
    onCancel?(): void;
}

class ImageOutputSetting extends React.PureComponent<IImageOutputSettingProps> {
    private nameField: any;

    constructor(props) {
        super(props);

        this.nameField = React.createRef();
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        if (key === 'width' || key === 'height') {
            newConfig[key] = parseInt(value, 10);
        } else {
            newConfig[key] = value;
        }
        this.props.onUpdate(newConfig);
    }

    private confirmDelete() {
        const { config } = this.props;
        openConfirmModal({
            onYes: () => this.props.onDelete(),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private renderButtons() {
        const { config, isNew } = this.props;

        if (isNew) {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        color='primary'
                        onClick={() => this.props.onConfirm(config)}>
                        {ICON_CHECK}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onCancel()}>
                        {ICON_TIMES}
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onDuplicate()}
                        tooltip={copy.global.duplicate}>
                        {ICON_COPY}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.confirmDelete()}
                        tooltip={copy.global.delete}>
                        {ICON_TRASH}
                    </Button>
                </div>
            );
        }
    }

    public componentDidMount() {
        const { isNew } = this.props;
        const field = this.nameField.current;

        if (isNew) {
            if (field) {
                field.setEditing(true);
            }
        }
    }

    public render() {
        const { config, isNew } = this.props;
        const nameReadOnly = !isNew;

        const title = isNew ? (
            <span className='text-primary'>{copy.encoding.newImageOutput}</span>
        ) : (
            config['name']
        );

        return (
            <Card
                title={title}
                collapsable={!isNew}
                open={isNew}>
                <TextField
                    ref={this.nameField}
                    label={copy.global.id}
                    readOnly={nameReadOnly}
                    disabled={nameReadOnly}
                    tooltip={copy.encoding.tooltipID}
                    value={config['name']}
                    width='50%'
                    pattern={/^$|^[a-zA-Z0-9_-]+$/}
                    onChange={(v) => this.inputChanged('name', v)}
                />

                <SelectField
                    label={copy.encoding.format}
                    tooltip={copy.encoding.tooltipImageFormat}
                    options={IMAGE_ENCODING_SETTING_EXTENSIONS}
                    value={config['extension']}
                    width='50%'
                    onChange={(v) => this.inputChanged('extension', v)}
                />

                <NumberField
                    label={copy.global.width}
                    tooltip={copy.encoding.tooltipWidth}
                    value={config['width']}
                    width='50%'
                    onChange={(v) => this.inputChanged('width', v)}
                />

                <NumberField
                    label={copy.global.height}
                    tooltip={copy.encoding.tooltipHeight}
                    value={config['height']}
                    width='50%'
                    onChange={(v) => this.inputChanged('height', v)}
                />

                <SelectField
                    label={copy.encoding.quality}
                    tooltip={copy.encoding.tooltipQuality}
                    options={ENCODING_SETTING_QUALITY_OPTIONS}
                    value={config['quality']}
                    width='50%'
                    onChange={(v) => this.inputChanged('quality', v)}
                />

                <SelectField
                    label={copy.encoding.resize}
                    tooltip={copy.encoding.tooltipResize}
                    options={ENCODING_SETTING_RESIZE_OPTIONS}
                    value={config['resize']}
                    width='50%'
                    onChange={(v) => this.inputChanged('resize', v)}
                />

                <HRule style='subtle' />

                {this.renderButtons()}
            </Card>
        );
    }
}

export default ImageOutputSetting;
