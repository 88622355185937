import axios, { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';

export interface ITemplateArchiveBody {
    type: string;
    width: number;
    height: number;
    fps: number;
    frame?: number;
    frames?: number;
    frame_count?: number;
    background_color: string;
    inventory: any;
    assets?: any;
}

export interface ISDKArchiveBody {
    storyId: string;
    compositionId: string;
    accessToken: string;
    storyName: string;
    inventory: any;
}

const DEFAULT_GENERATOR_REQ_CONFIG: any = {
    method: 'POST',
    responseType: 'blob',
    headers: { 'Content-Type': 'application/json' }
};

export const downloadTemplateArchive = (
    archiveName: string,
    config: ITemplateArchiveBody
): void => {
    const request: AxiosRequestConfig = {
        ...DEFAULT_GENERATOR_REQ_CONFIG,
        url: `${import.meta.env.VITE_TEMPLATE_GEN_BASE}/template-archive`,
        data: JSON.stringify(config)
    };

    axios(request)
        .then((res) => {
            saveAs(res.data, archiveName);
        })
        .catch((e) => {
            console.error('Failed to download template archive:', e);
        });
};

export const downloadSDKArchive = (archiveName: string, config: ISDKArchiveBody): void => {
    const request: AxiosRequestConfig = {
        ...DEFAULT_GENERATOR_REQ_CONFIG,
        url: `${import.meta.env.VITE_TEMPLATE_GEN_BASE}/sdk-archive`,
        data: JSON.stringify(config)
    };

    axios(request)
        .then((res) => {
            saveAs(res.data, archiveName);
        })
        .catch((e) => {
            console.error('Failed to download sdk archive:', e);
        });
};
