import MediaController from '../../../services/MediaController';
import MediaLayerSource from './MediaLayerSource';
import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';

export default class AudioLayerSource extends MediaLayerSource {
    public componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    public async loadLayerSource(): Promise<void | any> {
        const timer = Date.now();

        const { source, muted, playing, relativeTime } = this.props;

        const url = source.url;

        this.logInfo(`Load Layer Source`);

        let audioNode;

        try {
            audioNode = await MediaController.preloadOffscreenAudio(url);
        } catch (e) {
            return Promise.reject();
        }

        const sourceTimer = Date.now() - timer;
        this.logInfo(`Layer source loaded in ${sourceTimer}ms`);

        return new Promise<void>((resolve) => {
            this.clear();

            this.mediaController = new MediaController(audioNode);

            this.mediaController.internalMedia.muted = muted;

            if (this.mediaController.internalMedia.paused && playing) {
                this.mediaController.seek(relativeTime);
                this.play();
            }

            this.volume();

            void this.draw(true);

            this.setState({
                status: LAYER_SOURCE_STATUSES.READY
            });

            resolve();
        });
    }

    public async seek() {
        super.seek();
        // Only manually seek if we're not "playing"
        if (this.mediaController && !this.props.playing) {
            try {
                await this.mediaController.seek(this.props.relativeTime);
            } catch (e) {
                this.logError(`Error seeking to proper time`, e);
            }
            if (this.mediaSprite) {
                this.mediaSprite.texture.update();
            }
        }
    }
}
