import { CheckboxField, SelectField, SliderField } from '@imposium-hub/components';
import { ITTSConfig } from '../constants/snippets';
import {
    TTS_MODEL_DEFAULTS,
    TTS_MODEL_OPTIONS,
    TTS_SERVICE_DEFAULTS,
    TTS_SERVICE_OPTIONS,
    TTS_SERVICES,
    TTS_VOICES
} from '../constants/story';
import { fields as copy } from '../constants/copy';
import { logNotification, logError } from '../util/notifications';
import { getCopyPropIdButton } from './TextLayerOptions';

interface ITTSOptionsProps {
    config: ITTSConfig;
    onChange(c: ITTSConfig): void;
    showCopyPropIds: boolean;
    compositionId: string;
    layerId: string;
}

export const TextToSpeechOptions = ({
    config,
    onChange,
    showCopyPropIds,
    compositionId,
    layerId
}: ITTSOptionsProps) => {
    const onServiceChange = (service) => {
        const newConfig = { ...config };
        newConfig['service'] = service;
        newConfig['voice'] = TTS_SERVICE_DEFAULTS[service];
        newConfig['model'] = TTS_MODEL_DEFAULTS[service];
        newConfig['speed'] = 1;
        newConfig['pause_silences'] = false;
        onChange(newConfig);
    };

    const onVoiceChange = (voice) => {
        const newConfig = { ...config };
        newConfig['voice'] = voice;
        onChange(newConfig);
    };

    const onModelChange = (model) => {
        const newConfig = { ...config };
        newConfig['model'] = model;
        onChange(newConfig);
    };

    const onSpeedChange = (speed) => {
        const newConfig = { ...config };
        newConfig['speed'] = speed;
        onChange(newConfig);
    };

    const onSilenceChange = (silence) => {
        const newConfig = { ...config };
        newConfig['pause_silences'] = silence;

        if (silence) {
            newConfig['minimum_silence_length'] = 0.1;
        } else {
            delete newConfig['minimum_silence_length'];
        }

        onChange(newConfig);
    };

    const onSilenceLengthChange = (length) => {
        const newConfig = { ...config };
        newConfig['minimum_silence_length'] = length;
        onChange(newConfig);
    };

    return (
        <>
            <SelectField
                options={TTS_SERVICE_OPTIONS}
                value={config.service}
                width={'33%'}
                onChange={(v) => onServiceChange(v)}
                label={copy.overlayConfig.ttsService}
            />
            <SelectField
                options={TTS_VOICES[config.service] || []}
                value={config.voice}
                width={'33%'}
                onChange={(v) => onVoiceChange(v)}
                label={[
                    copy.overlayConfig.ttsVoice,
                    getCopyPropIdButton(
                        showCopyPropIds,
                        compositionId,
                        layerId,
                        'options.source.text_to_speech.voice',
                        logNotification,
                        logError
                    )
                ]}
            />
            {config.model && (
                <SelectField
                    options={TTS_MODEL_OPTIONS}
                    value={
                        config?.model ? config.model : TTS_MODEL_DEFAULTS[TTS_SERVICES.ELEVENLABS]
                    }
                    width={'33%'}
                    onChange={(v) => onModelChange(v)}
                    label={copy.overlayConfig.ttsModel}
                />
            )}
            <SliderField
                label={copy.overlayConfig.ttsSpeed}
                value={config?.speed ? config.speed : 1}
                defaultValue={1}
                width='33%'
                min={0.1}
                max={3}
                step={0.1}
                onChange={(v) => onSpeedChange(v)}
            />
            <CheckboxField
                label={copy.overlayConfig.ttsSilence}
                value={config?.pause_silences ? config.pause_silences : false}
                width='33%'
                onChange={(v) => onSilenceChange(v)}
            />
            {config.pause_silences && (
                <SliderField
                    label={copy.overlayConfig.ttsSilenceLength}
                    value={config?.minimum_silence_length ? config.minimum_silence_length : 0.1}
                    defaultValue={1}
                    width='33%'
                    min={0.1}
                    max={5}
                    step={0.1}
                    onChange={(v) => onSilenceLengthChange(v)}
                />
            )}
        </>
    );
};
