import * as React from 'react';
import Moveable from 'react-moveable';
import { ICON_PLUS } from '../../../constants/icons';
import {
    ASSET_TYPES,
    LAYOUT_UNITS,
    ORIGINS,
    OVERLAY_POSITION_TYPES
} from '../../../constants/story';
import {
    KEYFRAME_CONTROLLER_TYPES,
    LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE
} from '../../../constants/timeline';
import { trimToFourDecimalPlaces } from '../../../util/general';
import {
    addKeyframe,
    changeKeyframeValue,
    getLayerPosition,
    getPosValues
} from '../../../util/timeline';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import hotkeys from 'hotkeys-js';
import { setComposition } from '../../../redux/actions/compositions';
import { addViewer, replaceViewer } from '../../../redux/actions/story';
import { IComposition } from '../../../constants/snippets';
import { MULTI_SELECT_EVENT_NAME } from '../../../constants/editor';
import { getSourceKey } from './LayeredCompositionPreview';
import { updateTimelineState } from '../../../redux/actions/timeline';
import { selectKeyframeAtPlayhead } from '../../../util/ui';

interface ILayerInteractionHandlerProps {
    compositionId: string;
    compWidth: number;
    compHeight: number;
    layer: any;
    disabled: boolean;
    activeFrame: number;
    onUpdate(l: any): void;
    onUpdateOptions(o: any): void;
    onUpdateInterim(position: any): void;
    updateTimelineState(a: any): void;
    interimPosition: any;
    layerSourceData: any;
    addViewer: (viewerConfig: any) => any;
    replaceViewer: (viewerConfig: any) => any;
    setComposition: (id: string, comp: IComposition) => void;
    compositions: any;
    onClick(id: string, multiSelect: boolean): void;
}

interface ILayerInteractionHandlerState {
    ready: boolean;
    scaled: boolean;
    resized: boolean;
    moved: boolean;
    lockAspect: boolean;
    interimAnchorPosition: any;
    anchorDragging: boolean;
    dimensionsMode: boolean;
}

class LayerInteractionHandler extends React.PureComponent<
    ILayerInteractionHandlerProps,
    ILayerInteractionHandlerState
> {
    private evtHandlers;

    private moveable;

    private anchorMoveable;

    private layerRefMount = (e) => this.onRefMount(e);

    private target: any;

    private anchorTarget: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            onKeyDown: (e) => this.onKeyDown(e),
            onKeyUp: (e) => this.onKeyUp(e),
            onDrag: (e) => this.handleDrag(e),
            onAnchorDragStart: (e) => this.handleAnchorDragStart(e),
            onAnchorDrag: (e) => this.handleAnchorDrag(e),
            onAnchorDragEnd: (e) => this.handleAnchorDragEnd(e),
            onDragEnd: (e) => this.handleInteractionEnd(e),
            handleExternalTransform: (e) => this.handleInteractionEnd(e, true),
            onResize: (e) => this.handleResize(e),
            onResizeEnd: (e) => this.handleInteractionEnd(e),
            onUp: (e) => this.onDirectionKey(e, 'ArrowUp'),
            onDown: (e) => this.onDirectionKey(e, 'ArrowDown'),
            onLeft: (e) => this.onDirectionKey(e, 'ArrowLeft'),
            onRight: (e) => this.onDirectionKey(e, 'ArrowRight'),
            onDblClick: (e) => this.onDblClick(e),
            onClick: (e) => this.onClick(e)
        };

        this.moveable = React.createRef();
        this.anchorMoveable = React.createRef();

        this.state = {
            scaled: false,
            resized: false,
            moved: false,
            ready: false,
            lockAspect: true,
            anchorDragging: false,
            dimensionsMode: false,
            interimAnchorPosition: null
        };
    }

    public componentDidUpdate(prevProps, prevState) {
        const moveable = this.moveable.current;
        const anchor = this.anchorMoveable.current;
        if (moveable) {
            moveable.updateRect();
        }
        if (anchor) {
            anchor.updateRect();
        }
    }

    public componentDidMount(): void {
        const { layer } = this.props;
        this.target = document.getElementById(`interaction-handler-${layer.id}`);
        this.anchorTarget = document.getElementById(`interaction-handler-${layer.id}-anchor`);
        document.addEventListener('keydown', this.evtHandlers.onKeyDown);
        document.addEventListener('keyup', this.evtHandlers.onKeyUp);
        document.addEventListener('dblclick', this.evtHandlers.onDblClick);
        document.addEventListener(
            MULTI_SELECT_EVENT_NAME,
            this.evtHandlers.handleExternalTransform
        );
        hotkeys('up', this.evtHandlers.onUp);
        hotkeys('shift+up', this.evtHandlers.onUp);
        hotkeys('down', this.evtHandlers.onDown);
        hotkeys('shift+down', this.evtHandlers.onDown);
        hotkeys('left', this.evtHandlers.onLeft);
        hotkeys('shift+left', this.evtHandlers.onLeft);
        hotkeys('right', this.evtHandlers.onRight);
        hotkeys('shift+right', this.evtHandlers.onRight);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keyup', this.evtHandlers.onKeyUp);
        document.removeEventListener('keydown', this.evtHandlers.onKeyDown);
        document.removeEventListener('dblclick', this.evtHandlers.onDblClick);
        document.removeEventListener(
            MULTI_SELECT_EVENT_NAME,
            this.evtHandlers.handleExternalTransform
        );
        hotkeys.unbind('up', this.evtHandlers.onUp);
        hotkeys.unbind('shift+up', this.evtHandlers.onUp);
        hotkeys.unbind('down', this.evtHandlers.onDown);
        hotkeys.unbind('shift+down', this.evtHandlers.onDown);
        hotkeys.unbind('left', this.evtHandlers.onLeft);
        hotkeys.unbind('shift+left', this.evtHandlers.onLeft);
        hotkeys.unbind('right', this.evtHandlers.onRight);
        hotkeys.unbind('shift+right', this.evtHandlers.onRight);
    }

    private onClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { scaled, resized, moved, anchorDragging } = this.state;
        if (scaled || resized || moved || anchorDragging) {
            return;
        }
        this.props.onClick(this.props.layer.id, e.shiftKey);
    }

    private onDblClick(e) {
        const { layerSourceData, layer, compositions, compositionId } = this.props;
        const asset = layerSourceData[getSourceKey(compositionId, layer.id)];

        if (!asset?.type || e.target.id !== 'interaction-handler') return;

        const { id, name: label, type } = asset;

        const tabLabel =
            type === ASSET_TYPES.VIDEO_COMPOSITION
                ? `Composition: ${label || id}`
                : `Asset: ${label || id}`;

        if (type === ASSET_TYPES.VIDEO_COMPOSITION) {
            const compData = compositions[id] ? compositions[id] : asset.data;
            this.props.setComposition(id, compData);
            this.props.replaceViewer({ id, label: tabLabel, type });
        } else {
            this.props.addViewer({ id, label: tabLabel, asset, type });
        }
    }

    private onKeyUp(e) {
        if (!e.shiftKey && !this.state.lockAspect) {
            this.setState({
                lockAspect: true
            });
        }

        if (!e.altKey && this.state.dimensionsMode) {
            this.setState({
                dimensionsMode: false
            });
        }
    }

    private onKeyDown(e) {
        if (e.target.localName === 'textarea' || e.target.localName === 'input') {
            return null;
        }

        if (e.shiftKey && this.state.lockAspect) {
            this.setState({
                lockAspect: false
            });
        }

        if (e.altKey && !this.state.dimensionsMode && !this.state.scaled) {
            this.setState({
                dimensionsMode: true
            });
        }
    }

    private onDirectionKey(e, key) {
        const {
            layer,
            activeFrame,
            layer: {
                keyframes,
                position_inputs: { x, y }
            }
        } = this.props;

        const newLayer = { ...this.props.layer };

        const offset = e.shiftKey ? 10 : 1;

        if (keyframes && keyframes.position && keyframes.position.length > 0) {
            const oldPos = this.getInterpolatedLayerPosition();
            const newPos = this.getNewPositionHandler(key, offset, oldPos);

            const value = {
                x: newPos.x,
                y: newPos.y
            };

            let updatedKeyframes = { ...keyframes };
            updatedKeyframes = this.updateKeyframes(updatedKeyframes, 'position', value);
            newLayer.keyframes = updatedKeyframes;
            selectKeyframeAtPlayhead(layer, activeFrame, updatedKeyframes.position);
        } else {
            const oldPos = { x, y };
            const newPos = this.getNewPositionHandler(e.key, offset, oldPos);
            const newInputs = { ...newLayer.position_inputs };
            newInputs.x = newPos.x;
            newInputs.y = newPos.y;
            newLayer.position_inputs = newInputs;
        }

        this.props.onUpdate(newLayer);
    }

    private getNewPositionHandler(key, offset, oldPos) {
        const {
            layer: {
                position_inputs: { originX, originY }
            }
        } = this.props;
        let xOffset = offset;
        let yOffset = offset;
        const newPos = { ...oldPos };

        if (originX === ORIGINS.RIGHT) {
            xOffset = -offset;
        } else if (originY === ORIGINS.BOTTOM) {
            yOffset = -offset;
        }

        switch (key) {
            case 'ArrowUp':
                newPos.y = oldPos.y - yOffset;
                break;
            case 'ArrowDown':
                newPos.y = oldPos.y + yOffset;
                break;
            case 'ArrowLeft':
                newPos.x = oldPos.x - xOffset;
                break;
            case 'ArrowRight':
                newPos.x = oldPos.x + xOffset;
                break;
        }

        return newPos;
    }

    private getInterpolatedLayerPosition() {
        const { keyframes, start_frame, position_inputs: positionInputs } = this.props.layer;
        const { activeFrame } = this.props;
        const relativeFrame = activeFrame - start_frame;

        const pos = getPosValues(
            positionInputs,
            keyframes,
            'position',
            relativeFrame,
            ['x', 'y'],
            [0, 0]
        );

        return pos;
    }

    private getPositionFromLayerData(applyScale = true, applyAnchorOffset = true) {
        const { position_inputs, keyframes, start_frame } = this.props.layer;
        const { compWidth, compHeight, activeFrame } = this.props;
        const relativeFrame = activeFrame - start_frame;

        return getLayerPosition(
            position_inputs,
            keyframes,
            relativeFrame,
            compWidth,
            compHeight,
            applyScale,
            applyAnchorOffset
        );
    }

    private getPositionFromInterimPosition() {
        const { interimPosition } = this.props;
        const pos = { ...interimPosition };

        return pos;
    }

    private handleDrag(e) {
        if (this.state.anchorDragging) {
            return;
        }

        const pos = this.props.interimPosition
            ? this.getPositionFromInterimPosition()
            : this.getPositionFromLayerData();

        pos.x = Math.round(e.left);
        pos.y = Math.round(e.top);

        this.setState(
            {
                moved: true
            },
            () => {
                this.props.onUpdateInterim(pos);
            }
        );
    }

    private handleAnchorDrag(e) {
        const anchorX = trimToFourDecimalPlaces(e.left + LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE / 2);
        const anchorY = trimToFourDecimalPlaces(e.top + LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE / 2);
        const pos = {
            anchorX,
            anchorY
        };

        this.setState({
            interimAnchorPosition: pos
        });
    }

    private handleAnchorDragEnd(e) {
        const { interimAnchorPosition } = this.state;
        if (!interimAnchorPosition) {
            return;
        }

        this.setState(
            {
                interimAnchorPosition: null,
                anchorDragging: false
            },
            () => {
                const { compWidth, compHeight, layer, activeFrame } = this.props;
                const realPos = this.getPositionFromLayerData(false, false);
                const scaledPos = this.getPositionFromLayerData();
                let anchorX = trimToFourDecimalPlaces(
                    interimAnchorPosition.anchorX / scaledPos.scaleX
                );
                let anchorY = trimToFourDecimalPlaces(
                    interimAnchorPosition.anchorY / scaledPos.scaleY
                );

                const anchorXUnit = realPos.anchorXUnit || LAYOUT_UNITS.PIXELS;
                const anchorYUnit = realPos.anchorYUnit || LAYOUT_UNITS.PIXELS;
                const widthUnit = realPos.widthUnit || LAYOUT_UNITS.PIXELS;
                const heightUnit = realPos.heightUnit || LAYOUT_UNITS.PIXELS;

                const layerWidth =
                    widthUnit === LAYOUT_UNITS.PIXELS ? realPos.width : realPos.width / compWidth;
                const layerHeight =
                    heightUnit === LAYOUT_UNITS.PIXELS
                        ? realPos.height
                        : realPos.height / compHeight;

                if (anchorXUnit === LAYOUT_UNITS.PERCENT) {
                    anchorX = trimToFourDecimalPlaces(anchorX / layerWidth);
                }

                if (anchorYUnit === LAYOUT_UNITS.PERCENT) {
                    anchorY = trimToFourDecimalPlaces(anchorY / layerHeight);
                }

                const newLayer = { ...this.props.layer };
                const { keyframes } = this.props.layer;
                let updatedKeyframes = { ...newLayer.keyframes };
                if (keyframes && keyframes.anchor && keyframes.anchor.length > 0) {
                    const value = {
                        anchorX,
                        anchorY
                    };
                    updatedKeyframes = this.updateKeyframes(updatedKeyframes, 'anchor', value);
                    newLayer.keyframes = updatedKeyframes;
                    selectKeyframeAtPlayhead(layer, activeFrame, updatedKeyframes.anchor);
                } else {
                    const inputs = { ...newLayer.position_inputs };
                    inputs.anchorX = anchorX;
                    inputs.anchorY = anchorY;
                    newLayer.position_inputs = { ...inputs };
                }
                this.props.onUpdate(newLayer);
            }
        );
    }

    private handleAnchorDragStart(e) {
        this.setState({
            anchorDragging: true
        });
    }

    public handleInteractionEnd(e: any = null, triggeredExternally: boolean = false) {
        const { scaled, moved, resized, anchorDragging } = this.state;
        const {
            layer,
            activeFrame,
            layer: { keyframes, id },
            interimPosition,
            compWidth,
            compHeight
        } = this.props;

        if (triggeredExternally && e.detail.initiatorId === id) {
            return;
        }

        if (anchorDragging) {
            return;
        } else if (!scaled && !moved && !resized && !triggeredExternally) {
            this.props.onUpdateInterim(null);
            return;
        }

        // Dispatch the custom event for any other interaction handlers to tie into
        if (!triggeredExternally && (moved || scaled || resized)) {
            const evtData = { initiatorId: id, moved, scaled, resized };
            const event = new CustomEvent(MULTI_SELECT_EVENT_NAME, { detail: evtData });
            document.dispatchEvent(event);
        }

        const scaledPos = this.getPositionFromLayerData();

        let updatedKeyframes = { ...keyframes };
        let updateKeyframes = false;

        if (resized || (triggeredExternally && e.detail.resized)) {
            const interimPos = this.getPositionFromInterimPosition();
            const newLayer = { ...this.props.layer };
            const newPos = { ...newLayer.position_inputs };

            const widthUnit = interimPos.widthUnit || LAYOUT_UNITS.PIXELS;
            const heightUnit = interimPos.heightUnit || LAYOUT_UNITS.PIXELS;

            let newW = trimToFourDecimalPlaces(interimPos.width / interimPos.scaleX);
            let newH = trimToFourDecimalPlaces(interimPos.height / interimPos.scaleY);

            if (widthUnit === LAYOUT_UNITS.PERCENT) {
                newW = trimToFourDecimalPlaces(newW / compWidth);
            }

            if (heightUnit === LAYOUT_UNITS.PERCENT) {
                newH = trimToFourDecimalPlaces(newH / compHeight);
            }

            newPos.width = newW;
            newPos.height = newH;
            newLayer.position_inputs = { ...newPos };
            if (!triggeredExternally) {
                this.props.onUpdateInterim(null);
            }
            this.props.onUpdate(newLayer);
        }

        // If the layer was scaled
        if ((scaled && !resized) || (triggeredExternally && e.detail.scaled && !e.detail.resized)) {
            if (keyframes && keyframes.scale && keyframes.scale.length > 0) {
                updateKeyframes = true;
                const scaleX = trimToFourDecimalPlaces(interimPosition.scaleX);
                const scaleY =
                    this.state.lockAspect && scaledPos.scaleX === scaledPos.scaleY
                        ? scaleX
                        : trimToFourDecimalPlaces(interimPosition.scaleY);
                const value = {
                    scaleX,
                    scaleY
                };
                updatedKeyframes = this.updateKeyframes(updatedKeyframes, 'scale', value);
                selectKeyframeAtPlayhead(layer, activeFrame, updatedKeyframes.scale);
            } else {
                const interimPos = this.getPositionFromInterimPosition();
                const newLayer = { ...this.props.layer };
                const newPos = { ...newLayer.position_inputs };
                const scaleX = trimToFourDecimalPlaces(interimPos.scaleX);
                const scaleY =
                    this.state.lockAspect && scaledPos.scaleX === scaledPos.scaleY
                        ? scaleX
                        : trimToFourDecimalPlaces(interimPosition.scaleY);
                newPos.scaleX = scaleX;
                newPos.scaleY = scaleY;
                newLayer.position_inputs = { ...newPos };

                if (!triggeredExternally) {
                    this.props.onUpdateInterim(null);
                }
                this.props.onUpdate(newLayer);
            }
        }

        // If the layer was moved:
        if (moved || (triggeredExternally && e.detail.moved)) {
            const interimPos = this.getPositionFromInterimPosition();
            const realPos = this.getPositionFromLayerData(false, false);

            const originX = scaledPos.originX || ORIGINS.LEFT;
            const originY = scaledPos.originY || ORIGINS.TOP;
            const xUnit = scaledPos.xUnit || LAYOUT_UNITS.PIXELS;
            const yUnit = scaledPos.yUnit || LAYOUT_UNITS.PIXELS;

            const xOffset =
                originX === ORIGINS.LEFT ? interimPos.x - scaledPos.x : scaledPos.x - interimPos.x;
            const yOffset =
                originY === ORIGINS.TOP ? interimPos.y - scaledPos.y : scaledPos.y - interimPos.y;

            let newX = trimToFourDecimalPlaces(
                originX === ORIGINS.LEFT ? realPos.x + xOffset : compWidth - realPos.x + xOffset
            );

            let newY = trimToFourDecimalPlaces(
                originY === ORIGINS.TOP ? realPos.y + yOffset : compHeight - realPos.y + yOffset
            );

            // Transform the X back to percentage, if need be
            if (xUnit === LAYOUT_UNITS.PERCENT) {
                newX = trimToFourDecimalPlaces(newX / compWidth);
            } else {
                newX = trimToFourDecimalPlaces(newX);
            }

            // Transform the Y back to percentage, if need be
            if (yUnit === LAYOUT_UNITS.PERCENT) {
                newY = trimToFourDecimalPlaces(newY / compHeight);
            } else {
                newY = trimToFourDecimalPlaces(newY);
            }

            if (keyframes && keyframes.position && keyframes.position.length > 0) {
                // If we're already using keyframes for position:
                updateKeyframes = true;
                const value = {
                    x: newX,
                    y: newY
                };

                updatedKeyframes = this.updateKeyframes(updatedKeyframes, 'position', value);
                selectKeyframeAtPlayhead(layer, activeFrame, updatedKeyframes.position);
            } else {
                // If we're just updating the global x/y position
                const newLayer = { ...this.props.layer };
                const newPos = { ...newLayer.position_inputs };
                newPos.x = newX;
                newPos.y = newY;
                newLayer.position_inputs = { ...newPos };

                if (!triggeredExternally) {
                    this.props.onUpdateInterim(null);
                }
                this.props.onUpdate(newLayer);
            }
        }

        // If the keyframes have been updated:
        if (updateKeyframes) {
            const newLayer = { ...this.props.layer };
            newLayer.keyframes = updatedKeyframes;

            if (!triggeredExternally) {
                this.props.onUpdateInterim(null);
            }
            this.props.onUpdate(newLayer);
        }

        this.setState({
            scaled: false,
            moved: false,
            resized: false
        });
    }

    private updateKeyframes = (keyframes, field, value) => {
        const { start_frame } = this.props.layer;
        const { activeFrame } = this.props;
        const relativeFrame = activeFrame - start_frame;
        const activeKeyframeIndex = keyframes[field].findIndex((k) => {
            return k.relativeFrame === relativeFrame;
        });

        let updatedKeyframes;
        // Update the active keyframe, or make a new one
        if (activeKeyframeIndex !== -1) {
            updatedKeyframes = changeKeyframeValue(keyframes, field, activeKeyframeIndex, value);
        } else {
            const keyframe = {
                type: KEYFRAME_CONTROLLER_TYPES.COMPOUND,
                value
            };
            updatedKeyframes = addKeyframe(keyframes, relativeFrame, field, keyframe);
        }
        return updatedKeyframes;
    };

    private handleResize(e) {
        this.target.style.width = e.style.width;
        this.target.style.height = e.style.height;

        if (this.state.anchorDragging) {
            return;
        }

        if (this.state.dimensionsMode) {
            const interimPos = this.props.interimPosition
                ? { ...this.props.interimPosition }
                : this.getPositionFromLayerData();
            const newPos = { ...interimPos };
            newPos.width = trimToFourDecimalPlaces(e.width);
            newPos.height = trimToFourDecimalPlaces(e.height);

            this.setState(
                {
                    resized: true
                },
                () => {
                    this.props.onUpdateInterim(newPos);
                }
            );
        } else {
            const interimPos = this.props.interimPosition
                ? { ...this.props.interimPosition }
                : this.getPositionFromLayerData();
            const actualPos = { ...this.getPositionFromLayerData(false, false) };
            const newPos = { ...interimPos };

            const newScaleX = e.width / actualPos.width;
            const newScaleY = e.height / actualPos.height;

            // Calculate the new interim position using the actual raw, un-offset position, and the new scale value
            newPos.width = trimToFourDecimalPlaces(actualPos.width * newScaleX);
            newPos.height = trimToFourDecimalPlaces(actualPos.height * newScaleY);
            newPos.anchorX = trimToFourDecimalPlaces(actualPos.anchorX * newScaleX);
            newPos.anchorY = trimToFourDecimalPlaces(actualPos.anchorY * newScaleY);
            newPos.scaleX = trimToFourDecimalPlaces(newScaleX);
            newPos.scaleY = trimToFourDecimalPlaces(newScaleY);
            newPos.x = trimToFourDecimalPlaces(actualPos.x - actualPos.anchorX * newScaleX);
            newPos.y = trimToFourDecimalPlaces(actualPos.y - actualPos.anchorY * newScaleY);

            this.setState(
                {
                    scaled: true
                },
                () => {
                    this.props.onUpdateInterim(newPos);
                }
            );
        }
    }

    // Call forceUpdate() so that Moveable can find it's div on mount...
    // TODO: find a better way to do this. This is trash.
    private onRefMount(e) {
        this.forceUpdate();
    }

    public render() {
        const { layer, interimPosition, disabled } = this.props;
        const { lockAspect, interimAnchorPosition, dimensionsMode } = this.state;

        if (!layer.video_enabled) {
            return null;
        }

        if (layer.position === OVERLAY_POSITION_TYPES.RECT) {
            const layerClass = `layer-${layer.id}`;
            const pos = interimPosition
                ? this.getPositionFromInterimPosition()
                : this.getPositionFromLayerData();

            const { anchorX, anchorY } = interimAnchorPosition ? interimAnchorPosition : pos;
            if (pos) {
                const layerStyle: any = {
                    top: `${pos.y}px`,
                    left: `${pos.x}px`,
                    width: `${pos.width}px`,
                    height: `${pos.height}px`
                };

                const moveable = (
                    <Moveable
                        ref={this.moveable}
                        groupable={false}
                        throttleDragRotate={!lockAspect ? 90 : null}
                        target={this.target}
                        onDrag={this.evtHandlers.onDrag}
                        onDragEnd={this.evtHandlers.onDragEnd}
                        onResize={this.evtHandlers.onResize}
                        onResizeEnd={this.evtHandlers.onResizeEnd}
                        keepRatio={dimensionsMode ? false : lockAspect}
                        transformOrigin={`${anchorX}px ${anchorY}px`}
                        resizable={!disabled}
                        origin={false}
                        className={`${dimensionsMode ? 'change-layer-dimensions' : ''}`}
                        draggable={!disabled}
                    />
                );

                const moveableAnchor = (
                    <Moveable
                        ref={this.anchorMoveable}
                        className={`hide-border`}
                        target={this.anchorTarget}
                        onDrag={this.evtHandlers.onAnchorDrag}
                        onDragStart={this.evtHandlers.onAnchorDragStart}
                        onDragEnd={this.evtHandlers.onAnchorDragEnd}
                        origin={false}
                        draggable={true}
                    />
                );

                const aX = (anchorX || 0) - LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE / 2;
                const aY = (anchorY || 0) - LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE / 2;
                const anchorStyle = {
                    top: `${aY}px`,
                    left: `${aX}px`
                };

                return (
                    <>
                        {moveable}
                        {moveableAnchor}
                        <div
                            onClick={this.evtHandlers.onClick}
                            id={`interaction-handler-${layer.id}`}
                            ref={this.layerRefMount}
                            className={`${layerClass} apply-font interaction-handler`}
                            style={layerStyle}>
                            <div
                                id={`interaction-handler-${layer.id}-anchor`}
                                className={'interaction-handler-anchor'}
                                style={anchorStyle}>
                                {ICON_PLUS}
                            </div>
                        </div>
                    </>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            addViewer,
            updateTimelineState,
            replaceViewer,
            setComposition
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        compositions: state.compositions.present,
        layerSourceData: state.layerSources
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerInteractionHandler);
