import * as React from 'react';
import {
    TextField,
    Button,
    HRule,
    Tabs,
    Card,
    Spinner,
    resetFilters,
    clearStoryPublishStatus
} from '@imposium-hub/components';
import { NEW_PROJECT_PRESET_TEMPLATE_OPTIONS, SCENE_TYPES } from '../constants/story';
import { connect } from 'react-redux';
import { copyStory, createStory } from '../redux/actions/story';
import { updateEditorConfig } from '../redux/actions/editor';
import { disableForEncodingSettings, newStory } from '../util/story';
import { fields as copy } from '../constants/copy';
import EncodingSettingList from './EncodingSettingList';
import VideoStoryImageOutputSettings from './VideoStoryImageOutputSettings';
import { ICON_DOWNLOAD, ICON_SAVE } from '../constants/icons';
import PaneErrorBoundry from './errors/PaneErrorBoundry';
import { pushRoute } from '../util/routing';
import { saveAs } from 'file-saver';
import VideoStoryAudioOutputSettings from './VideoStoryAudioOutputSettings';
import { doPollJobCompletionStatus } from '../redux/actions/batch-jobs';
import { resetDatasets } from '../redux/actions/datasets';
import { LOWER_PANELS } from '../constants/editor';
import { api } from '../constants/app';

interface INewProjectSettingsProps {
    createStory(i): any;
    updateEditorConfig(c): void;
    resetDatasets(): void;
    copyStory(i, n?): any;
    resetFilters(): any;
    doPollJobCompletionStatus(i): any;
    clearStoryPublishStatus(apiInstance: any): void;
}

interface INewProjectSettingsState {
    storyCache: any;
    project: any;
    activePreset: string;
    blankTemplate: boolean;
    selectTemplate: boolean;
    preBuiltTemplate: boolean;
    setNameField: boolean;
}

class NewProjectSettings extends React.PureComponent<
    INewProjectSettingsProps,
    INewProjectSettingsState
> {
    private nameFieldRef: any;

    constructor(props) {
        super(props);

        const story = newStory(SCENE_TYPES.COMPOSITION);
        const actId = Object.keys(story[`acts`])[0];
        const act = story[`acts`][actId];

        this.state = {
            storyCache: story,
            project: {
                storyId: story.id,
                actId,
                sceneId: Object.keys(act.scenes)[0],
                compositionId: ''
            },
            activePreset: NEW_PROJECT_PRESET_TEMPLATE_OPTIONS[0].value,
            blankTemplate: false,
            selectTemplate: true,
            preBuiltTemplate: false,
            setNameField: false
        };

        this.nameFieldRef = React.createRef();
    }

    public componentDidUpdate(): void {
        const nameField = this.nameFieldRef.current;
        if (nameField && !this.state.setNameField) {
            nameField.setEditing(true);
            this.setState({ setNameField: true });
        }

        if (this.state.setNameField) {
            nameField.blur();
        }
    }

    private storyInputChanged(key, value) {
        const fields = {
            [key]: value
        };
        const updateFields = { ...this.state.storyCache, ...fields };

        this.setState({
            storyCache: updateFields
        });
    }

    private sceneInputChanged(key, value) {
        const {
            project: { actId, sceneId }
        } = this.state;

        const fields = {
            [key]: value
        };
        const scenes = this.getScene();
        const updateScene = { ...scenes, ...fields };
        const updateFields = Object.assign({}, this.state.storyCache);

        updateFields['acts'][actId].scenes[sceneId] = updateScene;

        this.setState({
            storyCache: updateFields
        });
    }

    private sceneDataInputChanged(key, value) {
        const {
            project: { actId, sceneId }
        } = this.state;

        const fields = {
            [key]: value
        };
        const scenes = this.getScene();
        const updateSceneData = {
            ...scenes.sceneData,
            ...fields
        };

        const updateFields = Object.assign({}, this.state.storyCache);

        updateFields['acts'][actId].scenes[sceneId].sceneData = updateSceneData;

        const {
            sceneData: { encodingSettings, imageOutputSettings, audioOutputSettings }
        } = updateFields['acts'][actId].scenes[sceneId];

        const imgOrAudio = imageOutputSettings.length > 0 || audioOutputSettings.length > 0;

        if (encodingSettings.length > 0 && imgOrAudio) {
            this.sceneInputChanged('skipVideoOutput', false);
        } else if (encodingSettings.length === 0 && imgOrAudio) {
            this.sceneInputChanged('skipVideoOutput', true);
        }

        this.setState({
            storyCache: updateFields
        });
    }

    private getScene() {
        const {
            storyCache: story,
            project: { actId, sceneId }
        } = this.state;
        const scenes = story['acts'][actId].scenes[sceneId];
        return scenes;
    }

    private changeActiveTab(i) {
        this.props.updateEditorConfig({
            activeSettingsTab: i
        });
    }

    private createStory() {
        const { storyCache } = this.state;
        this.props.createStory(storyCache);
        pushRoute(`/${storyCache.id}`);
        this.reset();
    }

    private copyStoryHandler(storyId) {
        this.setState(
            {
                preBuiltTemplate: true,
                selectTemplate: false
            },
            () =>
                this.props.copyStory(storyId).then((data) => {
                    pushRoute(`/${data.story_id}`);
                    this.reset();
                })
        );
    }

    private reset() {
        this.props.updateEditorConfig({
            newStoryModalOpen: false,
            activeDatasetId: null,
            activeLowerPanel: LOWER_PANELS.TIMELINE
        });
        this.props.resetDatasets();
        this.props.resetFilters();
        this.props.clearStoryPublishStatus(api);
    }

    private blankTemplate(scenes) {
        const { storyCache: story, project, blankTemplate, preBuiltTemplate } = this.state;
        const { encodingSettings, imageOutputSettings, audioOutputSettings } = scenes.sceneData;

        const title = story['name'] ? (
            story['name']
        ) : (
            <span className='text-primary'>{copy.settings.blankTemplate}</span>
        );

        if (blankTemplate) {
            return (
                <Card
                    title={title}
                    open={true}>
                    <TextField
                        label={copy.global.name}
                        value={story['name']}
                        tooltip={copy.project.tooltipName}
                        ref={this.nameFieldRef}
                        onChange={(v) => this.storyInputChanged('name', v)}
                    />
                    <HRule />
                    <h2>{copy.confirm.videoTitle}</h2>
                    <HRule />
                    <EncodingSettingList
                        config={encodingSettings}
                        onChange={(v) => this.sceneDataInputChanged('encodingSettings', v)}
                    />
                    <HRule />
                    <br />
                    <h2>{copy.confirm.imageTitle}</h2>
                    <HRule />
                    <VideoStoryImageOutputSettings
                        story={story}
                        project={project}
                        config={imageOutputSettings}
                        onChange={(v) => this.sceneDataInputChanged('imageOutputSettings', v)}
                    />
                    <HRule />
                    <br />
                    <h2>{copy.confirm.audioTitle}</h2>
                    <HRule />
                    <VideoStoryAudioOutputSettings
                        story={story}
                        project={project}
                        config={audioOutputSettings}
                        onChange={(v) => this.sceneDataInputChanged('audioOutputSettings', v)}
                    />
                    <HRule />
                </Card>
            );
        }

        if (preBuiltTemplate) {
            return (
                <div className='template-selector'>
                    <div className='pre-built-template'>
                        <h2>{copy.settings.loadingTemplate}</h2>
                        <br />
                        <Spinner />
                    </div>
                </div>
            );
        }
    }

    private blankTemplateHandler() {
        if (!this.state.preBuiltTemplate) {
            this.setState({
                blankTemplate: true,
                selectTemplate: false
            });
        }
    }

    public render() {
        const { selectTemplate, storyCache } = this.state;
        const scenes = this.getScene();
        const hidden = !selectTemplate ? 'hidden' : null;

        const newProject = (
            <div
                key='new-project'
                className='settings-tab new-project'>
                <div className={`template-selector ${hidden}`}>
                    <div className='select-template'>
                        <div
                            className='template'
                            onClick={() => this.blankTemplateHandler()}>
                            <div className='desc'>
                                <h1>{copy.settings.blankTemplate}</h1>
                                <p className='template_desc'>{copy.settings.blankTempDesc}</p>
                            </div>
                        </div>
                        <div
                            className='template'
                            onClick={() =>
                                this.copyStoryHandler('e6a15192-6a01-40ef-e68a-4d2688cb009d')
                            }>
                            <div className='desc'>
                                <h1>{copy.settings.travelerTemplate}</h1>
                                <p className='template_desc'>{copy.settings.travelerDesc}</p>
                                <video
                                    playsInline={true}
                                    controls
                                    autoPlay
                                    loop
                                    className='sample_video'>
                                    <source
                                        src='https://imposium-cdn.s3.amazonaws.com/traveler_rewards/travelerRewardsSample.mp4'
                                        type='video/mp4'
                                    />
                                </video>
                            </div>
                            <div className='template_data'>
                                <Button
                                    color='primary'
                                    size='small'
                                    style='bold'
                                    onClick={() =>
                                        saveAs(
                                            'https://imposium-cdn.s3.amazonaws.com/traveler_rewards/travelerRewardsSample.csv',
                                            'travelerRewardsSample.csv'
                                        )
                                    }>
                                    {copy.settings.sampleCSV}&nbsp;{ICON_DOWNLOAD}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.blankTemplate(scenes)}
            </div>
        );

        const tabs = [
            {
                label: copy.settings.selectTemplate,
                key: 'project-settings',
                content: newProject
            }
        ];

        const disableSave =
            disableForEncodingSettings(scenes) || (!storyCache.name && !selectTemplate);

        const btnSave = (
            <Button
                disabled={disableSave}
                customStyles={{
                    position: 'absolute',
                    bottom: '3px',
                    left: '50%',
                    marginLeft: '-47px'
                }}
                onClick={() => this.createStory()}
                color='primary'>
                {ICON_SAVE}&nbsp;{copy.story.uploadPrompt}
            </Button>
        );

        return (
            <div className='project-settings'>
                <PaneErrorBoundry>
                    <Tabs
                        onChange={(i) => this.changeActiveTab(i)}
                        options={tabs}
                    />
                    {!this.state.preBuiltTemplate ? btnSave : null}
                </PaneErrorBoundry>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createStory: (d) => dispatch(createStory(d)),
        resetDatasets: () => dispatch(resetDatasets()),
        updateEditorConfig: (c) => dispatch(updateEditorConfig(c)),
        copyStory: (i, n) => dispatch(copyStory(i, n)),
        resetFilters: () => dispatch(resetFilters()),
        doPollJobCompletionStatus: (i) => dispatch(doPollJobCompletionStatus(i)),
        clearStoryPublishStatus: (a) => dispatch(clearStoryPublishStatus(a))
    };
};

export default connect(null, mapDispatchToProps)(NewProjectSettings);
