import { FRAME_WIDTH, KEYFRAME_SIZE } from '../../constants/timeline';
import Draggable from 'react-draggable';
import { useState, useRef, useEffect } from 'react';
import { ICON_ELLIPSIS_H } from '../../constants/icons';
import { Button } from '@imposium-hub/components';
import { KeyframeContextMenu } from './KeyframeContextMenu';

export const Keyframe = ({
    offsetX,
    setOffsetX,
    config,
    scale,
    selected,
    onSelect,
    onChange,
    onMove,
    onDelete,
    onJump,
    disableEase
}) => {
    const [dragging, setDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(false);

    const scaledFrameWidth = scale * FRAME_WIDTH;

    const getKeyframeX = () => {
        return scaledFrameWidth * config.relativeFrame - KEYFRAME_SIZE / 2;
    };

    const containerRef = useRef(null);

    useEffect(() => {
        const onClick = (e) => {
            const insideContainer = containerRef.current.contains(e.target);
            if (contextMenu && !insideContainer) {
                setContextMenu(false);
            }
        };

        if (contextMenu) {
            document.addEventListener('click', onClick);
        } else {
            document.removeEventListener('click', onClick);
        }

        return () => {
            document.removeEventListener('click', onClick);
        };
    }, [contextMenu]);

    const style = {
        left: `${getKeyframeX()}px`
    };

    const sClass = selected ? 'selected' : '';
    const menuTrigger = (
        <div className='btn-keyframe-context-menu'>
            <Button
                onClick={(e) => setContextMenu(true)}
                style='subtle'>
                {ICON_ELLIPSIS_H}
            </Button>
        </div>
    );

    const keyframeContent = (
        <div
            ref={containerRef}
            className={`keyframe ${sClass}`}
            style={style}>
            <div className='keyframe-icon'></div>
            {selected && menuTrigger}
            {!dragging && contextMenu && selected && (
                <KeyframeContextMenu
                    disableEase={disableEase}
                    config={config}
                    onDelete={onDelete}
                    onJump={onJump}
                    onChange={onChange}
                />
            )}
        </div>
    );

    const onDragStart = (e) => {
        const container = containerRef.current;
        const insideContainer = container.contains(e.target);
        if (insideContainer) {
            onSelect(config, e.shiftKey, false);
        }
    };

    const onDrag = (e, ui) => {
        setDragging(true);
        setOffsetX(ui.x);
    };

    const onDragStop = (e, ui) => {
        const frames = Math.round(offsetX / scaledFrameWidth);

        setOffsetX(0);
        setDragging(false);

        if (frames !== 0) {
            onMove(frames);
        }
    };

    // Use offsetX from prop since multiple may be selected at once
    const xPos = selected ? offsetX : 0;

    return (
        <Draggable
            axis='x'
            position={{ x: xPos, y: 0 }}
            grid={[scaledFrameWidth, scaledFrameWidth]}
            onStop={onDragStop}
            onStart={onDragStart}
            onDrag={onDrag}>
            {keyframeContent}
        </Draggable>
    );
};
