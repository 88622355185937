import * as React from 'react';
import { Card, ButtonGroupField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import MediaSource from './MediaSource';
import { IVariables } from '../constants/snippets';
import {
    CLIP_DURATION_OPTIONS,
    DYNAMIC_DURATION_LAYERS,
    SOURCE_TYPES,
    SOURCE_TYPE_OPTIONS
} from '../constants/story';

interface IMediaLayerOptionsProps {
    variables: IVariables;
    storyId: string;
    options: any;
    type: any;
    duration: number;
    onChange(options: any): void;
    layerId: string;
}

class MediaLayerOptions extends React.PureComponent<IMediaLayerOptionsProps> {
    private evtHandlers = {
        source: (s) => this.updateOptionsFields({ source: s }),
        use_swap_duration: (v) => this.updateOptionsFields({ use_swap_duration: v })
    };

    constructor(props) {
        super(props);
    }

    private updateOptionsFields(options) {
        const merged = { ...this.props.options, ...options };
        this.props.onChange(merged);
    }

    private renderSwapFields() {
        const {
            options: { source, use_swap_duration },
            type
        } = this.props;
        if (
            (source.from === SOURCE_TYPES.ASSET_TAGS || source.from === SOURCE_TYPES.INVENTORY) &&
            DYNAMIC_DURATION_LAYERS.indexOf(type) !== -1
        ) {
            return (
                <ButtonGroupField
                    width='50%'
                    label={copy.compositions.swapDuration}
                    info={copy.compositions.swapDurationInfo}
                    options={CLIP_DURATION_OPTIONS}
                    onChange={this.evtHandlers.use_swap_duration}
                    value={use_swap_duration || false}
                />
            );
        } else {
            return null;
        }
    }

    public render() {
        const {
            type,
            options: { source, use_swap_duration },
            variables,
            storyId,
            duration,
            layerId
        } = this.props;

        return (
            <Card
                title={copy.layerConfig.options}
                open={true}
                collapsable={true}
                style={'options'}>
                <MediaSource
                    storyId={storyId}
                    variables={variables}
                    swapDuration={use_swap_duration}
                    source={source}
                    layerType={type}
                    typeOptions={SOURCE_TYPE_OPTIONS[type]}
                    onChange={this.evtHandlers.source}
                    duration={duration}
                    layerId={layerId}
                />
                {this.renderSwapFields()}
            </Card>
        );
    }
}

export default MediaLayerOptions;
