import MediaController from '../../../services/MediaController';
import * as PIXI from 'pixi.js-legacy';
import MediaLayerSource from './MediaLayerSource';
import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import LayeredCompositionPreview from './LayeredCompositionPreview';
import { ASSET_TYPES } from '../../../constants/story';

export default class VideoLayerSource extends MediaLayerSource {
    private compPreview: any;

    public componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    public async loadLayerSource(): Promise<void | any> {
        const timer = Date.now();

        const { source, muted, volume } = this.props;

        // If there is no edited_url on the source (asset src) use URL (variable src) instead
        const url = source.edited_url || source.url;

        this.logInfo(`Load Layer Source`);

        let videoNode;

        try {
            videoNode = await MediaController.preloadOffscreenVideo(url);
        } catch (e) {
            return Promise.reject();
        }

        const sourceTimer = Date.now() - timer;
        this.logInfo(`Layer source loaded in ${sourceTimer}ms`);

        return new Promise<void>((resolve) => {
            this.clear();

            this.mediaController = new MediaController(videoNode);
            this.mediaController.internalMedia.muted = muted;
            if (isFinite(volume)) {
                this.mediaController.internalMedia.volume = volume;
            }
            // create the resource, base texture, and texture manually.
            // If you do PIXI.sprite.from(video) it causes errors around video autoPlay
            // So we build it manually
            const resource = new PIXI.VideoResource(videoNode, {
                autoLoad: true,
                autoPlay: false,
                crossorigin: true
            });

            const { videoWidth, videoHeight } = videoNode;

            const base = new PIXI.BaseTexture(resource, {
                width: videoWidth,
                height: videoHeight
            });

            const texture = new PIXI.Texture(base);

            this.createMediaSprite(texture);

            this.setState({
                sourceWidth: videoWidth,
                sourceHeight: videoHeight,
                status: LAYER_SOURCE_STATUSES.READY
            });

            resolve();
        });
    }

    public async seek() {
        super.seek();
        // Only manually seek if we're not "playing"
        if (this.mediaController && !this.props.playing) {
            try {
                await this.mediaController.seek(this.props.relativeTime);
            } catch (e) {
                this.logError(`Error seeking to proper time`, e);
            }
            if (this.mediaSprite) {
                this.mediaSprite.texture.update();
            }
        }
    }

    public render() {
        const { variables, relativeFrame, source, sourceKey, layerData, pixiApp } = this.props;
        if (source?.type === ASSET_TYPES.VIDEO_COMPOSITION && sourceKey) {
            const patt = new RegExp(`(?:${source.id})`, 'gm');
            if (sourceKey.match(patt)?.length >= 1) {
                return null;
            }
        }
        const { status } = this.state;

        if (status === LAYER_SOURCE_STATUSES.READY && source && source?.data && this.mediaSprite) {
            return (
                <LayeredCompositionPreview
                    muted={!layerData?.audio_enabled}
                    inView={this.props.inView}
                    ref={this.compPreview}
                    isNested={true}
                    key={source.id}
                    parentSourceKey={sourceKey}
                    parentContainer={this.mediaSprite}
                    parentPixiApp={pixiApp}
                    variableMap={layerData?.options?.variable_map}
                    activeFrameOverride={relativeFrame}
                    compositionData={source.data}
                    compositionId={source.id}
                    variables={variables}
                />
            );
        }
        return null;
    }
}
