import * as React from 'react';
import { fields as copy } from '../../constants/copy';
import {
    Button,
    TextField,
    AssetsTableDropzone,
    DroppableAssetRenderer,
    updateFilters,
    uploadAssets,
    mimetypeConformsToOverlay
} from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ICON_FILTER, ICON_TIMES, ICON_WARNING } from '../../constants/icons';
import { circularCompCheck } from '../../util/timeline';

interface IAssetList {
    loading: boolean;
    page: number;
    total_pages: number;
    asset_count: number;
    assets: any[];
}

interface IAssetFieldProps {
    api: any;
    storyId: string;
    assetId: string;
    label: string;
    width: number;
    tooltip: string;
    onChange: (asset: any) => any;
    updateFilters: (filters: any) => any;
    uploadAssets: (api: any, files: File[], bindToOverlay?: (f: any) => any) => any;
    accepts?: string | string[];
    labelPosition?: string;
    onError?: (message: string) => void;
    labelWidth?: string | number;
    assetList: IAssetList;
}

interface IAssetFieldState {
    assetName: string;
    error: boolean;
    assetUploading: boolean;
}

class AssetField extends React.PureComponent<IAssetFieldProps, IAssetFieldState> {
    private loadAssetTimer: any;

    constructor(props) {
        super(props);

        this.state = {
            assetName: '',
            error: false,
            assetUploading: false
        };
    }

    public componentDidMount() {
        const { assetId } = this.props;
        // get the asset name from the API
        if (assetId && !this.state.assetName) {
            this.loadAsset();
        }
    }

    public componentDidUpdate(prevProps) {
        const { assetList, assetId } = this.props;
        if (assetList !== prevProps.assetList && assetId) {
            clearTimeout(this.loadAssetTimer);
            this.loadAssetTimer = setTimeout(() => this.loadAsset(), 300);
        }
    }

    public componentWillUnmount(): void {
        clearTimeout(this.loadAssetTimer);
    }

    private loadAsset() {
        const { assetId, api } = this.props;

        api.getAssetItem(assetId)
            .then((data: any) => {
                this.setState({
                    error: false,
                    assetName: data.name
                });
            })
            .catch((e: Error) => {
                this.setState({
                    error: true,
                    assetName: `Could not find asset: ${assetId}`
                });
            });
    }

    public setFilter(e) {
        const { accepts } = this.props;
        this.props.updateFilters({ name: '', tags: '', type: accepts ? accepts : '', page: 1 });
    }

    public setFilterToAsset(e) {
        this.props.updateFilters({ name: this.state.assetName, page: 1 });
    }

    private onFileDrop = (i: any, monitor: any): void => {
        const { accepts, api } = this.props;
        if (monitor) {
            const { files } = monitor.getItem();

            if (mimetypeConformsToOverlay(accepts, files[0])) {
                this.setState({ assetUploading: true });

                this.props.uploadAssets(api, [files[0]], (f: any) => {
                    this.setState({ assetName: f.name, error: false }, () => {
                        this.setState({ assetUploading: false });
                        this.props.onChange(f);
                    });
                });
            }
        }
    };

    private onAssetDrop(data, m) {
        const {
            rowData,
            rowData: { name, type }
        } = data;
        const { accepts } = this.props;
        let acceptTypes: string;

        if (typeof accepts === 'object') {
            acceptTypes = accepts.join(' or ');
        } else {
            acceptTypes = accepts;
        }

        if (accepts.includes(type)) {
            if (circularCompCheck(rowData)) return null;

            this.setState(
                {
                    assetName: name,
                    error: false
                },
                () => {
                    this.props.onChange(rowData);
                }
            );
        } else {
            if (this.props.onError) {
                this.props.onError(copy.asset.fieldMismatchError.replace('[accepts]', acceptTypes));
            }
        }
    }

    private clearAsset(e) {
        this.setState(
            {
                assetName: '',
                error: false
            },
            () => {
                this.props.onChange(null);
            }
        );
    }

    public render() {
        const { assetId, label, width, tooltip, labelPosition, accepts, labelWidth } = this.props;
        const { assetName, assetUploading, error } = this.state;
        const errorClass = error ? 'error' : '';
        const placeholder = !assetId ? copy.asset.dragPrompt : '';
        const style = { width: width || '100%' };

        const buttons: JSX.Element[] = [];

        const btnFilterType = (
            <Button
                key='btn-filter-type'
                onClick={(e) => this.setFilter(e)}
                style='subtle'
                tooltip={copy.asset.tooltipFilter}
                color='default'>
                {ICON_FILTER}
            </Button>
        );

        const btnFilterName = (
            <Button
                key='btn-filter-asset'
                onClick={(e) => this.setFilterToAsset(e)}
                style='subtle'
                tooltip={copy.asset.tooltipFilterAsset}
                color='primary'>
                {ICON_FILTER}
            </Button>
        );

        const btnClearAsset = (
            <Button
                key='btn-clear-asset'
                tooltip={copy.asset.tooltipClear}
                onClick={(e) => this.clearAsset(e)}
                style='subtle'>
                {ICON_TIMES}
            </Button>
        );

        if (accepts && typeof accepts !== 'object') {
            buttons.push(btnFilterType);

            if (this.state.assetName) {
                buttons.push(btnFilterName);
            }
        }

        if (assetId && !assetUploading) {
            buttons.unshift(btnClearAsset);
        }

        return (
            <div
                style={style}
                className={`asset-field ${errorClass} ${accepts}`}>
                <AssetsTableDropzone
                    onDrop={(i, m) => this.onFileDrop(i, m)}
                    disable={assetUploading}
                    className='dropzone'>
                    {!assetId ? <div className='warning'>{ICON_WARNING}</div> : ''}
                    <DroppableAssetRenderer onDrop={(i, m) => this.onAssetDrop(i, m)}>
                        <TextField
                            readOnly={true}
                            label={label}
                            tooltip={tooltip}
                            loading={assetUploading}
                            placeholder={placeholder}
                            buttons={buttons}
                            labelPosition={labelPosition}
                            labelWidth={labelWidth}
                            value={assetName}
                        />
                    </DroppableAssetRenderer>
                </AssetsTableDropzone>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateFilters, uploadAssets }, dispatch);
};

const mapStateToProps = (state): any => {
    return { assetList: state.assetList };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetField);
