import * as React from 'react';
import { ICON_PLUS, layerTypeIcon } from '../../constants/icons';
import { fields, timeline } from '../../constants/copy';
import { COMPOSITION_LAYER_TYPES } from '../../constants/story';
import { newCompositionLayer } from '../../util/story';
import { ICompositionLayer } from '../../constants/snippets';

interface IAddLayerButtonProps {
    onAddLayer(type: string): void;
    createNewHTMLAsset(): any;
    addLayer(compId: string, layer: ICompositionLayer, index?: number): any;
    updateTimelineState(state: any): any;
    width: number;
    height: number;
    totalFrames: any;
    compositionId: string;
}

interface IAddLayerButtonState {
    adding: boolean;
}

class AddLayerButton extends React.PureComponent<IAddLayerButtonProps, IAddLayerButtonState> {
    private revertTimeout: any;

    constructor(props) {
        super(props);

        this.state = {
            adding: false
        };
    }

    public componentWillUnmount() {
        clearTimeout(this.revertTimeout);
    }

    public showButtons() {
        this.setState({
            adding: true
        });

        this.resetTimeout();
    }

    public hideButtons() {
        this.setState({
            adding: false
        });
    }

    public addLayer(type: string) {
        const { width, height, totalFrames, compositionId } = this.props;
        this.setState({ adding: false });
        clearTimeout(this.revertTimeout);
        if (type === COMPOSITION_LAYER_TYPES.HTML) {
            const newlayer = newCompositionLayer(type, width, height, width, height, totalFrames);
            const configLayer = { ...newlayer };
            this.props.createNewHTMLAsset().then((html) => {
                configLayer['options']['source']['asset_id'] = html.id;
                this.props.addLayer(compositionId, configLayer);
                this.props.updateTimelineState({
                    activeTimelineLayer: configLayer.id,
                    activeKeyframes: []
                });
            });
        } else {
            this.props.onAddLayer(type);
        }
    }

    public resetTimeout() {
        clearTimeout(this.revertTimeout);
        this.revertTimeout = setTimeout(() => this.hideButtons(), 5000);
    }

    public render() {
        const showClass = this.state.adding ? 'show-buttons' : '';

        return (
            <div
                id='add-layer-button'
                className={showClass}>
                <div
                    className='btn-layer vid'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.VIDEO)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.VIDEO)}</div>
                    <div className='label'>{fields.global.video}</div>
                </div>
                <div
                    className='btn-layer img'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.IMAGE)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.IMAGE)}</div>
                    <div className='label'>{fields.global.image}</div>
                </div>
                <div
                    className='btn-layer img_sequence'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE)}>
                    <div className='icon'>
                        {layerTypeIcon(COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE)}
                    </div>
                    <div className='label'>{fields.global.image_sequence}</div>
                </div>
                <div
                    className='btn-layer aud'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.AUDIO)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.AUDIO)}</div>
                    <div className='label'>{fields.global.audio}</div>
                </div>
                <div
                    className='btn-layer txt'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.TEXT)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.TEXT)}</div>
                    <div className='label'>{fields.global.text}</div>
                </div>
                <div
                    className='btn-layer html'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.HTML)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.HTML)}</div>
                    <div className='label'>{fields.global.html}</div>
                </div>
                <div
                    className='btn-layer temp'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.TEMPLATE)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.TEMPLATE)}</div>
                    <div className='label'>{fields.global.template}</div>
                </div>
                <div
                    className='btn-layer solid'
                    onMouseEnter={() => this.resetTimeout()}
                    onClick={() => this.addLayer(COMPOSITION_LAYER_TYPES.SOLID)}>
                    <div className='icon'>{layerTypeIcon(COMPOSITION_LAYER_TYPES.SOLID)}</div>
                    <div className='label'>{fields.global.solid}</div>
                </div>

                <div
                    className='add-layer-prompt'
                    onClick={() => this.showButtons()}>
                    {ICON_PLUS}&nbsp;{timeline.addLayer}
                </div>
            </div>
        );
    }
}

export default AddLayerButton;
