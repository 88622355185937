import * as React from 'react';
import {
    AUDIO_BITRATES,
    AUDIO_SAMPLE_RATES,
    AUDIO_FORMAT_OPTIONS,
    AUDIO_DEFAULT_SETTNGS,
    AUDIO_CHANNEL_OPTIONS
} from '../constants/story';
import { TextField, SelectField, Card, HRule, Button } from '@imposium-hub/components';
import { IVideoStoryAudioOutputSetting } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { ICON_COPY, ICON_TRASH, ICON_CHECK, ICON_TIMES } from '../constants/icons';
import { openConfirmModal } from '../util/ui';

interface IVideoStoryAudioOutputSettingProps {
    config: IVideoStoryAudioOutputSetting;
    settings?: IVideoStoryAudioOutputSetting[];
    isNew?: boolean;
    type: string;
    onUpdate?(c): void;
    onDelete?(): void;
    onDuplicate?(): void;
    onConfirm?(c): void;
    onCancel?(): void;
}

interface IVideoStoryAudioOutputSettingState {
    isNameDup: boolean;
}

class VideoStoryAudioOutputSetting extends React.PureComponent<
    IVideoStoryAudioOutputSettingProps,
    IVideoStoryAudioOutputSettingState
> {
    private nameField: any;

    constructor(props) {
        super(props);

        this.state = {
            isNameDup: false
        };

        this.nameField = React.createRef();
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;
        this.props.onUpdate(newConfig);
    }

    private nameCheckHandler(v) {
        const { settings, isNew } = this.props;
        if (isNew) {
            const isDup = settings.findIndex((s) => s.name === v);
            if (isDup >= 0) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        } else {
            const dupSetting = [];
            settings.map((s) => {
                if (s.name === v) {
                    dupSetting.push(s);
                }
            });

            if (dupSetting.length >= 1) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        }
    }

    private updateFormat(configValue) {
        const { config } = this.props;
        const newConfig = { ...config, ...AUDIO_DEFAULT_SETTNGS[configValue] };
        this.props.onUpdate(newConfig);
    }

    private confirmDelete() {
        const { config } = this.props;

        openConfirmModal({
            onYes: () => this.props.onDelete(),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private renderButtons() {
        const { config, isNew } = this.props;
        const { isNameDup } = this.state;

        if (isNew) {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        color='primary'
                        disabled={isNameDup}
                        onClick={() => this.props.onConfirm(config)}>
                        {ICON_CHECK}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onCancel()}>
                        {ICON_TIMES}
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onDuplicate()}
                        tooltip={copy.global.duplicate}>
                        {ICON_COPY}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.confirmDelete()}
                        tooltip={copy.global.delete}>
                        {ICON_TRASH}
                    </Button>
                </div>
            );
        }
    }

    public componentDidMount() {
        const { isNew } = this.props;
        const field = this.nameField.current;

        if (isNew) {
            if (field) {
                field.setEditing(true);
            }
        }
    }

    public render() {
        const { config, isNew } = this.props;

        const title = isNew ? (
            <span className='text-primary'>{copy.encoding.newAudioOutput}</span>
        ) : (
            config['name']
        );

        const { isNameDup } = this.state;

        return (
            <Card
                title={title}
                collapsable={!isNew}
                open={true}>
                <div className='encoding-setting-config'>
                    <TextField
                        className={isNameDup ? 'warning' : ''}
                        ref={this.nameField}
                        label={copy.global.id}
                        tooltip={copy.encoding.tooltipID}
                        info={isNameDup ? copy.encoding.nameWarning : ''}
                        value={config['name']}
                        width='100%'
                        onChange={(v) => {
                            this.inputChanged('name', v);
                            this.nameCheckHandler(v.trim());
                        }}
                    />

                    <SelectField
                        label={copy.encoding.format}
                        tooltip={copy.encoding.tooltipAudioFormat}
                        options={AUDIO_FORMAT_OPTIONS}
                        value={config['extension']}
                        width='50%'
                        onChange={(newValue) => this.updateFormat(newValue)}
                    />

                    <SelectField
                        label={copy.encoding.audioChannels}
                        tooltip={copy.encoding.tooltipAudioChannels}
                        options={AUDIO_CHANNEL_OPTIONS}
                        value={config['channels']}
                        width='50%'
                        onChange={(v) => this.inputChanged('channels', v)}
                    />

                    <SelectField
                        label={copy.encoding.audioSampelRate}
                        tooltip={copy.encoding.tooltipAudioSampleRate}
                        options={AUDIO_SAMPLE_RATES}
                        value={config['sample_rate']}
                        width='50%'
                        onChange={(v) => this.inputChanged('sample_rate', v)}
                    />

                    <SelectField
                        label={copy.encoding.audioBitrate}
                        tooltip={copy.encoding.tooltipAudioBitrate}
                        options={AUDIO_BITRATES[config['extension']]}
                        value={config['bitrate']}
                        width='50%'
                        onChange={(v) => this.inputChanged('bitrate', v)}
                    />

                    <HRule style='subtle' />

                    {this.renderButtons()}
                </div>
            </Card>
        );
    }
}

export default VideoStoryAudioOutputSetting;
