import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createComposition } from '../redux/actions/compositions';
import { colorPresets, ILayeredComposition, IStory, NEW_COMPOSITION } from '../constants/snippets';
import { closeViewer, addViewer } from '../redux/actions/story';
import {
    NumberField,
    ColorField,
    Button,
    SelectField,
    TextField,
    doAssetTableHydration,
    SMPTEField,
    getSMPTE
} from '@imposium-hub/components';
import { fields } from '../constants/copy';
import { ASSET_TYPES, RATE_OPTIONS } from '../constants/story';
import { ICON_PLUS, ICON_TIMES } from '../constants/icons';
import { api } from '../constants/app';
import { VIEWER_TYPES } from '../constants/viewer';
import { MAX_OUTPUT_HEIGHT, MAX_OUTPUT_WIDTH, MIN_OUTPUT_DIMENSION } from '../constants/editor';
import Timecode from 'smpte-timecode';
import { getDuration } from '../util/ui';
import { logError } from '../util/notifications';

interface INewCompositionInterfaceProps {
    story: IStory;
    active: boolean;
    maximumDuration: number;
    addViewer(c: any);
    closeViewer(id);
    createComposition(sId: string, comp: ILayeredComposition, name?: string): any;
    doAssetTableHydration(apiInstance, sId): void;
}

interface INewCompositionInterfaceState {
    composition: ILayeredComposition;
    compName: string;
    duration: string;
    durationError: boolean;
}

class NewCompositionInterface extends React.PureComponent<
    INewCompositionInterfaceProps,
    INewCompositionInterfaceState
> {
    private evtHandlers: any;

    constructor(p) {
        super(p);

        const { frames, rate } = NEW_COMPOSITION;
        const duration = getDuration(frames, rate);

        this.state = {
            composition: { ...NEW_COMPOSITION },
            compName: 'New Composition',
            duration,
            durationError: false
        };

        this.evtHandlers = {
            width: (v) => this.updateField('width', v),
            height: (v) => this.updateField('height', v),
            rate: (v) => this.updateField('rate', parseFloat(v)),
            frames: (v) => this.updateFrames(v),
            duration: {
                onChange: (v) => this.updateDuration('onChange', v),
                onBlur: (v) => this.updateDuration('onBlur', v),
                onError: (v) => this.setState({ durationError: !v })
            },
            color: (v) => this.updateField('background_color', v.hex),
            name: (n) => this.setState({ compName: n })
        };
    }

    private updateFrames(v) {
        const { maximumDuration } = this.props;
        const {
            composition: { rate }
        } = this.state;
        const maxFrames = maximumDuration ? Math.ceil(this.props.maximumDuration * rate) : null;
        if (maxFrames && v > maxFrames) {
            logError(
                fields.compositions.errorTooManyFrames.replace(
                    '[duration]',
                    maximumDuration.toString()
                )
            );
        } else {
            const duration = getDuration(v, rate);
            this.setState({ duration });
            this.updateField('frames', v);
        }
    }

    private updateField(field, value) {
        const { frames } = this.state.composition;
        const newComp = { ...this.state.composition };

        if (field === 'width' || field === 'height') {
            newComp[field] = parseInt(value, 10);
        } else {
            newComp[field] = value;
        }

        if (field === 'rate') {
            const duration = getDuration(frames, value);
            this.setState({ duration });
        }

        this.setState({ composition: newComp });
    }

    private createComposition() {
        const { story } = this.props;
        const { composition, compName } = this.state;

        this.props
            .createComposition(story.id, composition, compName)
            .then((c: ILayeredComposition) => {
                this.props.addViewer({
                    id: c.id,
                    label: `Composition ${compName}`,
                    type: ASSET_TYPES.VIDEO_COMPOSITION
                });
                this.props.doAssetTableHydration(api, story.id);
                this.props.closeViewer(VIEWER_TYPES.NEW_COMPOSITION);
            });
    }

    private validateCompInState() {
        const {
            composition: { width, height, rate, frames },
            compName,
            durationError
        } = this.state;

        return !!width && !!height && !!rate && !!frames && !!compName && !durationError;
    }

    private cancelNewComp() {
        this.props.closeViewer(VIEWER_TYPES.NEW_COMPOSITION);
    }

    private updateDuration(type, v) {
        const {
            composition: { rate }
        } = this.state;
        let value = v;
        this.setState({ durationError: false });
        if (type === 'onChange') {
            value = getSMPTE(rate, v);
        }
        if (type === 'onBlur') {
            this.setState({ duration: v });
        }
        const duration = new Timecode(value, Math.ceil(rate), false);
        this.updateField('frames', duration.frameCount);
    }

    public render() {
        const { composition, compName, duration } = this.state;
        const { active } = this.props;

        const valid: any = this.validateCompInState();

        if (!active) {
            return null;
        }
        return (
            <div className={`new-composition-inerface`}>
                <div className='new-composition-modal'>
                    <h1>Enter the settings for your new Composition</h1>
                    <TextField
                        labelPosition='top'
                        width='100%'
                        label={fields.global.name}
                        onChange={this.evtHandlers.name}
                        value={compName}
                    />
                    <NumberField
                        labelPosition='top'
                        width='50%'
                        label={fields.global.width}
                        onChange={this.evtHandlers.width}
                        value={composition.width}
                        max={MAX_OUTPUT_WIDTH}
                        min={MIN_OUTPUT_DIMENSION}
                    />
                    <NumberField
                        labelPosition='top'
                        width='50%'
                        label={fields.global.height}
                        onChange={this.evtHandlers.height}
                        value={composition.height}
                        max={MAX_OUTPUT_HEIGHT}
                        min={MIN_OUTPUT_DIMENSION}
                    />
                    <SelectField
                        options={RATE_OPTIONS}
                        labelPosition='top'
                        width='33%'
                        label={fields.global.rate}
                        onChange={this.evtHandlers.rate}
                        value={composition.rate}
                    />
                    <NumberField
                        labelPosition='top'
                        width='33%'
                        label={fields.global.frames}
                        onChange={this.evtHandlers.frames}
                        value={composition.frames}
                    />
                    <SMPTEField
                        label={fields.global.duration}
                        labelPosition='top'
                        width='33%'
                        value={duration}
                        frameRate={composition.rate}
                        onBlur={this.evtHandlers.duration.onBlur}
                        onChange={this.evtHandlers.duration.onChange}
                        onError={this.evtHandlers.duration.onError}
                    />
                    <ColorField
                        labelPosition='top'
                        width='100%'
                        enableAlpha={false}
                        presetColors={colorPresets}
                        label={fields.global.backgroundColor}
                        onChange={this.evtHandlers.color}
                        value={composition.background_color}
                    />

                    <div className='btns'>
                        <Button
                            color='primary'
                            size='large'
                            style='bold'
                            disabled={!valid}
                            onClick={() => this.createComposition()}>
                            {ICON_PLUS} {fields.compositions.create}
                        </Button>
                    </div>

                    <Button
                        customStyles={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px'
                        }}
                        style='subtle'
                        onClick={() => this.cancelNewComp()}>
                        {ICON_TIMES}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            createComposition,
            addViewer,
            closeViewer,
            doAssetTableHydration
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        maximumDuration: state?.org?.max_comp_duration
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCompositionInterface);
