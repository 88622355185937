import * as React from 'react';
import TagFields from './TagFields';
import OverlayConfig from './OverlayConfig';
import { OVERLAY_METHOD_OPTIONS, AUDIO_SOURCE_OPTIONS } from '../constants/story';
import {
    Card,
    TextField,
    NumberField,
    SelectField,
    CheckboxField,
    Button,
    Section,
    ButtonMenu,
    ButtonMenuItem,
    ButtonGroupField
} from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ICut, IVariables, IAssetList } from '../constants/snippets';
import { addViewer } from '../redux/actions/story';
import { VIEWER_TYPES } from '../constants/viewer';
import { ASSET_TYPES, SCENE_TYPES } from '../constants/story';
import { updateEditorConfig } from '../redux/actions/editor';
import { SuperUserOnly } from './SuperUserOnly';
import { ICON_PLAY, ICON_TRASH, ICON_ELLIPSIS_V, ICON_CUT } from '../constants/icons';
import ErrorIndicator from './errors/ErrorIndicator';
import { STORY_ERRORS } from '../constants/errors';
import { api } from '../constants/app';
import { generateUUID } from '../util/story';
import CutEffectConfig from './CutEffectConfig';
import { logError } from '../util/notifications';
import AssetField from './assets/AssetField';
import { updateTimelineState } from '../redux/actions/timeline';
import { openConfirmModal } from '../util/ui';

interface ICutConfigProps {
    config: ICut;
    project: any;
    width: number;
    height: number;
    rate: number;
    variables: IVariables;
    assetList: IAssetList;
    activeOverlay: string;
    updateEditorConfig(c): void;
    updateTimelineState(s): void;
    onUpdate(c): void;
    onDelete(): void;
    addViewer(e): void;
}

interface ICutConfigState {
    filteredAssets: number;
}

class CutConfig extends React.PureComponent<ICutConfigProps, ICutConfigState> {
    private evtHandlers: any;

    private readonly menu: JSX.Element;

    constructor(props) {
        super(props);

        this.state = {
            filteredAssets: 0
        };

        this.evtHandlers = {
            inputs: {
                tags: (t) => this.inputChanged('tags', t),
                name: (v) => this.inputChanged('name', v),
                assetId: (v) => this.inputChanged('assetId', v ? v.id : null),
                clipSwap: (v) => this.inputChanged('clipSwap', v),
                useCutLength: (v) => this.inputChanged('useCutLength', v),
                offset: (v) => this.inputChanged('offset', v),
                audioSource: (v) => this.inputChanged('audioSource', v),
                effects: (v) => this.inputChanged('effects', v),
                overlayMethod: (v) => this.inputChanged('staticOverlayMethod', v),
                assetError: (m) => {
                    logError(m);
                }
            },
            preview: () => this.previewCut(),
            delete: () => this.confirmDelete()
        };

        const menuItems = [
            <ButtonMenuItem
                key='preview'
                label={
                    <span>
                        {ICON_PLAY}&nbsp;{copy.cutConfig.btnPreview}
                    </span>
                }
                onClick={this.evtHandlers.preview}
            />,
            <ButtonMenuItem
                key='delete'
                label={
                    <span>
                        {ICON_TRASH}&nbsp;{copy.cutConfig.btnDeleteCut}
                    </span>
                }
                onClick={this.evtHandlers.delete}
            />
        ];

        this.menu = (
            <ButtonMenu
                position='left'
                items={menuItems}
                button={<Button style='subtle'>{ICON_ELLIPSIS_V}</Button>}
            />
        );
    }

    public componentDidMount() {
        this.getFilteredTagCount();
    }

    public componentDidUpdate(prevProps: Readonly<ICutConfigProps>) {
        if (
            prevProps.config.tags &&
            this.props.config.tags &&
            prevProps.config.tags.length !== this.props.config.tags.length
        ) {
            this.getFilteredTagCount();
        }
    }

    private previewCut() {
        const { config } = this.props;

        this.props.addViewer({
            type: VIEWER_TYPES.CUT_PREVIEW,
            id: config.id,
            label: config.name
        });
    }

    private renderClipSwapConfig() {
        const {
            config: { clipSwap, tags, useCutLength, audioSource, offset, assetId },
            variables,
            project: { storyId }
        } = this.props;

        if (clipSwap) {
            return (
                <>
                    <TagFields
                        tags={tags || []}
                        variables={variables}
                        assetType={ASSET_TYPES.VIDEO}
                        onChange={this.evtHandlers.inputs.tags}
                    />
                    <AssetField
                        api={api}
                        storyId={storyId}
                        label={copy.cutConfig.assetId}
                        width='100%'
                        tooltip={copy.cutConfig.tooltipAssetId}
                        onChange={this.evtHandlers.inputs.assetId}
                        onError={this.evtHandlers.inputs.assetError}
                        accepts={[ASSET_TYPES.VIDEO, ASSET_TYPES.VIDEO_COMPOSITION]}
                        assetId={assetId}
                    />
                    <ButtonGroupField
                        label={copy.cutConfig.audio}
                        tooltip={copy.cutConfig.tooltipAudio}
                        value={audioSource}
                        key='audio'
                        width='50%'
                        options={AUDIO_SOURCE_OPTIONS}
                        onChange={this.evtHandlers.inputs.audioSource}
                    />
                    <CheckboxField
                        label={copy.cutConfig.useCutLength}
                        tooltip={copy.cutConfig.tooltipCutLength}
                        value={useCutLength}
                        width='50%'
                        onChange={this.evtHandlers.inputs.useCutLength}
                    />
                    <NumberField
                        label={copy.cutConfig.offset}
                        tooltip={copy.cutConfig.tooltipOffset}
                        value={offset}
                        width='50%'
                        onChange={this.evtHandlers.inputs.offset}
                    />
                </>
            );
        }
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        this.props.onUpdate(newConfig);
    }

    private updateOverlay(index, config) {
        const newConfig = { ...this.props.config };
        const overlays = [...newConfig.overlays];
        overlays[index] = config;
        newConfig.overlays = overlays;

        this.props.onUpdate(newConfig);
    }

    private deleteOverlay(index) {
        const newConfig = { ...this.props.config };
        const newOverlays = [...newConfig.overlays];
        newOverlays.splice(index, 1);
        newConfig.overlays = newOverlays;

        this.props.updateTimelineState({ activeTimelineOverlay: null });
        this.props.onUpdate(newConfig);
    }

    private confirmDelete() {
        const { config } = this.props;
        openConfirmModal({
            onYes: () => this.props.onDelete(),
            title: copy.global.deletePrompt.replace('[name]', config.name)
        });
    }

    private getCutDuration() {
        const {
            config: { startFrame, endFrame }
        } = this.props;

        if (startFrame !== undefined && endFrame !== undefined) {
            return endFrame - startFrame;
        } else {
            return 0;
        }
    }

    private getFilteredTagCount() {
        const {
            config: { clipSwap, tags },
            project: { storyId }
        } = this.props;

        if (clipSwap) {
            const addTag = (t) => {
                if (!tagStr) {
                    tagStr = t;
                } else {
                    tagStr += `,${t}`;
                }
            };

            let tagStr = '';

            for (const tag of tags) {
                if (tag.type === 'text') {
                    addTag(tag.value);
                }
            }

            // only show if there are tags to filter by
            if (tagStr) {
                api.getAssets({ tags: tagStr, type: ASSET_TYPES.VIDEO }, storyId).then(
                    (assetList) => {
                        const totalAssets = assetList.assets ? assetList.assets.length : 0;
                        this.setState({ filteredAssets: totalAssets });
                    }
                );
            } else {
                this.setState({ filteredAssets: 0 });
            }
        }
    }

    private duplicateOverlay = (config) => {
        const newOverlayConfig = { ...config };
        newOverlayConfig.id = generateUUID();
        newOverlayConfig.name = `Copy of ${newOverlayConfig.name}`;

        const newConfig = { ...this.props.config };
        const overlays = [...newConfig.overlays];
        overlays.push(newOverlayConfig);
        newConfig.overlays = overlays;

        this.props.onUpdate(newConfig);
    };

    public render() {
        const {
            config,
            activeOverlay,
            variables,
            project: { storyId },
            width,
            height,
            rate
        } = this.props;
        const { filteredAssets } = this.state;
        const dur = this.getCutDuration();

        const count = config.clipSwap ? (
            <div className='asset-count'>Assets Available {filteredAssets}</div>
        ) : null;
        const effects = config.effects || {};

        if (activeOverlay) {
            for (let i = 0; i < config.overlays.length; i++) {
                const overlay = config.overlays[i];
                if (overlay.id === activeOverlay) {
                    return (
                        <OverlayConfig
                            key={overlay.id}
                            storyId={storyId}
                            config={overlay}
                            type={SCENE_TYPES.VIDEO}
                            width={width}
                            height={height}
                            fps={rate}
                            variables={variables}
                            onPreview={this.evtHandlers.preview}
                            onChange={(c) => this.updateOverlay(i, c)}
                            onDuplicate={(c) => this.duplicateOverlay(c)}
                            onDelete={() => this.deleteOverlay(i)}
                        />
                    );
                }
            }
        }

        return (
            <Section
                title={
                    <>
                        {ICON_CUT}&nbsp;{config.name}
                    </>
                }
                buttons={this.menu}>
                <Card
                    title={copy.global.general}
                    open={true}
                    collapsable={false}>
                    <TextField
                        label={copy.cutConfig.name}
                        tooltip={copy.cutConfig.tooltipName}
                        value={config['name']}
                        onChange={this.evtHandlers.inputs.name}
                    />
                    <NumberField
                        label={copy.cutConfig.start}
                        tooltip={copy.cutConfig.tooltipStartFrame}
                        width='33%'
                        readOnly={true}
                        value={config['startFrame']}
                    />
                    <NumberField
                        label={copy.cutConfig.end}
                        tooltip={copy.cutConfig.tooltipEndFrame}
                        width='33%'
                        readOnly={true}
                        value={config['endFrame']}
                    />
                    <NumberField
                        label={copy.global.duration}
                        tooltip={copy.cutConfig.tooltipEndFrame}
                        width='33%'
                        readOnly={true}
                        value={dur}
                    />
                </Card>
                <Card
                    title={copy.cutConfig.swap}
                    open={true}
                    collapsable={true}>
                    <ErrorIndicator
                        cutId={config.id}
                        show={[STORY_ERRORS.CLIP_SWAP]}
                    />
                    <div style={{ display: 'flex' }}>
                        <CheckboxField
                            label={copy.global.enabled}
                            tooltip={copy.cutConfig.tooltipClipSwap}
                            value={config['clipSwap']}
                            onChange={this.evtHandlers.inputs.clipSwap}
                        />
                        {count}
                    </div>
                    {this.renderClipSwapConfig()}
                </Card>
                <Card
                    title={copy.cutConfig.effects}
                    open={false}
                    collapsable={true}>
                    <CutEffectConfig
                        storyId={storyId}
                        config={effects}
                        onChange={this.evtHandlers.inputs.effects}
                    />
                </Card>
                <SuperUserOnly>
                    <Card
                        title={copy.global.advanced}
                        open={true}
                        collapsable={true}>
                        <SelectField
                            label={copy.cutConfig.overlayMethod}
                            tooltip={copy.cutConfig.tooltipOverlay}
                            value={config['staticOverlayMethod']}
                            options={OVERLAY_METHOD_OPTIONS}
                            onChange={this.evtHandlers.inputs.overlayMethod}
                        />
                    </Card>
                </SuperUserOnly>
            </Section>
        );
    }
}
const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ addViewer, updateEditorConfig, updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        assetList: state.assetList,
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CutConfig);
