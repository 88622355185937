import * as React from 'react';
import { colorPresets, ILayeredComposition, IStory } from '../constants/snippets';
import { updateEditorConfig } from '../redux/actions/editor';
import { connect } from 'react-redux';
import { editComposition } from '../redux/actions/compositions';
import { updateScene } from '../redux/actions/story';
import { bindActionCreators } from 'redux';
import { fields } from '../constants/copy';
import {
    NumberField,
    ColorField,
    SelectField,
    CheckboxField,
    Button,
    TextField,
    SMPTEField,
    getSMPTE,
    HRule
} from '@imposium-hub/components';
import { RATE_OPTIONS } from '../constants/story';
import { logError } from '../util/notifications';
import { MAX_OUTPUT_HEIGHT, MAX_OUTPUT_WIDTH, MIN_OUTPUT_DIMENSION } from '../constants/editor';
import { ICON_SAVE } from '../constants/icons';
import Timecode from 'smpte-timecode';
import { capitalizeWord, getDuration } from '../util/ui';
import { rgbaToHexa } from '../util/general';

interface ICompositionSettingsProps {
    story: IStory;
    project: any;
    compositions: ILayeredComposition;
    maximumDuration: number;
    editComposition(id, c): void;
    updateScene(c): void;
    updateEditorConfig(c): void;
    onSave(): void;
}

interface ICompositionSettingsState {
    composition: ILayeredComposition;
    duration: string;
    error: boolean;
    durationError: boolean;
}

class CompositionSettings extends React.PureComponent<
    ICompositionSettingsProps,
    ICompositionSettingsState
> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            width: (v) => this.updateField('width', v),
            height: (v) => this.updateField('height', v),
            rate: (v) => this.updateField('rate', parseFloat(v)),
            frames: (v) => this.updateFrames(v),
            color: (v) =>
                this.updateField(
                    'background_color',
                    v.hex === 'transparent' ? 'transparent' : rgbaToHexa(v.rgb)
                ),
            layerDuration: (v) => this.updateField('fit_layer_duration', v),
            duration: {
                onChange: (v) => this.updateDuration('onChange', v),
                onBlur: (v) => this.updateDuration('onBlur', v),
                onError: (v) => this.setState({ durationError: !v })
            }
        };

        const {
            compositions,
            project: { compositionId }
        } = this.props;
        const composition = compositions[compositionId] || null;
        const { frames, rate } = composition;
        const duration = getDuration(frames, rate);

        this.state = {
            composition,
            duration,
            error: false,
            durationError: false
        };
    }

    private updateFrames(v) {
        const { maximumDuration } = this.props;
        const {
            composition: { rate }
        } = this.state;

        const maxFrames = maximumDuration ? Math.ceil(this.props.maximumDuration * rate) : null;

        if (maxFrames && v > maxFrames) {
            logError(
                fields.compositions.errorTooManyFrames.replace(
                    '[duration]',
                    maximumDuration.toString()
                )
            );
        } else {
            const duration = getDuration(v, rate);
            this.setState({ duration });
            this.updateField('frames', v);
        }
    }

    private updateDuration(type, v) {
        const {
            composition: { rate }
        } = this.state;

        let value = v;

        this.setState({ durationError: false });

        if (type === 'onChange') {
            value = getSMPTE(rate, v);
        }

        if (type === 'onBlur') {
            this.setState({ duration: v });
        }

        const duration = new Timecode(value, Math.ceil(rate), false);
        this.updateField('frames', duration.frameCount);
    }

    private checkChanges() {
        const { composition: stateComp, durationError } = this.state;

        const {
            compositions,
            project: { compositionId }
        } = this.props;

        const propComp = compositions[compositionId] || null;

        return JSON.stringify(stateComp) === JSON.stringify(propComp) || durationError;
    }

    private updateField(field, value) {
        const { frames } = this.state.composition;
        const newComp = { ...this.state.composition };

        if (field === 'width' || field === 'height') {
            newComp[field] = parseInt(value, 10);
        } else {
            newComp[field] = value;
        }

        if (field === 'rate') {
            const duration = getDuration(frames, value);
            this.setState({ duration });
        }

        this.setState({ composition: newComp });
    }

    private updateExcluded(field, value, isExcluded) {
        const newComp = { ...this.state.composition };
        const excluded = newComp[field] ? [...newComp[field]] : [];
        if (!isExcluded) {
            const valueIndex = newComp[field].indexOf(value);
            excluded.splice(valueIndex, 1);
        } else {
            excluded.push(value);
        }
        newComp[field] = excluded;
        this.setState({ composition: newComp });
    }

    private saveSettings() {
        this.props.editComposition(this.props.project.compositionId, this.state.composition);
        this.props.updateEditorConfig({ reHydrateAssetTable: true });
        this.props.onSave();
    }

    private outputSettings(type: string) {
        const { composition } = this.state;

        const {
            project: { actId, sceneId },
            story
        } = this.props;

        const act = story.acts[actId];
        const sceneData = act.scenes[sceneId].sceneData;
        const { encodingSettings, imageOutputSettings, audioOutputSettings } = sceneData;

        let excluded = composition?.excluded_video_outputs
            ? composition.excluded_video_outputs
            : [];
        let settings = encodingSettings;

        if (type === 'image') {
            settings = imageOutputSettings;
            excluded = composition?.excluded_image_outputs
                ? composition.excluded_image_outputs
                : [];
        }

        if (type === 'audio') {
            settings = audioOutputSettings;
            excluded = composition?.excluded_audio_outputs
                ? composition.excluded_audio_outputs
                : [];
        }

        const title = capitalizeWord(type);

        const outputSettings = settings && settings.length > 0 && (
            <div
                className='outputs'
                key={`settings-${type}`}>
                <h3>{`${title} Output(s)`}</h3>
                {settings.map((setting) => {
                    const isExcluded = !excluded.includes(setting.name);
                    return (
                        <div
                            className='setting'
                            key={setting.name}>
                            <CheckboxField
                                key={setting.name}
                                labelPosition='right'
                                width='30px'
                                onChange={() =>
                                    this.updateExcluded(
                                        `excluded_${type}_outputs`,
                                        setting.name,
                                        isExcluded
                                    )
                                }
                                value={isExcluded}
                            />
                            <div className='setting-name'>{setting.name}</div>
                        </div>
                    );
                })}
            </div>
        );

        return outputSettings;
    }

    public render() {
        const {
            composition,
            duration,
            composition: { rate }
        } = this.state;

        const {
            project: { compositionId }
        } = this.props;

        const isChanged = this.checkChanges();

        const outputSettings = (
            <>
                <HRule />
                <br />
                <br />
                <h2>{fields.compositions.outputSettings}</h2>
                <p>{fields.compositions.outputSettingsInfo}</p>
                <br />
                <div className='compOutputs'>
                    {this.outputSettings('video')}
                    {this.outputSettings('image')}
                    {this.outputSettings('audio')}
                </div>
            </>
        );

        return (
            <div className='composition-settings'>
                <div className='composition-setting'>
                    <h1>{fields.compositions.settings}</h1>
                    <TextField
                        width='41%'
                        readOnly={true}
                        value={compositionId}
                        showCopy={true}
                    />
                    <div className='params'>
                        <NumberField
                            labelPosition='top'
                            width='50%'
                            label={fields.global.width}
                            onChange={this.evtHandlers.width}
                            value={composition.width}
                            max={MAX_OUTPUT_WIDTH}
                            min={MIN_OUTPUT_DIMENSION}
                        />
                        <NumberField
                            labelPosition='top'
                            width='50%'
                            label={fields.global.height}
                            onChange={this.evtHandlers.height}
                            value={composition.height}
                            max={MAX_OUTPUT_HEIGHT}
                            min={MIN_OUTPUT_DIMENSION}
                        />
                        <SelectField
                            options={RATE_OPTIONS}
                            labelPosition='top'
                            width='33%'
                            label={fields.global.rate}
                            onChange={this.evtHandlers.rate}
                            value={composition.rate}
                        />
                        <NumberField
                            labelPosition='top'
                            width='33%'
                            label={fields.global.frames}
                            onChange={this.evtHandlers.frames}
                            value={composition.frames}
                        />
                        <SMPTEField
                            label='Duration'
                            labelPosition='top'
                            width='33%'
                            value={duration}
                            frameRate={rate}
                            onBlur={this.evtHandlers.duration.onBlur}
                            onChange={this.evtHandlers.duration.onChange}
                            onError={this.evtHandlers.duration.onError}
                        />
                        <ColorField
                            labelPosition='top'
                            width='50%'
                            enableAlpha={true}
                            presetColors={colorPresets}
                            label={fields.global.backgroundColor}
                            onChange={this.evtHandlers.color}
                            value={composition.background_color}
                        />
                        <CheckboxField
                            labelPosition='top'
                            width='50%'
                            labelWidth={130}
                            label={fields.compositions.layerDuration}
                            info={fields.compositions.layerDurationInfo}
                            onChange={this.evtHandlers.layerDuration}
                            value={composition.fit_layer_duration || false}
                        />
                        {outputSettings}
                    </div>
                </div>
                <Button
                    disabled={isChanged}
                    customStyles={{
                        position: 'absolute',
                        bottom: '3px',
                        left: '50%',
                        marginLeft: '-47px'
                    }}
                    onClick={() => this.saveSettings()}
                    color='primary'>
                    {ICON_SAVE} Save and Close
                </Button>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateEditorConfig, editComposition, updateScene }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        compositions: state.compositions.present,
        project: state.project,
        maximumDuration: state?.org?.max_comp_duration,
        story: state.story
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionSettings);
