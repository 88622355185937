import * as React from 'react';
import { IVariables, NEW_TTS_CONFIG, colorPresets } from '../constants/snippets';
import {
    ButtonGroupField,
    Card,
    ColorField,
    CheckboxField,
    NumberField,
    AdvancedNumberField,
    Spinner,
    FontPicker,
    FieldWrapper,
    CopyPropIdButton,
    ImposiumHeader,
    ImposiumDropdown,
    SelectField
} from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { ASSET_TYPES, DEFAULT_FONT, FONT_TYPES, VARIABLE_TYPES } from '../constants/story';
import { connect } from 'react-redux';
import { api } from '../constants/app';
import { DEFAULT_FONTS } from '../constants/fonts';
import {
    ICON_ALIGN_CENTER,
    ICON_ALIGN_LEFT,
    ICON_ALIGN_RIGHT,
    ICON_ARROW_CENTER_V,
    ICON_ARROW_DOWN,
    ICON_ARROW_UP
} from '../constants/icons';
import ReactTextareaAutocomplete from '@imposium-hub/react-textarea-autocomplete';
import { reactAutoCompleteSettings } from '../util/fields';
import { logError, logNotification } from '../util/notifications';
import moize from 'moize';
import { PROP_KEY_PRFIX } from '../constants/editor';
import { getVariableOptions } from '../util/story';
import { formatColor } from '../util/ui';

interface ITextLayerOptionsProps {
    storyId: string;
    compositionId: string;
    showCopyPropIds: boolean;
    options: any;
    layerId: string;
    type: any;
    onChange(options: any): void;
    assetList: any;
    variables: IVariables;
}

interface ITextLayerOptionsState {
    availableFonts: any[];
    showColorDropdown: boolean;
    showBackgroundColorDropdown: boolean;
    showHighlightColorDropdown: boolean;
    showStrokeColorDropdown: boolean;
}

export const H_ALIGN_OPTIONS = [
    {
        icon: ICON_ALIGN_LEFT,
        value: 'left'
    },
    {
        icon: ICON_ALIGN_CENTER,
        value: 'center'
    },
    {
        icon: ICON_ALIGN_RIGHT,
        value: 'right'
    }
];

export const V_ALIGN_OPTIONS = [
    {
        icon: ICON_ARROW_UP,
        value: 'flex-start'
    },
    {
        icon: ICON_ARROW_CENTER_V,
        value: 'center'
    },
    {
        icon: ICON_ARROW_DOWN,
        value: 'flex-end'
    }
];

class TextLayerOptions extends React.PureComponent<ITextLayerOptionsProps, ITextLayerOptionsState> {
    private readonly colorRef: any = null;

    private readonly highlightColorRef: any = null;

    private readonly backgroundColorRef: any = null;

    private readonly strokeColorRef: any = null;

    private eventHandlers;

    constructor(props) {
        super(props);

        this.state = {
            availableFonts: [],
            showColorDropdown: false,
            showBackgroundColorDropdown: false,
            showHighlightColorDropdown: false,
            showStrokeColorDropdown: false
        };

        this.eventHandlers = {
            inputs: {
                name: (v) => this.optionValueChanged('name', v),
                width: (v) => this.optionValueChanged('width', v),
                height: (v) => this.optionValueChanged('height', v),
                x: (v) => this.optionValueChanged('x', v),
                y: (v) => this.optionValueChanged('y', v),
                rotation: (v) => this.optionValueChanged('rotation', v),
                content: (v) => this.optionValueChanged('content', v),
                hAlign: (v) => this.optionValueChanged('horizontal_alignment', v),
                vAlign: (v) => this.optionValueChanged('vertical_alignment', v),
                textWrap: (v) => this.optionValueChanged('text_wrap', v),
                textFit: (v) => this.optionValueChanged('text_fit', v),
                fontSize: (v) => this.optionValueChanged('font_size', v),
                lineHeight: (v) => this.optionValueChanged('line_height', v),
                letterSpacing: (v) => this.optionValueChanged('letter_spacing', v),
                strokeWeight: (v) => this.optionValueChanged('stroke_weight', v),
                font: (v) => this.optionValueChanged('font', v),
                customFont: (v) => this.customFontUpdated(v),
                wordSpacing: (v) => this.optionValueChanged('word_spacing', v),
                color: (v) => this.colorValueChanged('color', v),
                highlightColor: (v) => this.colorValueChanged('highlight_color', v),
                strokeColor: (v) => this.colorValueChanged('stroke_color', v),
                backgroundColor: (v) => this.colorValueChanged('background_color', v),
                italic: () => this.optionValueChanged('font_style', 'italic'),
                caps: (v) => this.optionValueChanged('upper_case', v),
                textToSpeech: () => this.addTextToSpeech(),
                ttsConfig: (v) => this.ttsConfigHandler(v)
            },
            onNotification: (e) => logNotification(e),
            onError: (e) => logError(e)
        };

        this.colorRef = React.createRef();
        this.highlightColorRef = React.createRef();
        this.backgroundColorRef = React.createRef();
        this.strokeColorRef = React.createRef();
    }

    public componentDidMount() {
        this.getFontsAssets();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.assetList !== this.props.assetList) {
            this.getFontsAssets();
        }
    }

    private colorOptionHandler(option, value) {
        switch (option) {
            case 'stroke':
                this.setState({ showStrokeColorDropdown: false }, () =>
                    this.optionValueChanged('stroke_color_option', value)
                );
                break;
            case 'color':
                this.setState({ showColorDropdown: false }, () =>
                    this.optionValueChanged('color_option', value)
                );
                break;
            case 'highlight':
                this.setState({ showHighlightColorDropdown: false }, () =>
                    this.optionValueChanged('highlight_color_option', value)
                );
                break;
            case 'background':
                this.setState({ showHighlightColorDropdown: false }, () =>
                    this.optionValueChanged('background_color_option', value)
                );
                break;
        }
    }

    private ttsConfigHandler(options) {
        const merged = { ...this.props.options, ...options };
        this.props.onChange(merged);
    }

    private addTextToSpeech() {
        const newOpts = { ...this.props.options };
        newOpts['source'] = {
            from: VARIABLE_TYPES.TEXT,
            text: newOpts['content'],
            text_to_speech: { ...NEW_TTS_CONFIG }
        };

        const merged = { ...this.props.options, ...newOpts };
        this.props.onChange(merged);
    }

    private customFontUpdated(asset) {
        const newOpts = { ...this.props.options };

        if (asset) {
            const { name, url, id } = asset;
            newOpts['font'] = name;
            newOpts['custom_font_url'] = url;
            newOpts['custom_font_id'] = id;
        } else {
            newOpts['font'] = DEFAULT_FONT;
            newOpts['custom_font_url'] = null;
            newOpts['custom_font_id'] = null;
        }

        this.props.onChange(newOpts);
    }

    private getFontsAssets() {
        const { storyId } = this.props;

        api.getAssets({ type: ASSET_TYPES.FONT }, storyId)
            .then((assetList) => {
                if (assetList.assets) {
                    this.setState({
                        availableFonts: assetList.assets
                    });
                }
            })
            .catch((e) => console.error(e));
    }

    private colorValueChanged(key, value) {
        const newOpts = { ...this.props.options };
        let newValue = value;

        if (typeof value === 'object') {
            newValue = formatColor(value);
        }

        newOpts[key] = newValue;
        this.props.onChange(newOpts);
    }

    private optionValueChanged(key, value) {
        const newOpts = { ...this.props.options };

        if (key === 'font') {
            if (value === copy.overlayConfig.fontNotFound) {
                return;
            }

            const { availableFonts } = this.state;
            const standardFonts = DEFAULT_FONTS.find((font) => font.name === value);
            const customFonts = availableFonts.filter((asset) => asset.name === value);

            if (newOpts['font'] !== value) {
                if (standardFonts) {
                    newOpts['font_type'] = FONT_TYPES.STANDARD;
                    newOpts['font'] = value;
                    newOpts['font_weight'] = standardFonts.weight;
                    this.props.onChange(newOpts);
                }

                if (customFonts.length > 0) {
                    newOpts['font_type'] = FONT_TYPES.CUSTOM;
                    newOpts['font_weight'] = 'normal';
                    newOpts['font'] = value;
                    newOpts['custom_font_id'] = customFonts[0].id;
                    newOpts['custom_font_url'] = customFonts[0].url;
                    this.props.onChange(newOpts);
                }
            }
        } else {
            newOpts[key] = value;
            this.props.onChange(newOpts);
        }
    }

    private renderFontField() {
        const { availableFonts } = this.state;
        const {
            options: { font },
            showCopyPropIds,
            compositionId,
            layerId
        } = this.props;

        const { onNotification, onError } = this.eventHandlers;
        const customAvailableFonts = availableFonts.map((asset) => asset.name);
        const standardFonts = DEFAULT_FONTS.map((f) => f.name);
        const availableAssets = [...standardFonts, ...customAvailableFonts].sort();
        const fontCheck = availableAssets.includes(this.props.options.font);
        const fontValue = fontCheck ? font : copy.overlayConfig.fontNotFound;

        return (
            <FieldWrapper
                customClass='select-field'
                label={[
                    copy.textConfig.font,
                    getCopyPropIdButton(
                        showCopyPropIds,
                        compositionId,
                        layerId,
                        'options.font',
                        onNotification,
                        onError
                    )
                ]}
                labelPosition='top'
                width='50%'>
                <FontPicker
                    apiKey='AIzaSyApdITAFBEQA0j5YJbcfTIP6ZBMJTJmo9E'
                    activeFontFamily={fontValue}
                    families={availableAssets}
                    defaultFonts={DEFAULT_FONTS}
                    customFonts={availableFonts}
                    onChange={this.eventHandlers.inputs.font}
                />
            </FieldWrapper>
        );
    }

    private renderTextArea() {
        const { options, variables, compositionId, layerId, showCopyPropIds } = this.props;
        const { varsData, variableItem } = reactAutoCompleteSettings(variables);
        const { onNotification, onError } = this.eventHandlers;

        return (
            <FieldWrapper
                label={[
                    copy.textConfig.textLayerContent,
                    getCopyPropIdButton(
                        showCopyPropIds,
                        compositionId,
                        layerId,
                        'options.content',
                        onNotification,
                        onError
                    )
                ]}
                labelPosition='top'
                customClass='text-area-field autocomplete'>
                <ReactTextareaAutocomplete
                    key='text-autocomplete'
                    onChange={(e) => this.eventHandlers.inputs.content(e.target.value)}
                    value={options['content']}
                    loadingComponent={() => <Spinner />}
                    movePopupAsYouType
                    minChar={0}
                    tabOrEnter={true}
                    trigger={{
                        '{': {
                            dataProvider: (d) => {
                                const filtered = varsData.filter((f) =>
                                    f.name.toLowerCase().startsWith(d)
                                );
                                return filtered;
                            },
                            component: variableItem,
                            output: (value) => `{{${value.name}}}`
                        }
                    }}
                />
            </FieldWrapper>
        );
    }

    private renderDropdownChevron = (toggle: boolean): JSX.Element => {
        return toggle ? ImposiumHeader.UP : ImposiumHeader.DOWN;
    };

    public render() {
        const { options, layerId, compositionId, showCopyPropIds, variables } = this.props;
        const {
            showColorDropdown,
            showHighlightColorDropdown,
            showBackgroundColorDropdown,
            showStrokeColorDropdown
        } = this.state;
        const { onNotification, onError } = this.eventHandlers;

        const color = options['color'] ? options['color'] : 'transparent';
        const strokeColor = options['stroke_color'] ? options['stroke_color'] : 'transparent';
        const highlightColor = options['highlight_color']
            ? options['highlight_color']
            : 'transparent';
        const backgroundColor = options['background_color']
            ? options['background_color']
            : 'transparent';

        const colorOption = options['color_option'] ? options['color_option'] : 'color';
        const highlightColorOption = options['highlight_color_option']
            ? options['highlight_color_option']
            : 'color';
        const backgroundColorOption = options['background_color_option']
            ? options['background_color_option']
            : 'color';
        const strokeColorOption = options['stroke_color_option']
            ? options['stroke_color_option']
            : 'color';

        const variableOptions = getVariableOptions(variables, ['color']);

        const colorOptionLabel = (
            <span
                className='color-dropdown'
                ref={this.colorRef}
                onClick={() =>
                    this.setState({
                        showColorDropdown: !showColorDropdown
                    })
                }>
                {copy.textConfig.color}
                {getCopyPropIdButton(
                    showCopyPropIds,
                    compositionId,
                    layerId,
                    'options.color',
                    onNotification,
                    onError
                )}
                <button>{this.renderDropdownChevron(showColorDropdown)}</button>
            </span>
        );

        const colorOptionDropdown = (
            <ImposiumDropdown
                key='color-dropdown'
                position='bottomleft'
                show={showColorDropdown}
                toggleRef={this.colorRef}
                onOutsideClick={() => this.setState({ showColorDropdown: false })}>
                <div className='color-options-menu'>
                    <div
                        className={`color-option-list ${colorOption === 'color' ? 'active' : null}`}
                        onClick={() => {
                            this.colorOptionHandler('color', 'color');
                            this.setState({ showColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.custom}
                    </div>
                    <div
                        className={`color-option-list ${
                            colorOption === 'inventory_id' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('color', 'inventory_id');
                            this.setState({ showColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.variable}
                    </div>
                </div>
            </ImposiumDropdown>
        );

        const colorOptionField =
            colorOption === 'color' ? (
                <>
                    <ColorField
                        label={colorOptionLabel}
                        value={color}
                        presetColors={colorPresets}
                        enableAlpha={true}
                        width='25%'
                        labelPosition='top'
                        pickerPosition='left'
                        onChange={this.eventHandlers.inputs.color}
                    />
                    {colorOptionDropdown}
                </>
            ) : (
                <>
                    <SelectField
                        label={colorOptionLabel}
                        labelPosition='top'
                        key='inventory-id-field'
                        width='25%'
                        value={color.replace(/[{()}]/g, '')}
                        options={variableOptions}
                        onChange={(e) => this.eventHandlers.inputs.color(`{{${e}}}`)}
                    />
                    {colorOptionDropdown}
                </>
            );

        const highlightOptionLabel = (
            <span
                className='color-dropdown'
                ref={this.highlightColorRef}
                onClick={() =>
                    this.setState({
                        showHighlightColorDropdown: !showHighlightColorDropdown
                    })
                }>
                {copy.textConfig.highlight}
                {getCopyPropIdButton(
                    showCopyPropIds,
                    compositionId,
                    layerId,
                    'options.highlight_color',
                    onNotification,
                    onError
                )}
                <button>{this.renderDropdownChevron(showHighlightColorDropdown)}</button>
            </span>
        );

        const highlightOptionDropdown = (
            <ImposiumDropdown
                key='highlight-color-dropdown'
                position='bottomleft'
                show={showHighlightColorDropdown}
                toggleRef={this.highlightColorRef}
                onOutsideClick={() => this.setState({ showHighlightColorDropdown: false })}>
                <div className='color-options-menu'>
                    <div
                        className={`color-option-list ${
                            highlightColorOption === 'color' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('highlight', 'color');
                            this.setState({ showHighlightColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.custom}
                    </div>
                    <div
                        className={`color-option-list ${
                            highlightColorOption === 'inventory_id' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('highlight', 'inventory_id');
                            this.setState({ showHighlightColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.variable}
                    </div>
                </div>
            </ImposiumDropdown>
        );

        const highlightColorOptionField =
            highlightColorOption === 'color' ? (
                <>
                    <ColorField
                        label={highlightOptionLabel}
                        value={highlightColor}
                        presetColors={colorPresets}
                        enableAlpha={true}
                        width='25%'
                        labelPosition='top'
                        pickerPosition='left'
                        onChange={this.eventHandlers.inputs.highlightColor}
                    />
                    {highlightOptionDropdown}
                </>
            ) : (
                <>
                    <SelectField
                        label={highlightOptionLabel}
                        labelPosition='top'
                        key='inventory-id-field'
                        width='25%'
                        value={highlightColor.replace(/[{()}]/g, '')}
                        options={variableOptions}
                        onChange={(e) => this.eventHandlers.inputs.highlightColor(`{{${e}}}`)}
                    />
                    {highlightOptionDropdown}
                </>
            );

        const backgroundOptionLabel = (
            <span
                className='color-dropdown'
                ref={this.backgroundColorRef}
                onClick={() =>
                    this.setState({
                        showBackgroundColorDropdown: !showBackgroundColorDropdown
                    })
                }>
                {copy.textConfig.backgroundColor}
                {getCopyPropIdButton(
                    showCopyPropIds,
                    compositionId,
                    layerId,
                    'options.background_color',
                    onNotification,
                    onError
                )}
                <button>{this.renderDropdownChevron(showBackgroundColorDropdown)}</button>
            </span>
        );

        const backgroundOptionDropDown = (
            <ImposiumDropdown
                key='background-color-dropdown'
                position='bottomleft'
                show={showBackgroundColorDropdown}
                toggleRef={this.backgroundColorRef}
                onOutsideClick={() => this.setState({ showBackgroundColorDropdown: false })}>
                <div className='color-options-menu'>
                    <div
                        className={`color-option-list ${
                            backgroundColorOption === 'color' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('background', 'color');
                            this.setState({ showBackgroundColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.custom}
                    </div>
                    <div
                        className={`color-option-list ${
                            backgroundColorOption === 'inventory_id' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('background', 'inventory_id');
                            this.setState({ showBackgroundColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.variable}
                    </div>
                </div>
            </ImposiumDropdown>
        );

        const backgroundColorOptionField =
            backgroundColorOption === 'color' ? (
                <>
                    <ColorField
                        label={backgroundOptionLabel}
                        value={backgroundColor}
                        presetColors={colorPresets}
                        enableAlpha={true}
                        width='25%'
                        labelPosition='top'
                        pickerPosition='right'
                        onChange={this.eventHandlers.inputs.backgroundColor}
                    />
                    {backgroundOptionDropDown}
                </>
            ) : (
                <>
                    <SelectField
                        label={backgroundOptionLabel}
                        labelPosition='top'
                        key='inventory-id-field'
                        width='25%'
                        value={backgroundColor.replace(/[{()}]/g, '')}
                        options={variableOptions}
                        onChange={(e) => this.eventHandlers.inputs.backgroundColor(`{{${e}}}`)}
                    />
                    {backgroundOptionDropDown}
                </>
            );

        const strokeColorOptionLabel = (
            <span
                className='color-dropdown'
                ref={this.strokeColorRef}
                onClick={() =>
                    this.setState({
                        showStrokeColorDropdown: !showStrokeColorDropdown
                    })
                }>
                {copy.textConfig.strokeColor}
                {getCopyPropIdButton(
                    showCopyPropIds,
                    compositionId,
                    layerId,
                    'options.stroke_color',
                    onNotification,
                    onError
                )}
                <button>{this.renderDropdownChevron(showStrokeColorDropdown)}</button>
            </span>
        );

        const strokeColorOptionDropDown = (
            <ImposiumDropdown
                key='stroke-color-dropdown'
                position='bottomleft'
                show={showStrokeColorDropdown}
                toggleRef={this.strokeColorRef}
                onOutsideClick={() => this.setState({ showStrokeColorDropdown: false })}>
                <div className='color-options-menu'>
                    <div
                        className={`color-option-list ${
                            strokeColorOption === 'color' ? 'active' : null
                        }`}
                        onClick={() => {
                            this.colorOptionHandler('stroke', 'color');
                            this.setState({ showStrokeColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.custom}
                    </div>
                    <div
                        className={`color-option-list ${
                            strokeColorOption === 'inventory_id' ? 'active' : null
                        }`}
                        onClick={(v) => {
                            this.colorOptionHandler('stroke', 'inventory_id');
                            this.setState({ showStrokeColorDropdown: false });
                        }}>
                        {copy.textConfig.colorOption.variable}
                    </div>
                </div>
            </ImposiumDropdown>
        );

        const strokeColorOptionField =
            strokeColorOption === 'color' ? (
                <>
                    <ColorField
                        label={strokeColorOptionLabel}
                        value={strokeColor}
                        presetColors={colorPresets}
                        enableAlpha={true}
                        width='25%'
                        labelPosition='top'
                        pickerPosition='right'
                        onChange={this.eventHandlers.inputs.strokeColor}
                    />
                    {strokeColorOptionDropDown}
                </>
            ) : (
                <>
                    <SelectField
                        label={strokeColorOptionLabel}
                        labelPosition='top'
                        key='inventory-id-field'
                        width='25%'
                        value={strokeColor.replace(/[{()}]/g, '')}
                        options={variableOptions}
                        onChange={(e) => this.eventHandlers.inputs.strokeColor(`{{${e}}}`)}
                    />
                    {strokeColorOptionDropDown}
                </>
            );

        return (
            <Card
                collapsable={true}
                open={true}
                title={copy.layerConfig.options}
                style={'text-options'}>
                {this.renderTextArea()}
                <ButtonGroupField
                    label={
                        <span>
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.horizontal_alignment',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    labelPosition='top'
                    options={H_ALIGN_OPTIONS}
                    value={options['horizontal_alignment']}
                    width='50%'
                    onChange={this.eventHandlers.inputs.hAlign}
                />
                <ButtonGroupField
                    label={
                        <span>
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.vertical_alignment',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    labelPosition='top'
                    options={V_ALIGN_OPTIONS}
                    value={options['vertical_alignment']}
                    width='50%'
                    onChange={this.eventHandlers.inputs.vAlign}
                />
                {this.renderFontField()}
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.wrap}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.text_wrap',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['text_wrap']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.textWrap}
                />
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.fit}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.text_fit',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['text_fit']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.textFit}
                />
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.caps}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.upper_case',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['upper_case']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.caps}
                />
                <AdvancedNumberField
                    label={
                        <span>
                            {copy.textConfig.fontSize}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.font_size',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['font_size']}
                    width='25%'
                    max={250}
                    min={1}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.fontSize}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.lineHeight}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.line_height',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['line_height']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.lineHeight}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.letterSpacing}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.letter_spacing',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['letter_spacing']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.letterSpacing}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.strokeWeight}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.stroke_weight',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['stroke_weight']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.strokeWeight}
                />
                {colorOptionField}
                {highlightColorOptionField}
                {backgroundColorOptionField}
                {strokeColorOptionField}
            </Card>
        );
    }
}

const mapStateToProps = (state): any => {
    return {
        assetList: state.assetList,
        showCopyPropIds: state.editor.showCopyPropIds,
        story: state.story
    };
};

export const mGetCopyPropIdButton = (
    enabled,
    compId,
    layerId,
    propKey,
    onNotification,
    onError
) => {
    if (enabled) {
        return (
            <CopyPropIdButton
                key={propKey}
                onNotification={onNotification}
                onError={onError}
                copyPropId={`${PROP_KEY_PRFIX}-${compId}-${layerId}.${propKey}`}
            />
        );
    } else {
        return null;
    }
};

export const getCopyPropIdButton = moize(mGetCopyPropIdButton);

export default connect(mapStateToProps, {})(TextLayerOptions);
