import * as React from 'react';
import { connect } from 'react-redux';
import { TextField, FieldWrapper, HRule, Button, SelectField } from '@imposium-hub/components';
import type { IProject } from '../redux/reducers/project';
import { IStory } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { logNotification, logError } from '../util/notifications';
import {
    ICON_TIMES,
    ICON_PLUS,
    ICON_PROJECT_DIAGRAM,
    ICON_JS,
    ICON_GLOBE,
    ICON_EYE_SOLID,
    ICON_EYE_SLASH
} from '../constants/icons';
import { api } from '../constants/app';
import { downloadSDKArchive, ISDKArchiveBody } from '../util/template-generator';
import { getFirstAct } from '../util/story';
import { getDemoURL } from '../util/routing';
import { ASSET_TYPES } from '../constants/story';
import { openConfirmModal } from '../util/ui';

interface IIntegrationDetailsProps {
    project: IProject;
    story: IStory;
}

interface ICredential {
    name: string;
    access_key_id: string;
    secret_access_key: string;
    user_id: string;
}

interface IIntegrationDetailsState {
    credentials: ICredential[];
    newName: string;
    gettingCreds: boolean;
    selectedCredential: any;
    selectedComposition: any;
    compositions: any[];
    afterEffects: any[];
}

class IntegrationDetails extends React.PureComponent<
    IIntegrationDetailsProps,
    IIntegrationDetailsState
> {
    constructor(props) {
        super(props);

        this.state = {
            credentials: [],
            gettingCreds: true,
            newName: '',
            selectedCredential: null,
            selectedComposition: null,
            compositions: [],
            afterEffects: []
        };
    }

    public static getDerivedStateFromProps(props, state) {
        const {
            project: { compositionId }
        } = props;
        let { selectedCredential, selectedComposition } = state;
        const { credentials, compositions } = state;

        // Establish default selectedComposition if none exists
        if (!selectedComposition) {
            selectedComposition = compositionId
                ? compositionId
                : compositions?.length > 0
                ? compositions[0]?.id
                : null;
        }

        // Establish default selectedCredential if none exists
        if (!selectedCredential && credentials) {
            selectedCredential = credentials[0];
        }

        return {
            selectedComposition,
            selectedCredential
        };
    }

    public componentDidMount() {
        const { story: activeStory } = this.props;

        api.getAccssCredentials()
            .then((creds) => {
                this.setState({
                    gettingCreds: false,
                    credentials: creds
                });

                // Creates default credential if none exists
                if (creds.length === 0) {
                    api.createAccessCredentials('default').then((resp) => {
                        const { credentials } = this.state;
                        const newCreds = [...credentials, ...[resp]];
                        this.setState({ credentials: newCreds });
                    });
                }
            })
            .catch((credsError) => {
                logError(copy.integration.errorGettingCreds);
            });

        api.getAssets({ type: ASSET_TYPES.VIDEO_COMPOSITION }, activeStory.id)
            .then((res) => {
                this.setState({
                    compositions: res.assets
                });
            })
            .catch((credsError) => {
                logError(copy.integration.errorPullingComps);
            });

        api.getAssets({ type: ASSET_TYPES.AFTER_EFFECT }, activeStory.id)
            .then((res) => {
                this.setState({
                    afterEffects: res.assets
                });
            })
            .catch((credsError) => {
                logError(copy.integration.errorPullingAEs);
            });
    }

    private createNewCredentials(callback?: any) {
        const { newName } = this.state;

        if (newName !== '') {
            this.setState(
                {
                    newName: ''
                },
                () => {
                    api.createAccessCredentials(newName)
                        .then((resp) => {
                            const { credentials } = this.state;
                            const newCreds = [...credentials, ...[resp]];
                            this.setState({ credentials: newCreds, newName: '' }, () => {
                                if (callback) {
                                    callback();
                                }
                            });
                        })
                        .catch((err) => {
                            logError(copy.integration.errorCreatingCreds);
                        });
                }
            );
        } else {
            logError(copy.integration.noName);
        }
    }

    private downloadExampleArchive = (): void => {
        const { story } = this.props;

        const { credentials, selectedCredential, selectedComposition } = this.state;

        const firstAct: any = getFirstAct(story);

        let archiveName: string;
        let sdkExampleBody: ISDKArchiveBody;

        if (
            story.id &&
            story.name &&
            credentials.length > 0 &&
            typeof firstAct !== 'undefined' &&
            firstAct.hasOwnProperty('inventory')
        ) {
            archiveName = story.name + copy.integration.sdkArchiveSuffix;
            sdkExampleBody = {
                storyId: story.id,
                compositionId: selectedComposition,
                storyName: story.name,
                accessToken: selectedCredential.access_key_id,
                inventory: firstAct.inventory
            };

            downloadSDKArchive(archiveName, sdkExampleBody);
        } else if (credentials.length === 0) {
            this.promptForNewCredentials(() => this.downloadExampleArchive());
        }
    };

    private updateNewName(e) {
        this.setState({
            newName: e
        });
    }

    private deleteCreds(index) {
        const { credentials, selectedCredential } = this.state;

        const id = credentials[index].access_key_id;
        const newSelected = id === selectedCredential.access_key_id ? null : selectedCredential;
        api.deleteAccessCrednetials(id);

        const newCreds = [...credentials.slice(0, index), ...credentials.slice(index + 1)];
        this.setState({
            credentials: newCreds,
            selectedCredential: newSelected
        });
    }

    private handleCredSelect(creds) {
        const { credentials } = this.state;

        const selectedCred = credentials.find((cred) => cred.name === creds);

        this.setState({ selectedCredential: selectedCred });
    }

    private linkToDemoPage() {
        const { selectedCredential, selectedComposition } = this.state;
        const {
            project: { storyId }
        } = this.props;

        if (selectedCredential) {
            const key = selectedCredential.access_key_id;
            const demoUrl = `${getDemoURL()}/${key}/${storyId}/${selectedComposition}`;
            window.open(demoUrl, '_blank');
        } else {
            this.promptForNewCredentials(() => this.linkToDemoPage());
        }
    }

    private promptForNewCredentials(callback) {
        // Redundancy if Default token is deleted
        const credName = prompt(copy.integration.credentialAlert);

        if (credName) {
            this.setState(
                {
                    newName: credName
                },
                () =>
                    this.createNewCredentials(() => {
                        this.setState(
                            {
                                selectedCredential: this.state.credentials[0]
                            },
                            () => {
                                callback();
                            }
                        );
                    })
            );
        }
    }

    private renderCreds() {
        const { credentials, gettingCreds } = this.state;

        if (!gettingCreds) {
            if (credentials.length > 0) {
                return credentials.map((creds, i) => {
                    return (
                        <CredentialsSet
                            key={creds.access_key_id}
                            onDelete={() => this.deleteCreds(i)}
                            credentials={creds}
                        />
                    );
                });
            } else {
                return <span>{copy.integration.noCreds}</span>;
            }
        } else {
            return null;
        }
    }

    public onCompositionChange = (e) => {
        this.setState({ selectedComposition: e });
    };

    public render() {
        const {
            story: { id, name }
        } = this.props;
        const { credentials, newName, selectedCredential, compositions, afterEffects } = this.state;
        const credsOpts = [];
        const compOpts = [];

        if (compositions.length > 0) {
            for (const comp of compositions) {
                compOpts.push({
                    value: comp.id,
                    label: comp.name
                });
            }

            if (afterEffects.length > 0) {
                for (const AE of afterEffects) {
                    compOpts.push({
                        value: AE.id,
                        label: AE.name
                    });
                }
            }
        } else {
            compOpts.push(copy.compositions.noComposition);
        }

        for (const cred of credentials) {
            credsOpts.push(cred.name);
        }

        return (
            <div className='integration-details'>
                <h2>{copy.integration.details}</h2>
                <HRule />
                <p>{copy.integration.accessParagraph}</p>

                <TextField
                    label={copy.project.storyName}
                    value={name}
                    onNotification={(n) => logNotification(n)}
                    onError={(e) => logError(e)}
                    readOnly={true}
                />

                <TextField
                    label={copy.project.story}
                    value={id}
                    tooltip={copy.project.tooltipStoryId}
                    showCopy={true}
                    onNotification={(n) => logNotification(n)}
                    onError={(e) => logError(e)}
                    readOnly={true}
                />

                <SelectField
                    label={copy.project.compName}
                    value={this.state.selectedComposition}
                    onChange={this.onCompositionChange}
                    options={compOpts}
                    tooltip={copy.project.tooltipCompId}
                />

                <TextField
                    label={copy.project.comp}
                    value={this.state.selectedComposition}
                    showCopy={true}
                    onNotification={(n) => logNotification(n)}
                    onError={(e) => logError(e)}
                    readOnly={true}
                />

                <FieldWrapper label={copy.integration.access}>
                    <div className='credentials'>{this.renderCreds()}</div>
                </FieldWrapper>

                <TextField
                    buttons={[
                        <Button
                            style='subtle'
                            color='primary'
                            tooltip={copy.integration.tooltipAddCreds}
                            onClick={() => this.createNewCredentials()}
                            key='add-creds'>
                            {ICON_PLUS}
                        </Button>
                    ]}
                    placeholder={copy.integration.name}
                    tooltip={copy.integration.tooltipNewCreds}
                    label={copy.integration.newCreds}
                    onChange={(e) => this.updateNewName(e)}
                    value={newName}
                />

                <br />
                <br />

                <h2>{copy.integration.codeSampleTitle}</h2>
                <HRule />
                <p>{copy.integration.codeSampleDescription}</p>

                {credsOpts.length > 0 ? (
                    <SelectField
                        label={copy.integration.selectCreds}
                        value={selectedCredential ? selectedCredential.name : credsOpts[0].name}
                        onChange={(c) => this.handleCredSelect(c)}
                        options={credsOpts}
                    />
                ) : null}

                <BigButton
                    label={
                        <span>
                            {ICON_GLOBE}&nbsp;{copy.integration.demoPage}
                        </span>
                    }
                    onClick={() => this.linkToDemoPage()}
                />

                <BigButton
                    label={
                        <span>
                            {ICON_JS}&nbsp;{copy.integration.codeSampleJSBrowser}
                        </span>
                    }
                    onClick={this.downloadExampleArchive}
                />

                <br />
                <br />

                <h2>{copy.integration.documentation}</h2>
                <HRule />
                <p>{copy.integration.docParagraph}</p>

                <BigLink
                    label={
                        <span>
                            {ICON_JS}&nbsp;{copy.integration.js}
                        </span>
                    }
                    link={copy.integration.jsLink}
                />

                <BigLink
                    label={
                        <span>
                            {ICON_PROJECT_DIAGRAM}&nbsp;{copy.integration.rest}
                        </span>
                    }
                    link={copy.integration.restLink}
                />
            </div>
        );
    }
}

interface IBigLinkProps {
    label: any;
    link: string;
}

const BigLink: React.FC<IBigLinkProps> = (p: IBigLinkProps) => {
    return (
        <a
            className='big-link'
            target='_blank'
            href={p.link}>
            <h1>{p.label}</h1>
        </a>
    );
};

interface IBigButtonProps {
    label: any;
    onClick: (...args) => any;
}

const BigButton: React.FC<IBigButtonProps> = (p) => (
    <div
        className='big-link'
        onClick={p.onClick}>
        <h1>{p.label}</h1>
    </div>
);

interface ICredentialsSetProps {
    credentials: ICredential;
    onDelete(): void;
}

interface ICredentialsSetState {
    showSecret: boolean;
}

class CredentialsSet extends React.PureComponent<ICredentialsSetProps, ICredentialsSetState> {
    constructor(props) {
        super(props);

        this.state = {
            showSecret: false
        };
    }

    public deleteCredentials(): void {
        openConfirmModal({
            onYes: () => this.props.onDelete(),
            title: copy.integration.confirmCreds
        });
    }

    public render() {
        const {
            credentials: { name, access_key_id, secret_access_key }
        } = this.props;
        const { showSecret } = this.state;

        const btnClearSecret = (
            <Button
                style='subtle'
                key='clear-secret'
                onClick={() => this.setState({ showSecret: false })}>
                {ICON_EYE_SLASH}
            </Button>
        );

        const btnShowSecret = (
            <Button
                style='subtle'
                key='show-secret'
                onClick={() => this.setState({ showSecret: true })}>
                {ICON_EYE_SOLID}
            </Button>
        );

        const secret = showSecret ? (
            <TextField
                label={copy.integration.secret}
                value={atob(secret_access_key)}
                tooltip={copy.integration.tooltipSecret}
                showCopy={true}
                onNotification={(n) => logNotification(n)}
                onError={(e) => logError(e)}
                buttons={btnClearSecret}
                readOnly={true}
            />
        ) : (
            <FieldWrapper label={copy.integration.secret}>{btnShowSecret}</FieldWrapper>
        );

        return (
            <div className='set'>
                <TextField
                    label={copy.integration.name}
                    value={name}
                    tooltip={copy.integration.tooltipName}
                    onNotification={(n) => logNotification(n)}
                    onError={(e) => logError(e)}
                    readOnly={true}
                />
                <TextField
                    label={copy.integration.key}
                    value={access_key_id}
                    tooltip={copy.integration.tooltipKey}
                    showCopy={true}
                    onNotification={(n) => logNotification(n)}
                    onError={(e) => logError(e)}
                    readOnly={true}
                />
                {secret}
                <Button
                    onClick={() => this.deleteCredentials()}
                    tooltip={copy.integration.tooltipRemoveCreds}
                    style='subtle'
                    customStyles={{
                        top: '3px',
                        left: '3px',
                        position: 'absolute'
                    }}>
                    {ICON_TIMES}
                </Button>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state): any => {
    return { project: state.project, story: state.story };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationDetails);
