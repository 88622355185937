import { Button, Card, HRule, SelectField, TextField } from '@imposium-hub/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { fields as copy } from '../constants/copy';
import { ICON_TRASH } from '../constants/icons';
import { IAPIScene, IStory } from '../constants/snippets';
import { API_REQUEST_TYPE_OPTIONS } from '../constants/story';
import type { IProject } from '../redux/reducers/project';
import KeyValueFields from './KeyValueFields';
import { openConfirmModal } from '../util/ui';

interface IAPICallbackConfigProps {
    config: IAPIScene;
    story: IStory;
    project: IProject;
    onUpdate(s, u): void;
    onDelete(id: string): void;
}

interface IAPICallbackConfigState {
    isUrl: boolean;
}

class APICallbackConfig extends React.PureComponent<
    IAPICallbackConfigProps,
    IAPICallbackConfigState
> {
    constructor(props) {
        super(props);

        this.state = {
            isUrl: false
        };
    }

    private confirmDelete() {
        openConfirmModal({
            onYes: () => this.props.onDelete(this.props.config.id),
            title: copy.global.deletePrompt.replace('[name]', 'API Callback')
        });
    }

    private sceneDataInputChanged(key, value) {
        const { config } = this.props;
        let unsaved = true;
        if (key === 'url') {
            const patt = new RegExp(/^(https?):\/\/[^\s$.?#].[^\s]*$/gm);
            unsaved = patt.test(value);
            this.setState({ isUrl: !unsaved });
        }
        const scene = { ...config };
        const data = { ...scene.sceneData };
        data[key] = value;
        scene.sceneData = data;

        this.props.onUpdate(scene, unsaved);
    }

    private getAdditionalParamOptions() {
        const {
            project: { actId, sceneId },
            story
        } = this.props;
        const inv = story.acts[actId].inventory;
        const videoScene = story.acts[actId].scenes[sceneId];

        const variables = [];
        const projectParams = [
            {
                label: 'Story ID',
                value: 'story_id'
            }
        ];
        const batchParams = [
            {
                label: 'Batch ID',
                value: 'batch_id'
            }
        ];
        const videoOutput = [
            {
                label: 'Experience ID',
                value: 'experience_id'
            },
            {
                label: 'Video Duration',
                value: 'scene_duration'
            }
        ];
        const increments = [];
        const formatNames = [];
        const formatKeys = [];

        for (const itemId in inv) {
            if (inv.hasOwnProperty(itemId)) {
                const item = inv[itemId];
                variables.push({
                    label: item.name,
                    value: itemId
                });
            }
        }

        for (const encodingSettings of videoScene.sceneData.encodingSettings) {
            if (encodingSettings.extension && encodingSettings.height) {
                const formatName = encodingSettings.name;
                const formatKey = encodingSettings.extension + '_' + encodingSettings.height;

                if (typeof increments[formatKey] === 'undefined') {
                    increments[formatKey] = 0;
                }
                increments[formatKey]++;

                formatNames.push(formatName);

                if (formatKeys.indexOf(formatKey) <= -1) {
                    formatKeys.push(formatKey);
                } else {
                    formatKeys.push(formatKey + '_' + increments[formatKey]);
                }
            }
        }

        for (let k = 0; k < formatKeys.length; k++) {
            videoOutput.push({
                label: formatNames[k],
                value: formatKeys[k]
            });
        }

        return [
            {
                value: '',
                disabled: true,
                label: 'Parameter Value'
            },
            {
                type: 'group',
                label: copy.api.projectVals,
                options: [...projectParams]
            },
            {
                type: 'group',
                label: copy.api.batchVals,
                options: [...batchParams]
            },
            {
                type: 'group',
                label: copy.api.outputVals,
                options: [...videoOutput]
            },
            {
                type: 'group',
                label: copy.api.varVals,
                options: [...variables]
            }
        ];
    }

    public render() {
        const { config } = this.props;
        const { isUrl } = this.state;
        const sceneData = config.sceneData;

        return (
            <Card
                collapsable={true}
                open={true}
                style='small'
                title={config.name || copy.api.title}>
                <TextField
                    className={isUrl ? 'warning' : ''}
                    label={copy.api.url}
                    tooltip={copy.api.tooltipURL}
                    info={isUrl ? copy.encoding.urlWarning : ''}
                    value={sceneData['url']}
                    onChange={(v) => this.sceneDataInputChanged('url', v)}
                />
                <SelectField
                    label={copy.api.type}
                    tooltip={copy.api.tooltipType}
                    options={API_REQUEST_TYPE_OPTIONS}
                    value={sceneData['requestType']}
                    onChange={(v) => this.sceneDataInputChanged('requestType', v)}
                />
                <TextField
                    label={copy.api.script}
                    tooltip={copy.api.tooltipSetup}
                    value={sceneData['setupScript']}
                    onChange={(v) => this.sceneDataInputChanged('setupScript', v)}
                />
                <br />
                <HRule style='subtle' />
                <h3>{copy.api.paramTitle}</h3>
                <br />
                <br />
                <KeyValueFields
                    label={copy.api.params}
                    tooltip={copy.api.tooltipParam}
                    tooltipAdd={copy.api.tooltipAddParam}
                    btnAddLabel={copy.api.addParam}
                    keyPlaceholder={copy.api.paramName}
                    valuePlaceholder={copy.api.paramValue}
                    lockValue={true}
                    entries={sceneData['additionalParams']}
                    options={this.getAdditionalParamOptions()}
                    onChange={(v) => this.sceneDataInputChanged('additionalParams', v)}
                />
                <br />
                <HRule style='subtle' />
                <h3>{copy.api.headerTitle}</h3>
                <br />
                <br />
                <KeyValueFields
                    label={copy.api.headers}
                    tooltip={copy.api.tooltipHeaders}
                    tooltipAdd={copy.api.tooltipAddHeader}
                    btnAddLabel={copy.api.addHeader}
                    keyPlaceholder={copy.api.headerlabel}
                    valuePlaceholder={copy.api.headerValue}
                    entries={sceneData['headers']}
                    onChange={(v) => this.sceneDataInputChanged('headers', v)}
                />
                <HRule style='subtle' />
                <div style={{ textAlign: 'right' }}>
                    <Button
                        onClick={() => this.confirmDelete()}
                        style='subtle'>
                        {ICON_TRASH}
                    </Button>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = (state): any => {
    return { project: state.project, story: state.story };
};

export default connect(mapStateToProps)(APICallbackConfig);
