import {
    DEFAULT_DESYNC_TOLERANCE,
    MAX_ATTEMPTS,
    RETRY_DELAY,
    RETRY_STATUSES
} from '../constants/editor';

const preloadedUrls = {};

const nullMedia = {
    addEventListener: (ignored, callback) => callback(),
    pause: () => {
        // placeholder
    },
    play: () => {
        // placeholder
    },
    removeEventListener: () => {
        // placeholder
    }
};

const preloadBlobAsObjectURL = (sourceUrl, mimetype) => {
    return new Promise<any>((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', sourceUrl);
        if (mimetype) {
            request.overrideMimeType(mimetype);
        }
        request.responseType = 'blob';
        request.onload = () => {
            if (request.status !== 200) {
                reject(request.status);
            }
            resolve(request.response);
        };
        request.send();
        request.onerror = reject;
    }).then((preloadedBlob: any) => URL.createObjectURL(preloadedBlob));
};

const retryHelper = async (
    task,
    { baseDelay = RETRY_DELAY, maxAttempts = MAX_ATTEMPTS, shouldRetry = (e) => true }
) => {
    let error = null;
    for (let attempt = 0; attempt < maxAttempts; attempt += 1) {
        try {
            return await task();
        } catch (err) {
            error = err;
            if (!shouldRetry(err)) {
                break;
            }
            // delay next attempt with exponential backoff
            await new Promise<void>((resolve) => setTimeout(resolve, baseDelay * 2 ** attempt));
        }
    }
    throw error;
};

export default class MediaController {
    /**
     * Preload a media source fully.
     * Can optionally override the mimetype.
     */
    public static preloadSource(sourceUrl, mimetype = null) {
        if (!preloadedUrls[sourceUrl]) {
            preloadedUrls[sourceUrl] = retryHelper(
                () => preloadBlobAsObjectURL(sourceUrl, mimetype),
                { shouldRetry: (status) => RETRY_STATUSES.includes(status) }
            ).catch((status) => {
                throw Error(`Unable to preload source, received HTTP status ${status}`);
            });
        }
        return preloadedUrls[sourceUrl];
    }

    /**
     * Convenience function for preloading MP4 video sources
     */
    public static preloadVideoSource(sourceUrl) {
        // safari won't load the video if it doesn't have the correct mime type
        return MediaController.preloadSource(sourceUrl, 'video/mp4');
    }

    public static preloadImage(sourceUrl): any {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                resolve(img);
            };
            img.onerror = () => {
                reject();
            };
            img.src = sourceUrl;
        });
    }

    /**
     * Ensures a <video> or <audio> element is loaded and ready for interactions
     */
    public static preloadMediaElement(media, src) {
        // this is the only reliable way we've found to make sure video is ready
        // waiting for these events doesn't work: loadeddata, canplay, canplaythrough
        return new Promise<void>((resolve, reject) => {
            let isLoaded = false;

            const loaded = () => {
                media.removeEventListener('canplaythrough', loaded);
                if (!isLoaded) {
                    isLoaded = true;
                    resolve();
                }
            };

            media.addEventListener('canplaythrough', loaded);
            media.src = src;
            media.load();
        });
    }

    public static async preloadOffscreenImage(sourceUrl) {
        const img = document.createElement('img');
        img.setAttribute('crossorigin', 'anonymous');

        await new Promise<void>((resolve, reject) => {
            img.addEventListener('load', () => resolve());
            img.addEventListener('error', () => {
                reject('error loading image');
            });
            img.src = sourceUrl;
        });

        return img;
    }

    public static async preloadOffscreenVideo(sourceUrl) {
        const source = await MediaController.preloadVideoSource(sourceUrl);
        const video = document.createElement('video');
        video.setAttribute('crossorigin', 'anonymous');
        video.setAttribute('playsInline', '');
        video.setAttribute('muted', '');

        await MediaController.preloadMediaElement(video, source);

        return video;
    }

    public static async preloadOffscreenAudio(sourceUrl) {
        const source = await MediaController.preloadSource(sourceUrl);
        const audio = document.createElement('audio');

        await MediaController.preloadMediaElement(audio, source);

        return audio;
    }

    private desyncTolerance: number;

    private internalMedia: any;

    private audioContext: any = new AudioContext();

    private audioTrack: any;

    private gainNode: any;

    constructor(media, desyncTolerance = DEFAULT_DESYNC_TOLERANCE) {
        this.internalMedia = media || nullMedia;
        this.desyncTolerance = desyncTolerance;
        this.audioTrack = this.audioContext.createMediaElementSource(media);
        this.gainNode = this.audioContext.createGain();
        this.audioTrack.connect(this.gainNode).connect(this.audioContext.destination);
    }

    get gain() {
        return this.gainNode;
    }

    get track() {
        return this.audioTrack;
    }

    get media() {
        return this.internalMedia;
    }

    public pause() {
        if (!this.media.paused) {
            this.media.pause();
        }
    }

    public mute() {
        this.media.muted = true;
    }

    public unmute() {
        this.media.muted = false;
    }

    public async play() {
        if (this.media.paused) {
            const play = this.media.play();

            if (play) {
                this.audioContext.resume().then(() => {
                    play.catch((error) => {
                        console.error(error);
                    });
                });
            }

            return play;
        }
        return Promise.resolve();
    }

    public seek(timestamp) {
        if (!isFinite(timestamp)) {
            console.error(`ERROR: Requested seek timestamp is not finite. ${timestamp}`);
            return Promise.resolve();
        }

        if (!this.media.duration) {
            console.error(`WARNING: Requested seek before media finished 
            ing`);
            return Promise.resolve();
        }

        if (timestamp < 0 || timestamp > this.media.duration) {
            // console.error(`WARNING: Requested seek timestamp is invalid.`);

            // make sure the target timestamp is in bounds for the source media
            timestamp = Math.max(timestamp, 0);
            timestamp = Math.min(timestamp, this.media.duration);
        }

        return new Promise<void>((resolve) => {
            const seekHandler = () => {
                this.media.removeEventListener('seeked', seekHandler);

                return resolve();
            };
            this.media.addEventListener('seeked', seekHandler);

            this.media.currentTime = timestamp;
        });
    }

    public isNearTime(timestamp) {
        return Math.abs(this.media.currentTime - timestamp) < this.desyncTolerance;
    }
}
